import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Box, Typography, TablePagination } from '@material-ui/core';
import { bottomBorder, leftBorder, topBorder, pageTitle } from '../styles/common';
import CountryCard from './CountryCard';
import { actChangeProxyPage, actChangeProxyRowsPerPage, actResetStaticCountryFilter, actLoadProxies } from '../redux/backend/proxies';
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../redux/auth"
import ProxyTable from './ProxyTable';
import ProxySidebar from './ProxySidebar';
import MoreCountries from './MoreCountries';
import ProxyTablePaginationActions from './ProxyTablePaginationActions';
import './css/FaqPage.css';
import ShopMobile from './shopMobile/shopMobile';
import { useEffect } from 'react';
import { getActiveItem } from '../redux/backend/activeMenuItem';


export const headerStyle = (theme) => ({
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
})

const useStyles = makeStyles((theme) => ({
    shop: {
        height: '100%',
        maxHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        gridTemplateColumns: '1fr auto',
    },
    header: {
        ...headerStyle(theme),
        ...bottomBorder(theme),

        gridColumnStart: 1,
        gridColumnEnd: 3,
        gridRowStart: 1,
        gridRowEnd: 2,
    },
    table: {
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 2,
        gridRowEnd: 3,
        overflowY: 'scroll',
    },
    sidebar: {
        ...leftBorder(theme),

        gridColumnStart: 2,
        gridColumnEnd: 3,
        gridRowStart: 2,
        gridRowEnd: 4,

        maxHeight: '100%',
        overflowY: 'scroll',
    },
    footer: {
        padding: 0,
        paddingLeft: theme.spacing(1),
        ...topBorder(theme),

        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 3,
        gridRowEnd: 4,
    },
    title: {
        ...pageTitle,
    },
    countries: {
        display: 'flex',
        "& div": {
            marginRight: theme.spacing(1),
            // marginTop: theme.spacing(2),
        },
    },
    paginationRoot: {
        borderWidth: 0,
    },
    paginationCaption: {
        fontWeight: 500,
    },
    paginationSpacer: {
        display: 'none',
    },
    actionTexMob: {
        marginLeft: '20px',
        fontFamily: 'SF Pro Medium',
        fontSize: 10,
        color: '#0da12f',
        fontSize: 14,
        letterSpacing: 0,
        fontWeight: 500
    },
}))

const Shop = (props) => {

    const classes = useStyles();
    const { getAccessTokenSilently } = useAuth0()

    const dispatch = props.dispatch

    const handlePageChange = (_, page) => {
        dispatch(actChangeProxyPage(getAccessTokenSilently, page))
    }

    const handleRowsChange = (ev) => {
        dispatch(actChangeProxyRowsPerPage(getAccessTokenSilently, ev.target.value))
    }

    useEffect(() => {
        // dispatch(actResetStaticCountryFilter())
        dispatch(actLoadProxies(getAccessTokenSilently, 0))
    }, [])

    useEffect(() => {
        dispatch(getActiveItem('Shop'))
    }, [dispatch])

    return (<>
        <div className='shop-web'>
            <Box className={classes.shop}>
                <Box className={classes.header}>
                    <Typography className={classes.title}>Proxies</Typography>
                    <Box className={classes.countries}>
                        {props.countries.map((country) => <CountryCard key={country} country={country} />)}
                        {props.extraCountries.map((country) => <CountryCard key={country} country={country} />)}
                        <MoreCountries />
                    </Box>
                </Box>
                <Box className={classes.table}>
                    <ProxyTable />
                </Box>
                <Box className={classes.sidebar}>
                    <ProxySidebar selected={props.selected} leaseType={props.leaseType} />
                </Box>
                <Box className={classes.footer}>
                    <TablePagination
                        component='div'
                        classes={{ root: classes.paginationRoot, spacer: classes.paginationSpacer, caption: classes.paginationCaption }}
                        rowsPerPageOptions={[10, 20, 50]}
                        rowsPerPage={props.proxyRowsPerPage}
                        count={props.totalProxies}
                        page={props.proxyPage}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsChange}
                        ActionsComponent={ProxyTablePaginationActions}
                    />
                </Box>
            </Box>
        </div>
        <div className='shop-mobile'>
            <ShopMobile />
        </div>
    </>
    );
};

const mapStateToProps = (state) => ({
    totalProxies: state.proxies.total ? state.proxies.total : 0,
    proxyRowsPerPage: state.proxies.pagination.rowsPerPage,
    proxyPage: state.proxies.pagination.page,
    countries: state.proxies.countries,
    extraCountries: state.proxies.extraCountries,
    selectedCountry: state.proxies.filter.country,
    countryTotals: state.proxies.countryTotals,
    selected: state.proxies.selected,
    leaseType: state.proxies.leaseType,
});

export default connect(mapStateToProps)(Shop);
