import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import ConfirmRefundDialog from './ConfirmRefundDialog'

const RefundButton = (props) => {
    const [confirmRefundOpen, setConfirmRefundOpen] = useState(false)

    const refund = () => {
        setConfirmRefundOpen(true)
    }

    // console.log(confirmRefundOpen, 'confirmRefundOpen')

    return (
        <>
            <Button {...props.props} onClick={() => setConfirmRefundOpen(true)}>Refund</Button>
            <ConfirmRefundDialog open={confirmRefundOpen} onClose={() => setConfirmRefundOpen(false)} />
        </>
    )
}

export default connect()(RefundButton)
