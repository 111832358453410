import React from 'react'
import { connect } from 'react-redux'
import { countryNames } from '../data/countryNames'
import { makeStyles, Box } from '@material-ui/core'
import ReactCountryFlag from 'react-country-flag'
// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../redux/auth"
import { actChangeCountry, actChangeStaticCountry, actResetStaticProxiesFilter } from '../redux/backend/proxies'
import chipCard from '../styles/chipCard'

const useStyles = makeStyles((theme) => ({
    card: chipCard(theme),
    selected: {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            color: theme.palette.common.white,
        }
    },
    flag: {
        position: 'relative',
        bottom: 2,
        minWidth: 24,
        marginRight: theme.spacing(1),
    },
    check: {
        marginLeft: theme.spacing(1),
    },
}))

const CountryCard = (props) => {
    const classes = useStyles()
    const { getAccessTokenSilently } = useAuth0()

    const selected = props.country === props.selectedCountry

    const proxyType = props.type || "mobile"

    const selectedStatic = props.country === props.selectedCountryStatic

    let selectedProxy = selected

    if (proxyType === 'static') {
        selectedProxy = selectedStatic
    }

    const handleClick = () => {
        if (proxyType === "static") {
            props.dispatch(actResetStaticProxiesFilter())
            props.dispatch(actChangeStaticCountry(getAccessTokenSilently, props.country))  
        } else {
            props.dispatch(actChangeCountry(getAccessTokenSilently, props.country))
        }
    }

    let total = props.countryTotals[props.country]
    if (total) {
        total = "(" + total + ")"
    }

    return (
        <Box className={`${classes.card} ${selectedProxy ? classes.selected : ''}`} onClick={handleClick}>
            <ReactCountryFlag countryCode={props.country} className={classes.flag} svg />
            {countryNames[props.country]} {proxyType !== "static"?total:''}
        </Box>
    )
}

const mapStateToProps = (state) => ({
    selectedCountry: state.proxies.filter.country,
    selectedCountryStatic: state.proxies.filter.countryStatic,
    countryTotals: state.proxies.countryTotals ? state.proxies.countryTotals : {},
})

export default connect(mapStateToProps)(CountryCard)
