import React from 'react'
import { Box } from '@material-ui/core'
import { useTableStyles } from './table'


const NoResults = (props) => {
    const classes = useTableStyles()
    const message = props.message || 'Nothing found'
    return (
        <Box className={classes.noResults}>{message}</Box>
    )
}

export default NoResults
