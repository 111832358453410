import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { TableRow, styled, TableCell, makeStyles, TextField, Paper, createMuiTheme, IconButton, Button, Link } from '@material-ui/core'
import { headCellHeight } from './table'
import { connTypeLabels } from './ProxyTable'
import { Autocomplete } from '@material-ui/lab'
import { actSetFilter, countriesSupportedForAdvancedSearch, isAdvancedSearchActive } from '../redux/backend/proxies'
// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../redux/auth"
import ClearIcon from '@material-ui/icons/Clear'
import { bottomBorder } from '../styles/common'
import AdvancedSearchDialog, { resetAdvancedSearch } from './search/AdvancedSearchDialog';

const useStyles = makeStyles((theme) => ({
    filterRow: {
        backgroundColor: '#e4e6eb',
        top: headCellHeight,
        position: 'sticky',
        height: headCellHeight,
        maxHeight: headCellHeight,
    },
    filterInput: {
        backgroundColor: theme.palette.common.white,
        borderColor: '#D0D4DB',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 4,
        fontSize: 12,
        padding: 2,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: 0,
        height: 32,
    },
    filterInputInternal: {
        '&::placeholder': {
            fontStyle: 'italic',
        },
    },
    clearIconButton: {
        margin: 0,
        padding: 0,
        height: 28,
        width: 28,
    },
    clearIcon: {
        height: 20,
        margin: 0,
        padding: 0,
    },
    advancedSearchButton: {
        textTransform: 'none',
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    activeAdvancedSearch: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    advancedSearchCriterion: {
        marginRight: 30,
    },
    advancedSearchValue: {
        fontWeight: 400,
    },
}))

const theme = createMuiTheme()

const FilterCell = styled(TableCell)({
    padding: 0,
    paddingLeft: theme.spacing(2),
    backgroundColor: 'inherit',
    border: 0,
    ...bottomBorder(theme),
})

let ipTimeout = null

const ProxyFilter = (props) => {

    const classes = useStyles()
    const {getAccessTokenSilently} = useAuth0()

    const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false)

    const FilterPaper = styled(Paper)({
        minWidth: 300,
        fontSize: 14,
    })

    const [ipRaw, setIpRaw] = useState('')
    const [ipRawCountry, setIpRawCountry] = useState(props.country)

    useEffect(() => {
        if (ipRawCountry !== props.country) {
            setIpRaw('')
            setIpRawCountry(props.country)
        }
    }, [ipRaw, setIpRaw, ipRawCountry, setIpRawCountry, props.country])

    const updateIpFilter = (val) => {
        let ip1 = null
        let ip2 = null

        const parts = val.trim().split('.')
        if (parts.length > 0) {
            const num = Number(parts[0])
            if (!isNaN(num) && num > 0 && num < 256) {
                ip1 = num
            }

            if (parts.length > 1 && parts[1].length > 0) {
                const num = Number(parts[1])
                if (!isNaN(num) && num >= 0 && num < 256) {
                    ip2 = num
                }
            }
        } else {
            const num = Number(val)
            if (!isNaN(num) && num > 0 && num < 256) {
                ip1 = num
            }
        }

        props.dispatch(actSetFilter(getAccessTokenSilently, {ip1, ip2}))
    }

    const handleIpFilterChange = (e) => {
        const val = e?.target?.value ? e.target.value : ''
        setIpRaw(val)

        if (ipTimeout != null) {
            clearTimeout(ipTimeout)
        }
        ipTimeout = setTimeout(() => updateIpFilter(val), 1000)
    }

    const dispatch = props.dispatch
    const filter = props.filter
    const SegmentFilterField = (props) => {

        return (
            <Autocomplete
                disabled={(!props.value) && props.disabled}
                freeSolo={Boolean(props.value)}
                openOnFocus={true}
                filterSelectedOptions={true}
                renderInput={(params) =>
                    <TextField {...params}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            classes: {input: classes.filterInputInternal},
                            autoComplete: 'new-password',
                            readOnly: Boolean(props.value),
                        }}
                        autoComplete='new-password'
                        placeholder='Any' />}
                options={props.options || []}
                PaperComponent={FilterPaper}
                classes={{inputRoot: classes.filterInput}}
                value={props.value}
                getOptionLabel={props.getOptionLabel || ((o) => o)}
                onChange={(_, v) => dispatch(actSetFilter(getAccessTokenSilently, props.filter(v)))} />
        )
    }

    const leaseCountNames = {
        'none': "Private",
        'some': "Shared",
    }
    const freshStatuses = {
        'fresh': "New",
        'worn': "Not New",
    }

    const onResetAdvancedSearch = () => {
        resetAdvancedSearch(getAccessTokenSilently, props.dispatch)
    }

    const advancedActive = isAdvancedSearchActive(props.filter)

    return (
        <>
        <TableRow className={classes.filterRow}>
            <FilterCell>
                <TextField
                    InputProps={{
                        disableUnderline: true, classes: {input: classes.filterInputInternal, root: classes.filterInput},
                        endAdornment: ipRaw && (
                            <IconButton className={classes.clearIconButton} onClick={() => handleIpFilterChange(null)}>
                              <ClearIcon className={classes.clearIcon} />
                            </IconButton>
                        ),
                        autoComplete: 'new-password',
                    }}
                    placeholder='Any'
                    value={ipRaw}
                    autoComplete='new-password'
                    onChange={handleIpFilterChange} />
            </FilterCell>
            <FilterCell>
                <SegmentFilterField disabled={!props.segments?.regions} options={props.segments.regions}
                    value={filter.region} filter={(v) => ({region: v})} />
            </FilterCell>
            <FilterCell>
                <SegmentFilterField disabled={!props.segments?.cities} options={props.segments.cities}
                    value={filter.city} filter={(v) => ({city: v})} />
            </FilterCell>
            <FilterCell>
                <SegmentFilterField disabled={!props.segments?.zips} options={props.segments.zips}
                    value={filter.zip} filter={(v) => ({zip: v})} />
            </FilterCell>
            <FilterCell>
                <SegmentFilterField disabled={!props.segments?.isps} options={props.segments.isps}
                    value={filter.isp} filter={(v) => ({isp: v})} />
            </FilterCell>
            <FilterCell>
                <SegmentFilterField options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                            getOptionLabel={(o) => "" + o}
                            value={filter.stars} filter={(v) => ({stars: v})} />
            </FilterCell>
            <FilterCell>
                    <SegmentFilterField
                        options={['none', 'some']}
                        getOptionLabel={(o) => leaseCountNames[o]}
                        value={filter.leases}
                        filter={(v) => ({ leases: v })}
                    />
            </FilterCell>
            <FilterCell>
                <SegmentFilterField options={['wifi', 'cell']}
                    getOptionLabel={(o) => connTypeLabels[o]}
                    value={filter.conn} filter={(v) => ({conn: v})} />
            </FilterCell>
            <FilterCell>
                <SegmentFilterField options={['fresh', 'worn']}
                        getOptionLabel={(o) => freshStatuses[o]}
                        value={filter.fresh} filter={(v) => ({fresh: v})} />
            </FilterCell>
            <FilterCell colSpan={2}>
                {countriesSupportedForAdvancedSearch.includes(props.country) &&
                <Button
                    className={`${classes.filterInput} ${classes.advancedSearchButton} ${advancedActive ? classes.activeAdvancedSearch : ''}`}
                    onClick={() => setAdvancedSearchOpen(true)}>Advanced</Button>}
                <AdvancedSearchDialog open={advancedSearchOpen} onClose={() => setAdvancedSearchOpen(false)} />
            </FilterCell>
        </TableRow>
        {advancedActive && <TableRow className={classes.filterRow}>
            <FilterCell colSpan={11}>
                <span className={classes.advancedSearchCriterion}>Central ZIP: <span className={classes.advancedSearchValue}>{props.filter.geoCentralZip}</span></span>
                <span className={classes.advancedSearchCriterion}>Search Radius: <span className={classes.advancedSearchValue}>{props.filter.geoSearchRadius} miles</span></span>
                <Link href="#" onClick={onResetAdvancedSearch}>Reset</Link>
            </FilterCell>
        </TableRow>}
        </>
    )
}

const mapStateToProps = (state) => ({
    country: state.proxies.filter.country,
    segments: state.proxies.segments || {},
    filter: state.proxies.filter,
})

export default connect(mapStateToProps)(ProxyFilter)
