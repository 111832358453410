import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Box, Typography, TablePagination } from '@material-ui/core';
import { useAuth0 } from "../../redux/auth"
import ProxyTablePaginationActions from '../ProxyTablePaginationActions';
import { useEffect } from 'react';
import { Table, TableRow, TableHead, TableCell, styled, useTheme, TableBody } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import CloseIcon from '@material-ui/icons/Close'
import NoResults from '../NoResults'
import { useTableStyles, TableDataCell, TableHeadCell, mediumColumnWidth, smallColumnWidth, LoadingRow } from '../table'
import StaticProxyFilter from './StaticProxyFilter'
import { connTypeLabels } from '../ProxyTable'
import SmartTooltip from '../SmartTooltip'
import Button from '@mui/material/Button'
import { actStartStaticLease } from '../../redux/backend/lease'
import { getTableBodyUtilityClass } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'

const useStyles = makeStyles((theme) => ({
    filterIcon: {
        position: 'relative',
        top: 3,
        opacity: 0.7,
        cursor: 'pointer',
        '&:hover, &:focus': {
            color: theme.palette.primary.main,
        },
    },
    proxyNew: {
        color: '#24B35F'
    },
    usedIp: {
    	color: 'red'
    },
    newIp: {
    	color: '#09920E'
    },
    errorDiv: {
        margin: 'auto',
        width: '50%',
    }
}))

const StaticProxyTable = (props) => {
    const classes = useTableStyles()
    const extraClasses = useStyles()
    const theme = useTheme()

    const { getAccessTokenSilently } = useAuth0()

    const HeadCell = TableHeadCell(theme)
    const DataCell = TableDataCell(theme)

    const PriceCell = styled(DataCell)({
        fontWeight: 600,
        color: theme.palette.common.black,
    })

    const size = useWindowSize()

    function useWindowSize() {

	  const [windowSize, setWindowSize] = useState({
	    width: undefined,
	    height: undefined,
	  });

	  useEffect(() => {

		  function handleResize() {
           setWindowSize({
        	   width: window.innerWidth,
        	   height: window.innerHeight,
           });
		  }

		  window.addEventListener("resize", handleResize);

		  handleResize();
		  return () => window.removeEventListener("resize", handleResize);
	  }, []);

	  return windowSize;
    }

    var ispCellSize = (size.width <= 620)?120:'44%';
    var regionCellSize = (size.width <= 620)?120:'23%';
    var cityCellSize = (size.width <= 620)?120:'33%';

    const handleBuyClick = (proxy) => {
        props.dispatch(actStartStaticLease(proxy))
    }

    let body = null
    if (!props?.staticloading && props?.staticList?.agents?.length) {
        let filter = props.filter
        body = props?.staticList['agents']?.map((proxy) => {
            const connType = connTypeLabels[proxy.conn]

            return (
                <TableRow
                    hover
                    key={proxy.id}
                    className={classes.dataRow}
                    classes={{ selected: classes.selected }}
                    selected={props.selected?.id === proxy.id}
                >
                    <DataCell><Box>{proxy.ip}</Box></DataCell>
                    <DataCell><SmartTooltip>{proxy.loc ? proxy.loc.city : ""}</SmartTooltip></DataCell>
                    <DataCell><SmartTooltip>{proxy?.loc?.isp}</SmartTooltip></DataCell>
                    <DataCell>{connType}</DataCell>
                    <DataCell><Button variant="contained" onClick={()=>handleBuyClick(proxy)}>Buy {proxy?.price/100}$</Button></DataCell>
                </TableRow>
            )
        })
    } else {
        body = <LoadingRow />
    }
    return (
        <>
            <Box className={classes.tableAndNoResultsContainer}>
            <TableContainer sx={{ maxHeight: 550 }}>
            <Table stickyHeader >
                <TableHead>
                    <TableRow>
                        <HeadCell style={{width: '80px'}}>IP</HeadCell>
                        <HeadCell style={{width: '120px'}}>City</HeadCell>
                        <HeadCell style={{width: '150px'}}>ISP</HeadCell>
                        <HeadCell style={{width: '40px'}}>Conn</HeadCell>
                        <HeadCell style={{width: '50px'}}>Price</HeadCell>
                    </TableRow>
                    <StaticProxyFilter disabled={props.loadError} />
                </TableHead>
                <TableBody>
                    {body}
                </TableBody>
            </Table>
            </TableContainer>
            {(!props.staticloading && !props?.staticList?.agents?.length) && <NoResults />}
        </Box>
        </>
    )
}

const mapStateToProps = (state) => ({
    staticloading: state.proxies.staticloading,
    pagination: state.proxies.staticPagination,
    filter: state.proxies.static?.filter,
    loadError: state.proxies?.staticError,
});

export default connect(mapStateToProps)(StaticProxyTable);