import { sortIgnoreCase } from "../../util/util"
import humanizeDuration from 'humanize-duration'
import moment from "moment"
import { LOAD_STATIC_SUCCESS, LOAD_STATIC_FAILED} from "./api-client"
import { actSetGlobalBusy } from "../actions"


export const initialProxyState = () => ({
    filter: {
        country: 'US',
        countryStatic: 'US',
    },
    pagination: {
        page: 0,
        rowsPerPage: 10,    
    },
    paginationStatic: {
        page: 0,
        rowsPerPage: 10,
    },
    proxies: null,
    cursor: 0,
    loadedAll: false,
    total: null,

    countries: ['US', 'GB', 'AE', 'CA', 'IT', 'CN'],
    extraCountries: [],
    countryTotals: null,

    selected: null,
    leaseType: null,

    segments: null,

    loading: false,
})

export const actLoadProxies = (getToken, minPage) => {
    return async (dispatch, getState) => {
        // if (getState().proxies.loadedAll) {
        //     return
        // }

        dispatch(actSetProxiesLoading(true))

        const pf = getState().proxies.filter
        let proxies = getState().proxies.proxies ? [...getState().proxies.proxies] : []
        const wasEmpty = proxies.length === 0
        
        let total = getState().proxies.total
        let loadedAll = false
        let segments = null
        const rowsPerPage = getState().proxies.pagination.rowsPerPage

        let cursor = minPage * rowsPerPage // getState().proxies.cursor 

        // console.log("params")
        // console.log(pf)
        // while (proxies.length < getState().proxies.pagination.rowsPerPage * (minPage + 1)) {
            try {
                const json = await getState().apiClient.withToken(getToken).loadAgents(pf, cursor, rowsPerPage)
                cursor = json.cursor
                total = json.total
                proxies = json.agents//[...proxies, ...json.agents]
                if (json.segments) {
                    segments = json.segments
                }
            } catch (err) {
                console.warn(err)
                return
            }
            // if (cursor === 0) {
            //     loadedAll = true
                // break
            // }
        // }
        // console.log(proxies)
        dispatch(actSetProxies(proxies, cursor, total, loadedAll))
        if (wasEmpty && proxies.length > 0) {
            dispatch(actSetSelectedProxy(proxies[0]))
        }
        if (segments != null) {
            dispatch(actSetSegments(segments))
        }

        dispatch(actSetProxiesLoading(false))
        return 
    }
}

export const actLoadStaticProxies = (getToken, data) => {
    return async (dispatch, getState) => {
        dispatch(actSetStaticProxiesLoading(true))
        dispatch(actSetStaticProxiesError(false))
        try {
            // dispatch(actSetGlobalBusy(true))
            const country = getState().proxies.filter.countryStatic
            const page = getState().proxies.paginationStatic.page
            const rows = getState().proxies.paginationStatic.rowsPerPage

            const params = {
                cc: country,
                cursor: page,
                limit: rows,
            }

            // console.log("paramsStatic")
            // console.log(params)

            // console.log("getState().proxies.filter")
            // console.log(getState().proxies.filter)

            const conn = getState().proxies?.static?.filter?.conn
            if (conn !== null && conn !== undefined){
                params['conn'] = conn
            }

            const isp = getState().proxies?.static?.filter?.isp
            if (isp !== null && isp !== undefined){
                params['isp'] = isp
            }

            const city = getState().proxies?.static?.filter?.city
            if (city !== null && city !== undefined){
                params['city'] = city
            }

            const ip = getState().proxies?.static?.filter?.ip
            if (ip !== null && ip !== undefined){
                params['ip'] = ip
            }

            const result = await getState().apiClient.withToken(getToken).loadStaticAgents(params)
            
            // const sortResult = (data) => {
            //     let sortedData = {
            //         agents: data['agents'],
            //         isps: [],
            //         cities:[],
            //     }

            //     data['agents'].map((el) => {
            //         sortedData.isps.push(el.loc.isp)
            //         sortedData.cities.push(el.loc.city)
            //     })

            //     return sortedData
            // }

            switch (result[0]) {
                case LOAD_STATIC_SUCCESS:
                    if(result[1].total === 0){
                        return dispatch(actSetStaticProxiesError(true))
                    }
                    return dispatch(actSetStaticProxiesResult(result[1]))
                case LOAD_STATIC_FAILED:
                    return dispatch(actSetStaticProxiesError(true))
                default:
                    return dispatch(actSetStaticProxiesError(true))
            }

        }catch(e) {
            console.log(e)
            dispatch(actSetStaticProxiesLoading(false))
            dispatch(actSetStaticProxiesError(true))
        } finally {
            // dispatch(actSetGlobalBusy(false))
            dispatch(actSetStaticProxiesLoading(false))
        }
        
    }
}

const actSetSegments = (segments) => ({
    type: 'SET_SEGMENTS',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            segments: {
                isps: segments.isps ? segments.isps.sort(sortIgnoreCase) : null,
                zips: segments.zips ? segments.zips.sort(sortIgnoreCase) : null,
                cities: segments.cities ? segments.cities.sort(sortIgnoreCase) : null,
                regions: segments.regions ? segments.regions.sort(sortIgnoreCase) : null,
            },
        }
    })
})

export const actSetProxies = (proxies, newCursor, newTotal, loadedAll) => ({
    type: 'SET_PROXIES',
    reduce: (state) => {
        const newState = {
            ...state,
            proxies: {
                ...state.proxies,
                proxies: proxies,
                cursor: newCursor,
                loadedAll: loadedAll,
                total: newTotal,
            },    
        }
        return newState
    }
})

export const actChangeProxyPage = (getToken, page) => {
    return (dispatch) => {
        dispatch(actLoadProxies(getToken, page)).then(() => {
            dispatch({
                type: 'SET_PROXY_PAGE',
                reduce: (state) => ({
                    ...state,
                    proxies: {
                        ...state.proxies,
                        pagination: {
                            ...state.proxies.pagination,
                            page: page,    
                        }
                    }
                })
            })
        })
    }
}

export const actChangeStaticProxyPage = (getToken, page) => {
    return (dispatch) => {
        dispatch(actLoadStaticProxies(getToken, {page: page})).then(() => {
            dispatch({
                type: 'SET_STATIC_PROXY_PAGE',
                reduce: (state) => ({
                    ...state,
                    proxies: {
                        ...state.proxies,
                        paginationStatic: {
                            ...state.proxies.paginationStatic,
                            page: page,    
                        }
                    }
                })
            })
        })
    }
}

export const actChangeStaticProxyRowsPerPage = (getToken, rows) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_STATIC_ROWS_PER_PAGE',
            reduce: (state) => ({
                ...state,
                proxies: {
                    ...state.proxies,
                    paginationStatic: {
                        page: 0,
                        rowsPerPage: rows,
                    }
                }
            })
        })
        dispatch(actLoadStaticProxies(getToken, {rows: rows, page: 0}))
    }
} 

export const actChangeProxyRowsPerPage = (getToken, rows) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_ROWS_PER_PAGE',
            reduce: (state) => ({
                ...state,
                proxies: {
                    ...state.proxies,
                    pagination: {
                        ...state.proxies.pagination,
                        page: 0,
                        rowsPerPage: rows,
                    }
                }
            })
        })
        dispatch(actLoadProxies(getToken, 0))
    }
} 

export const actChangeCountry = (getToken, country) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SET_COUNTRY',
            reduce: (state) => {
                const s = resetProxies(state)
                if (!s.proxies.countries.includes(country)) {
                    s.proxies.extraCountries = [country]
                }
                return {
                    ...s,
                    proxies: {
                        ...s.proxies,
                        filter: {
                            // ...state.proxies.filter,
                            country: country,
                            // countryStatic: null,
                        }
                    },
                }
            }
        })
        dispatch(actLoadProxies(getToken, 0))
    }
}

export const actChangeStaticCountry = (getToken, countryStatic) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SET_STATIC_COUNTRY',
            reduce: (state) => {
                return {
                    ...state,
                    proxies: {
                        ...state.proxies,
                        filter: {
                            ...state.proxies.filter,
                            countryStatic: countryStatic,
                            // country: null,
                        }
                    },
                }
            }
        })
        dispatch(actResetStaticPagination())
        dispatch(actLoadStaticProxies(getToken))
    }
}

export const actResetStaticPagination = () => ({
    type: 'RESSET_STATIC_PROXY_PAGINATION',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            paginationStatic: {
                page: 0,
                rowsPerPage: 10,
            }
        }
    })
})

export const actResetCountryFilter = () => ({
    type: 'RESSET_PROXY_COUNTRY_FILTER',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            filter: {
                countryStatic: 'US',
                country: 'US',
            },
        }
    })
})

export const actResetStaticCountryFilter = () => ({
    type: 'RESSET_STATIC_PROXY_COUNTRY_FILTER',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            filter: {
                countryStatic: 'US',
                country: 'US',
            },
        }
    })
})

export const actLoadProxyCountryTotals = (getToken, opts) => {
    return async (dispatch, getState) => {
        try {
            const json = await getState().apiClient.withToken(getToken).loadAgentTotals(opts)
            dispatch({
                type: 'SET_COUNTRY_TOTALS',
                reduce: (state) => ({
                    ...state,
                    proxies: {
                        ...state.proxies,
                        countryTotals: json.countries,
                    }
                })
            })    
        } catch (err) {
            console.warn(err)
        }
    }
}

export const actSetSelectedProxy = (proxy) => ({
    type: 'SET_SELECTED_PROXY',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            selected: proxy,
            leaseType: proxy.leases?.count ? 'SHARED' : 'PRIVATE',
        }
    }),
})

export const actSetLeaseType = (leaseType) => ({
    type: 'SET_LEASE_TYPE',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            leaseType: leaseType,
        }
    })
})

export const actSetStaticFilter = (getToken, name, value) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_STATIC_FILTER',
            reduce: (state) => {
                let newFilter = state.proxies?.static?.filter
                newFilter[name] = value
                return {
                    ...state,
                    proxies: {
                        ...state.proxies,
                        static: {
                            ...state.proxies?.static,
                            filter:{
                                ...newFilter,
                            }
                        },
                    }    
                }
            }        
        })
        dispatch(actResetStaticPagination())
        dispatch(actLoadStaticProxies(getToken))
    }
}

export const actSetFilter = (getToken, fields, reset) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_FILTER',
            reduce: (state) => {
                const newFilter = reset ? { country: state.proxies.filter.country } : {
                    ...state.proxies.filter,
                    ...fields,
                }
                if (JSON.stringify(newFilter) === JSON.stringify(state.proxies.filter)) {
                    return state
                }

                state = resetProxies(state)
                return {
                    ...state,
                    proxies: {
                        ...state.proxies,
                        filter: newFilter,
                    }    
                }
            }        
        })
        dispatch(actChangeProxyPage(getToken, 0))
    }
}

export const resetProxies = (state) => ({
    ...state,
    proxies: {
        ...state.proxies,
        pagination: {
            ...state.proxies.pagination,
            page: 0,    
        },
        cursor: 0,
        proxies: null,
        loadedAll: false,
        total: null,
        selected: null,
        leaseType: 'PRIVATE',
        segments: null,
    }
})

const actSetStaticProxiesLoading = (staticloading) => ({
    type: 'SET_STATIC_PROXIES_LOADING',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            staticloading: staticloading,
        }
    })
})

const actSetStaticProxiesResult = (proxiesList) => ({
    type: 'SET_STATIC_PROXIES_RESULT',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            cursor: 0,
            static: {
                ...proxiesList,
                filter: {
                    ip: state.proxies?.static?.filter?.ip ? state.proxies?.static?.filter?.ip : null,
                    city: state.proxies?.static?.filter?.city ? state.proxies?.static?.filter?.city : null,
                    isp: state.proxies?.static?.filter?.isp ? state.proxies?.static?.filter?.isp : null,
                    conn: state.proxies?.static?.filter?.conn ? state.proxies?.static?.filter?.conn : null,
                }
            },
            paginationStatic: {
                page: state.proxies?.paginationStatic?.page ? state.proxies?.paginationStatic?.page : 0,
                rowsPerPage: state.proxies?.paginationStatic?.rowsPerPage ? state.proxies?.paginationStatic?.rowsPerPage : 10,
            }
            
        }
    })
})

export const actResetStaticProxiesFilter = () => ({
    type: 'RESET_STATIC_PROXIES_FILTER',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            static: {
                ...state.proxies.static,
                filter: {
                    ip: null,
                    city: null,
                    isp: null,
                    conn: null,
                }
            }
            
        }
    })
})

const actSetStaticProxiesError = (status) => ({
    type: 'SET_STATIC_PROXIES_ERROR',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            staticError: status,
        }
    })
})

const actSetProxiesLoading = (loading) => ({
    type: 'SET_PROXIES_LOADING',
    reduce: (state) => ({
        ...state,
        proxies: {
            ...state.proxies,
            loading,
        }
    })
})

export const actReloadSelectedProxy = (getToken) => {
    return async (dispatch, getState) => {
        const proxy = getState().proxies.selected
        if (!proxy) {
            return
        }

        let newProxy = null
        try {
            newProxy = await getState().apiClient.withToken(getToken).loadSingleAgent(proxy.id)
        } catch (err) {
            return
        }

        const proxies = [...getState().proxies.proxies]
        for (let i = 0; i < proxies.length; i++) {
            const p = proxies[i]
            if (p.id === newProxy.id) {
                proxies[i] = newProxy
            }
        }

        dispatch({
            type: 'UPDATE_SINGLE_PROXY',
            reduce: (state) => ({
                ...state,
                proxies: {
                    ...state.proxies,
                    proxies: proxies,
                    selected: newProxy,
                }
            })
        })
    }
}

const ignoredBlacklists = ["bl.spamcannibal.org", "bl.emailbasura.org"]
const blacklistNameMappings = {
    "b.barracudacentral.org": "BarracudaCentral",
    "noptr.spamrats.com": "SpamRats (noptr)",
    "dyna.spamrats.com": "SpamRats (dyna)",
    "spam.spamrats.com": "SpamRats (spam)",
    "dnsbl-1.uceprotect.net": "UCEPROTECT (dnsbl-1)",
    "dnsbl-2.uceprotect.net": "UCEPROTECT (dnsbl-2)",
    "dnsbl-3.uceprotect.net": "UCEPROTECT (dnsbl-3)",
    "z.mailspike.net": "MailSpike",
    "cbl.abuseat.org": "AbuseAt.org",
    "drone.abuse.ch": "Abuse.ch",
}

export const filteredBlacklists = (proxy) => {
    if (!proxy.blacklists || !proxy.blacklists.listIDs) {
        return []
    }

    let bl = proxy.blacklists.listIDs.filter(id => {
        return (id !== "") && (!ignoredBlacklists.includes(id))
    })

    bl = bl.map(item => {
        if (item in blacklistNameMappings) {
            return blacklistNameMappings[item]
        } else {
            return item
        }
    })

    bl.sort()

    return bl
}

export const humanizedAge = (age) => {
    return (!age) ? "" : humanizeDuration(age / 1000000, { round: true, largest: 2 })
}

export const humanizedAgeSinceFirstSeen = (firstSeen) => {
    return humanizedTimeSince(firstSeen)
}

export const humanizedTimeSince = (time, prefix, suffix) => {
    if (!time) {
        return "Unknown"
    }
    const momentTime = moment(time)
    if (momentTime.year() < 2000) {
        return "Unknown" // empty time
    }
    return (prefix ? prefix : "") + moment.duration(moment().diff(momentTime)).humanize() + (suffix ? suffix : "")
}

export const humanizedActivity = (actv, probeActv) => {
    if (probeActv && probeActv > 0) {
        return Math.round(probeActv * 100) + '%'
    } else {
        return isNaN(actv) ? "" : Math.round(actv * 100) + '%'
    }
}

export const humanizeIPScore = (score) => {
    return isNaN(score) ? "" : Math.round(score * 100.0) / 100.0
}

export const isAdvancedSearchActive = (filter) => {
    return !!filter.geoCentralZip && !!filter.geoSearchRadius && (filter.geoSearchRadius > 0)
}

export const countriesSupportedForAdvancedSearch = ['US', 'CA', 'IT', 'AU', 'BD', 'BG', 'FR', 'CZ', 'FI', 'DE', 'GT', 'IN', 'IE',
    'LT', 'MY', 'MX', 'NZ', 'PK', 'PH', 'PL', 'PT', 'PR', 'RO', 'RU', 'KR', 'ES', 'LK', 'SE', 'CH', 'BY', 'BE', 'LV', 'TH', 'TR', 'UA']
