export const actLoadExistingSpeedCheck = (getToken, proxyId) => {
    return async (dispatch, getState) => {
        dispatch(actSetProxySpeedcheck(proxyId, {'loadingExisting': true}))
        const check = await getState().apiClient.withToken(getToken).loadSpeedCheck(proxyId, false)
        dispatch(actSetProxySpeedcheck(proxyId, check))
    }
}

export const actRequestNewSpeedCheck = (getToken, proxyId) => {
    return async (dispatch, getState) => {
        dispatch(actSetProxySpeedcheck(proxyId, {'checking': true}))
        const check = await getState().apiClient.withToken(getToken).loadSpeedCheck(proxyId, true)
        dispatch(actSetProxySpeedcheck(proxyId, check))
    }
}

const actSetProxySpeedcheck = (proxyId, check) => {
    return {
        type: 'SET_PROXY_SPEEDCHECK',
        reduce: (state) => {
            const newState = {...state}
            const proxies = newState.proxies.proxies
            for (var i = 0; i < proxies.length; ++i) {
                const p = proxies[i]
                if (p.id === proxyId) {
                    proxies[i] = {...p, speedCheck: check}
                }
            }
            if (newState.proxies.selected?.id === proxyId) {
                newState.proxies.selected = {...newState.proxies.selected, speedCheck: check}
            }
            for (var lease of newState.activeLeases.activeLeases) {
                if (lease.agentId === proxyId) {
                    lease = {...lease, currentInfo: {...lease.currentInfo, speedCheck: check}}
                }
            }
            const selectedLease = newState.activeLeases.selected
            if (selectedLease?.agentId === proxyId) {
                newState.activeLeases.selected = {...selectedLease, currentInfo: {...selectedLease.currentInfo, speedCheck: check}}
            }
            return newState
        }
    }
}