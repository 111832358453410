import React, { useMemo } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Table, TableCell, TableBody, TableRow, makeStyles, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { actSetLeaseType, filteredBlacklists, humanizedActivity, humanizedAgeSinceFirstSeen } from '../../redux/backend/proxies'
import { useDialogStyles } from '../dialog'
import { formatMoney } from '../../util/money'
import { actStartLease } from '../../redux/backend/lease'

const useStyles = makeStyles(window.innerWidth > 480 ?
    {
        dialogPaper: {
            minWidth: 900,
        },
        labelCell: {
            fontWeight: 400,
            width: 100,
        }
    } : {
        dialogPaper: {
            minWidth: 400,
        },
        labelCell: {
            fontWeight: 600,
            width: 100,
        }
    });

const BuyAgainDialog = (props) => {
    const classes = useStyles()
    const dialogClasses = useDialogStyles()

    const buyPrivate = () => {
        props.onClose()
        props.dispatch(actSetLeaseType('PRIVATE'))
        props.dispatch(actStartLease(p))
    }

    const buyShared = () => {
        props.onClose()
        props.dispatch(actSetLeaseType('SHARED'))
        props.dispatch(actStartLease(p))
    }

    const p = props.agentForBuyingAgain
    const blacklists = filteredBlacklists(p || {})

    return (
        <Dialog 
            classes={{paper: dialogClasses.dialog + " " + classes.dialogPaper}} 
            open={props.open} onClose={props.onClose} >
            <DialogTitle>Review And Buy Again</DialogTitle>
            <DialogContent className={dialogClasses.content}>
                <p>Some proxy information may have changed.</p>
                <p>Please review before purchasing.</p>
                {p && <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.labelCell}>IP</TableCell><TableCell>{p.ip}</TableCell>    
                            <TableCell className={classes.labelCell}>Host</TableCell><TableCell>{p.host}</TableCell>    
                            <TableCell className={classes.labelCell}>Country</TableCell><TableCell>{p.loc?.cc}</TableCell>    
                        </TableRow>    
                        <TableRow>
                            <TableCell className={classes.labelCell}>Region</TableCell><TableCell>{p.loc?.reg}</TableCell>    
                            <TableCell className={classes.labelCell}>ZIP</TableCell><TableCell>{p.loc?.zip}</TableCell>    
                            <TableCell className={classes.labelCell}>City</TableCell><TableCell>{p.loc?.city}</TableCell>    
                        </TableRow>    
                        <TableRow>
                            <TableCell className={classes.labelCell}>Uptime</TableCell><TableCell>{humanizedAgeSinceFirstSeen(p.firstSeen)}</TableCell>    
                            <TableCell className={classes.labelCell} colSpan={2}>Activity</TableCell><TableCell>{humanizedActivity(p.actv, p.probeActv)}</TableCell>    
                        </TableRow>    
                        <TableRow>
                            <TableCell className={classes.labelCell}>Blacklists</TableCell>
                            <TableCell colSpan={3}>
                                {blacklists.length === 0 && 'None'}
                                {blacklists && blacklists.map((b) => b + " ")}
                            </TableCell>    
                        </TableRow>
                    </TableBody>    
                </Table>}    
            </DialogContent>
            <DialogActions classes={{root: dialogClasses.actions}}>
                {p?.priceExcC && <Button variant="contained" color="primary" onClick={buyPrivate}>Buy Private ({formatMoney(p.priceExcC)})</Button>}
                <Button variant="contained" color="primary" onClick={buyShared}>Buy Shared ({p && formatMoney(p.priceShrC)})</Button>
                <Button color="primary" onClick={props.onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    ...state.leaseHistory,
    leaseType: state.proxies.leaseType,
})

export default connect(mapStateToProps)(BuyAgainDialog)