import React from 'react';
import MyProxyFilter from '../my-proxies/MyProxyFilter';
import MyProxiesMobileItem from './proxyItemMobile';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useState } from 'react';
import ProxyHistoryItem from '../ProxyHistoryMobile/proxyHistoryItem';
import { useEffect } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../../redux/auth"
import { getProxiesHistory } from '../../redux/backend/proxyHistories';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import { actLoadActiveLeases, actSetSelectedActiveLease } from '../../redux/backend/active-leases';
import { countryNames } from '../../data/countryNames';
import './style.css';
import { actChangeLeaseHistoryPage, actLoadLeaseHistory } from '../../redux/backend/lease-history';
import { formatMoney } from '../../util/money';

const MyProxiesMobilePage = (props) => {

  const formatTimeLeft = (date) => {
    const diffMs = date - Date.now();
    const minutes = Math.max(0, Math.floor(diffMs / (60 * 1000) % 60));
    const hours = Math.floor(diffMs / (60 * 60 * 1000));

    let result = minutes + "m";
    if (hours > 0) {
      result = hours + "h " + result;
    }

    if (result === "0m") {
      result = "now";
    }
    return result;
  };

  const [openHistoryList, setOpenHistoryList] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { dispatch, neverLoaded } = props;

  useEffect(() => {
    if (neverLoaded) {
      dispatch(actLoadLeaseHistory(getAccessTokenSilently));
    }
  }, []);

  useEffect(() => {
    if (neverLoaded) {
        dispatch(actLoadActiveLeases(getAccessTokenSilently))
    }
}, [])

  const [offset, setOffset] = useState(0);
  
  const isMobile = true;
  const handlePageChange = (page) => {
    dispatch(actChangeLeaseHistoryPage(getAccessTokenSilently, page, isMobile));
  };

  useEffect(() => {
    dispatch(getActiveItem('My Proxies'));
  }, [dispatch]);

  const filteredLeases = filterLeases(props.activeLeases.activeLeases, props.filter);

  const handleRowClick = (lease) => {
    dispatch(actSetSelectedActiveLease(lease));
  };

  return (
    <div className='my-proxies-mbile-page'>
      <MyProxyFilter label='Search' />
      {
        props?.loading ?
          <ClipLoader
            color='#1589EE'
            size={30}
            style={{ margin: 'auto' }}
          /> : <div>
            {props?.activeLeases?.activeLeases && filteredLeases && filteredLeases?.map((item) => {
              return (
                <MyProxiesMobileItem
                  numbers={item?.info?.ip}
                  country={countryNames[item?.currentInfo?.loc?.cc]}
                  region={item?.currentInfo?.loc?.reg}
                  city={item?.currentInfo?.loc?.city}
                  sharedCount={item?.currentInfo?.leases?.count}
                  time={formatTimeLeft(new Date(item.until))}
                  status={item.exc ? "Private" : (item.info.leases ? item.currentInfo.leases.count : "Shared")}
                  inactive={+formatTimeLeft(new Date(item.until)).charAt(0) > 4 ? true : false}
                  id={item?.id}
                  onClick={() => handleRowClick(item)}
                />
              );
            })}
          </div>
      }
      <div
        onClick={() => setOpenHistoryList(!openHistoryList)}
        className='show-history'
      >
        {openHistoryList ? 'Hide history' : 'Show history'}
      </div>
      {
        openHistoryList && <div>
          {
            props?.items?.map((item) => {
              return <ProxyHistoryItem
                port={item?.ip}
                country={countryNames[item?.loc?.cc]}
                city={item?.loc?.city}
                region={item?.loc?.reg}
                price={formatMoney(item?.lastPaymentC)}
                id={item?.ip}
              />;
            })
          }
          {
            props?.items?.length < props?.total && <div
              className='show-more-histories-btn'
              onClick={() => {
                setOffset(offset + 1);
                handlePageChange(offset + 1);
              }}>Show more histories</div>
          }
          {
            props?.items && props?.items?.length > 0 && props.loading &&
            <div style={{ width: '100%', textAlign: 'center' }}>
              <ClipLoader
                color='#1589EE'
                size={30}
                className='cliploader shop'
              />
            </div>
          }
        </div>
      }
    </div>
  );
};

const filterLeases = (leases, filter) => {
  if (filter === null || filter === "") {
    return leases;
  }
  const filtered = [];
  const f = filter.toLowerCase();
  for (const lease of leases) {
    if (lease?.info?.ip?.toLowerCase()?.includes(f) ||
      lease?.info?.loc?.cc?.toLowerCase()?.includes(f) ||
      countryNames[lease?.info?.loc?.cc]?.toLowerCase()?.includes(f) ||
      lease?.info?.loc?.reg?.toLowerCase()?.includes(f) ||
      lease?.info?.loc?.city?.toLowerCase()?.includes(f) ||
      lease?.info?.loc?.zip?.toLowerCase()?.includes(f) ||
      lease?.info?.loc?.isp?.toLowerCase()?.includes(f) ||
      lease?.note?.toLowerCase()?.includes(f)) {
      filtered.push(lease);
    }
  }
  return filtered;
};

const mapStateToProps = (state) => ({
  activeLeases: state.activeLeases,
  ...state.leaseHistory,
  filter: state.activeLeases.filter,
});

export default connect(mapStateToProps)(MyProxiesMobilePage);
