import React from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    screen: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

const LoadingScreen = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.screen}>
            <CircularProgress />
        </div>
    )
}

export default LoadingScreen