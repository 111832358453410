import React from "react"
import { useAuth0 } from '../redux/auth'

const AuthContext = React.createContext()
const useAuthContext = () => React.useContext(AuthContext)

const AuthProvider = ({children}) => {

    const auth = useAuth0()

    return <AuthContext.Provider value={auth}>
        {children}
    </AuthContext.Provider>
}

export { useAuthContext, AuthProvider}
