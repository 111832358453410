import React from 'react'
import { Backdrop, CircularProgress, useTheme } from '@material-ui/core'
import { connect } from 'react-redux'


const GlobalBusyIndicator = (props) => {
    const theme = useTheme()
    return (
        <Backdrop open={props.busy} style={{zIndex: theme.zIndex.drawer + 1}}>
            <CircularProgress />
        </Backdrop>
    )
}

const mapStateToProps = (state) => ({busy: state.globalBusy})

export default connect(mapStateToProps)(GlobalBusyIndicator)