import React, { useEffect } from 'react'
import { Dialog, DialogContent, makeStyles, Typography, Slide } from '@material-ui/core'
import { connect } from 'react-redux'
import { useDialogStyles } from './LeaseFlow'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


const LeaseSuccess = (props) => {
    const dialogClasses = useDialogStyles()
    const classes = makeStyles((theme) => ({
        content: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
            marginLeft: theme.spacing(12),
            marginRight: theme.spacing(12),
            textAlign: 'center',
        },
        heading: {
            fontSize: 16,
            fontWeight: 600,
            marginBottom: theme.spacing(4),
            display: 'block',
        },
    }))()

    useEffect(() => {
        if (props.leaseFlow.type === 'SUCCESS') {
            setTimeout(props.onClose, 1000)
        }
    }, [props.leaseFlow, props.onClose])

    return (
        <Dialog 
            open={props.leaseFlow.type === 'SUCCESS'} 
            onClose={props.onClose} 
            classes={{paper: dialogClasses.dialog}} 
            TransitionComponent={Slide} 
            transitionDuration={{enter: 0, exit: 1000}}
        >
            <DialogContent className={`${classes.content} ${dialogClasses.content}`}>
                <Typography className={classes.heading} variant='p'>Purchase successful</Typography>
                <CheckCircleOutlineIcon style={{color: '#24B35F'}} fontSize='large' />
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    leaseFlow: state.leaseFlow,
})

export default connect(mapStateToProps)(LeaseSuccess)
