import { makeStyles } from "@material-ui/core";

// TODO move to /styles
export const useDialogStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(1),
        color: '#3A404D',
    },
    content: {
        color: '#6C7380',
    },
    actions: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
    },
}))
