// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../../redux/auth"
import { Box, Button, IconButton, makeStyles, Table, TableBody, TableHead, TableRow, TextField, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { actLoadAgentForBuyingAgain, actLoadLeaseHistory, actResetBuyAgain, actSetFirstLeaseHistoryDate, actSetLastLeaseHistoryDate, actSetLeaseHistoryQuery } from '../../redux/backend/lease-history'
import { formatMoney } from '../../util/money'
import NoResults from '../NoResults'
import SmartTooltip from '../SmartTooltip'
import { LoadingRow, TableDataCell, TableHeadCell, useTableStyles } from '../table'
import moment from 'moment'
import ReactCountryFlag from 'react-country-flag'
import BuyAgainDialog from './BuyAgainDialog'
import BuyAgainErrorDialog from './BuyAgainErrorDialog'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { bottomBorder } from '../../styles/common'
import ClearIcon from '@material-ui/icons/Clear'


const useStyles = makeStyles((theme) => ({
    flag: {
        width: 20,
        position: 'relative',
        bottom: 2,
        marginRight: theme.spacing(1),
    },
    buyAgainButton: {
        margin: 0,
    },
    row: {
        height: 60,
    },
    datePicker: {
        marginRight: theme.spacing(2),
    },
    topBar: {
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        alignItems: 'center',
        paddingBottom: theme.spacing(2),
        ...bottomBorder(theme),
    },
    filters: {
        margin: theme.spacing(1),
    },
    searchBar: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(3),
    },
    clearIconButton: {
        margin: 0,
        padding: 0,
        height: 28,
        width: 28,
    },
    clearIcon: {
        height: 20,
        margin: 0,
        padding: 0,
    },
    search: {
        margin: theme.spacing(1),
        marginRight: theme.spacing(3),
    }
}))

const LeaseHistoryTable = (props) => {
    const { dispatch, neverLoaded } = props
    const { getAccessTokenSilently } = useAuth0()
    const [ mouseOverLeaseID, setMouseOverLeaseID ] = useState("")
    const [ updateAvailable, setUpdateAvailable ] = useState(true)

    const classes = useStyles()
    const tableClasses = useTableStyles()
    const theme = useTheme()

    const HeadCell = TableHeadCell(theme)
    const DataCell = TableDataCell(theme)

    useEffect(() => {
        if (neverLoaded) {
            dispatch(actLoadLeaseHistory(getAccessTokenSilently))
        }
    }, [neverLoaded, dispatch, getAccessTokenSilently])

    const onBuyAgainClick = (lease) => {
        dispatch(actLoadAgentForBuyingAgain(getAccessTokenSilently, lease))
    }

    // TODO this was copied and pasted from PaymentPage - see if we can extract
    const datePickerLabelFunc = (prefix) => { return (date) => prefix + ' ' + moment(date).format('YYYY-MM-DD') }

    const datePickerProps = {
        className: classes.datePicker,
        inputVariant: 'standard',
        variant: 'inline',
        disableFuture: true,
        autoOk: true,
        minDate: props.minDate,
    }

    const setFirstDate = (date) => {
        dispatch(actSetFirstLeaseHistoryDate(getAccessTokenSilently, date))
    }
    const setLastDate = (date) => {
        dispatch(actSetLastLeaseHistoryDate(getAccessTokenSilently, date))
    }
    const onClickUpdate = () => {
        if (!updateAvailable) {
            return
        }
        dispatch(actLoadLeaseHistory(getAccessTokenSilently))
        
        setUpdateAvailable(false)
        setTimeout(() => setUpdateAvailable(true), 3000)
    }
    const onSearchQueryChange = (e) => {
        dispatch(actSetLeaseHistoryQuery(e?.target?.value))
    }
    const onSearchKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClickUpdate()
            e.preventDefault()
        } 
    }
    const onClickClearSearch = () => {
        dispatch(actSetLeaseHistoryQuery(""))
    }

    return (
        <>
        <Box className={tableClasses.tableAndNoResultsContainer} onMouseOut={() => setMouseOverLeaseID("")}>
            <Box className={classes.topBar}>
                <Box className={classes.searchBar}>
                    <TextField 
                        variant="outlined" 
                        size="small"
                        style={{width: '100%'}}
                        label="Search by location, IP, note..." 
                        value={props.query}
                        onChange={onSearchQueryChange}
                        onKeyPress={onSearchKeyPress}
                        InputProps={{ 
                            endAdornment: props.query && (
                                <IconButton className={classes.clearIconButton} onClick={onClickClearSearch}>
                                    <ClearIcon className={classes.clearIcon} />
                                </IconButton>
                            )
                        }}/>
                </Box>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Box className={classes.filters}>
                        <DatePicker
                            {...datePickerProps} 
                            onChange={setFirstDate} 
                            labelFunc={datePickerLabelFunc('First date:')} 
                            value={props.firstDate} 
                        />
                        <DatePicker 
                            {...datePickerProps} 
                            onChange={setLastDate} 
                            labelFunc={datePickerLabelFunc('Last date:')} 
                            value={props.lastDate} 
                        />
                    </Box>
                </MuiPickersUtilsProvider>
                <Box className={classes.search}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={onClickUpdate}
                        disabled={!updateAvailable}>
                            Search
                    </Button>                    
                </Box>
            </Box>
            <Table className={tableClasses.table} stickyHeader enabled={updateAvailable}>
                <TableHead>                    
                    <HeadCell style={{width: 160}}>Original IP</HeadCell>
                    <HeadCell style={{width: 140}}>SOCKS User</HeadCell>
                    <HeadCell style={{width: 160}}>Start</HeadCell>
                    <HeadCell style={{width: 160}}>End</HeadCell>
                    <HeadCell style={{width: "15%"}}>Country</HeadCell>
                    <HeadCell style={{width: "15%"}}>Region</HeadCell>
                    <HeadCell style={{width: "15%"}}>City</HeadCell>
                    <HeadCell style={{width: "15%"}}>ZIP</HeadCell>
                    <HeadCell style={{width: "20%"}}>ISP</HeadCell>
                    <HeadCell style={{width: "20%"}}>Note</HeadCell>
                    <HeadCell style={{width: 140, textAlign: 'center'}}>Price Paid</HeadCell>
                </TableHead>
                <TableBody>
                    {props.loading && <LoadingRow />}
                    {(!props.loading) && (props.items.map((i) => (
                        <TableRow
                            hover 
                            key={i.leaseID} 
                            className={`${tableClasses.dataRow} ${classes.row}`}
                            onMouseOver={() => setMouseOverLeaseID(i.leaseID)}
                            onMouseOut={() => setMouseOverLeaseID("")}
                        >
                            <DataCell>{i.ip}</DataCell>
                            <DataCell>{i.socksUser}</DataCell>
                            <DataCell>{moment(i.start).format('MMM-D LT')}</DataCell>
                            <DataCell>{moment(i.paidUntil).format('MMM-D LT')}</DataCell>
                            <DataCell>
                                <span>
                                    <ReactCountryFlag className={classes.flag} countryCode={i.loc.cc} svg />
                                    {i.loc.cc}
                                </span>
                            </DataCell>
                            <DataCell><SmartTooltip>{i.loc.reg}</SmartTooltip></DataCell>
                            <DataCell><SmartTooltip>{i.loc.city}</SmartTooltip></DataCell>
                            <DataCell><SmartTooltip>{i.loc.zip}</SmartTooltip></DataCell>
                            <DataCell><SmartTooltip>{i.loc.isp}</SmartTooltip></DataCell>
                            <DataCell><SmartTooltip>{i.note}</SmartTooltip></DataCell>
                            <DataCell style={{textAlign: 'center'}}>{(mouseOverLeaseID === i.leaseID) ? 
                                <Button 
                                    color="primary" size="small" variant="contained" 
                                    className={classes.buyAgainButton}
                                    onClick={() => { onBuyAgainClick(i) }}>
                                        Buy Again
                                </Button> 
                                : formatMoney(i.lastPaymentC)}</DataCell>
                        </TableRow>
                    )))}
                </TableBody>
            </Table>
            {(props.items.length === 0) && <NoResults message='No leases in the selected time period' />}
        </Box>
        <BuyAgainDialog 
            open={props.agentForBuyingAgain !== null} 
            onClose={() => dispatch(actResetBuyAgain())} />
        <BuyAgainErrorDialog 
            open={props.agentForBuyingAgainNotFound} 
            onClose={() => dispatch(actResetBuyAgain())} 
            message="This proxy is currently unavailable for purchase." />
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state.leaseHistory
})

export default connect(mapStateToProps)(LeaseHistoryTable)
