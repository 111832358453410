import React from 'react'
import { useLocalStorage } from './useLocalStorage'
import { useState } from "react";

export const useAuth0 = () => {
    const [user, setUser] = useLocalStorage("user", null)

    const getAccessTokenSilently = async () => {
        // console.log('token')
        // console.log(user)
        return user
    }

    const setToken = async (token) => {
        setUser(token)
    }

    const logout = async (data) => {
        setUser(null)
        window.location.href = data.returnTo
    }

    return { user, getAccessTokenSilently, setToken, logout }
}