import { actSetGlobalBusy } from "../actions"
import { actLoadAccount } from './account'
import { CHARGESTATUS_OK, CHARGESTATUS_ERROR } from "./api-client"

export const actRequestChargeStatus = (getToken, chargeUid) => {
    return async (dispatch, getState) => {
        try {
            dispatch(actSetGlobalBusy(true))
            const result = await getState().apiClient.withToken(getToken).getChargeStatus(chargeUid)

            if(result.status == 'paid'){
              dispatch(actLoadAccount(getToken))
            }

            dispatch(actSetChargeStatus(result?.data))

        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}
export const actSetChargeStatus = (result) => ({
    type: 'SET_CHARGESTATUS_RESULT',
    reduce: (state) => ({
        ...state,
        chargeInfo: result
    })
})
