import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../redux/auth"
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { actRequestChargeStatus } from './../redux/backend/chargestatus';
import './css/ChargePage.css';
import { CopySnackbar } from './my-proxies/CopyButton';
import Alert from '@mui/material/Alert'
import {QRCodeSVG} from 'qrcode.react'
import AlertTitle from '@mui/material/AlertTitle'
import Snackbar from '@mui/material/Snackbar'

const ChargePage = (props) => {

  const mainStyles = useMemo(() => makeStyles(window.innerWidth > 480 ?
		{
      maindiv: {
        width: 471,
        height: 360,
        // position: 'absolute',
        left: 496,
        top: 104,
        alignItems: 'center',
      },
      dataDiv: {
        width: 471,
        height: 360,
        position: 'absolute',
        flexDirection: 'row',
        left: 496,
        top: 104,
        alignItems: 'center',
      },
      headerDiv: {
        fontFamily: 'SF Pro',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        gap: 8,
        width: 469,
        height: 24,
        left: 498,
        top: 104,
      },
      chargeid: {
        width: 300,
        height: 24,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 510,
        fontSize: 15,
        color: '#000000',
        order: 0,
        flexGrow: 0
      },
      statusdiv1: {
        width: 100,
        height: 20,
        flex: 'none',
        order: 1,
        flexGrow: 0,
      },
      statusDiv2: {
        position: 'absolute',
        height: 20,
        top: 2,
        marginLeft: 18,
        alignItems: 'center',
      },
      statusDiv2Pending: {
        position: 'absolute',
        height: 20,
        top: 2,
        marginLeft: 30,
        alignItems: 'center',
      },
      statusDiv2Paid: {
        position: 'absolute',
        height: 20,
        top: 2,
        marginLeft: 41,
        alignItems: 'center',
      },
      statusText: {
        height: 16,
        top: 4,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 10,
        textTransform: 'uppercase',
        color: '#FFFFFF',
      },
      statusRectangle: {
        height: 20,
        left: 428,
        top: 2,
        background: '#FF9900',
        borderRadius: 11,
      },
      statusRectanglePending: {
        height: 20,
        left: 428,
        top: 2,
        background: '#006DFD',
        borderRadius: 11,
      },
      statusRectanglePaid: {
        height: 20,
        left: 428,
        top: 2,
        background: '#58B000',
        borderRadius: 11,
      },
      useridDiv: {
        width: 335,
        height: 40,
        left: 496,
        top: 168,
        marginTop: 40,
      },
      userStringDiv: {
        width: 31,
        height: 16,
        left: 496,
        top: 168,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#262121'
      },
      userStringID: {
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        color: '#242933'
      },
      userEmailDiv: {
        width: 335,
        height: 40,
        left: 496,
        top: 232,
        marginTop: 15,
      },
      paymentinfoDiv: {
        width: 335,
        // height: 40,
        left: 496,
        top: 232,
        marginTop: 8,
      },
      paymentinfoDiv2: {
        width: 335,
        height: 40,
        left: 496,
        top: 232,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      paymentinfoString: {
        // width: 31,
        height: 16,
        left: 496,
        top: 168,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#262121'
      },
      paymentinfoStringAmount: {
        // position: 'absolute',
        height: 16,
        left: 496,
        top: 168,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#262121'
      },
      paymentinfoStringAmountUsd: {
        // position: 'absolute',
        height: 16,
        left: 596,
        top: 168,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#262121',
        marginLeft: 40,
      },
      paymentinfoStringExhangeRate: {
        height: 16,
        left: 596,
        top: 168,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#262121',
        marginLeft: 40,
      },
      paymentinfoData: {
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        color: '#242933'
      },
      copyText: {
        fontSize: 10,
        cursor: 'pointer',
        color: '#3a9acf',
        textDecoration: 'none'
      },
      bonusDiv: {
        width: 448,
        height: 128,
        left: 496,
        top: 512,
        marginTop: 48,
      },
      bonusDivText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 0,
        gap: 8,
        width: 369,
        height: 24,
        left: 498,
        top: 512,
      },
      bonusText: {
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        textTransform: 'capitalize',
        color: '#438600',
      },
      bonusAmountDiv: {
        boxSizing: 'border-box',
        // position: absolute;
        width: 448,
        height: 88,
        left: 496,
        top: 552,
        background: 'rgba(88, 176, 0, 0.05)',
        border: '1px solid #438600',
        borderRadius: 8,
      },
      bonusAmountTextDiv: {
        width: 108,
        height: 48,
        left: 666,
        top: 572,
        marginLeft: 177,
      },
      bonusAmountText: {
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 590,
        fontSize: 32,
        color: '#438600',
      },
      pLeft: {
        float: 'left',
        marginLeft: 10,
        fontFamily: 'SF Pro Medium',
        color: '#525966'
      },
      pRight: {
        float: 'right',
        marginRight: 20,
        fontFamily: 'SF Pro Medium',
      },
      note: {
        color: '#ff4d4d',
        fontSize: 12,
      }
    } : {
      maindiv: {
        width: 'auto',
        height: 'auto',
        // position: 'absolute',
        left: 0,
        top: 75,
        alignItems: 'center',
      },
      dataDiv: {
        width: 350,
        height: 'fit-content',
        position: 'absolute',
        flexDirection: 'row',
        left: 20,
        top: 70,
        alignItems: 'center',
      },
      headerDiv: {
        fontFamily: 'SF Pro',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
        gap: 8,
        width: 'fit-content',
        height: 24,
        left: 20,
        top: 104,
      },
      chargeid: {
        width: 300,
        height: 24,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 510,
        fontSize: 15,
        color: '#000000',
        order: 0,
        flexGrow: 0
      },
      statusdiv1: {
        width: 100,
        height: 20,
        flex: 'none',
        order: 1,
        flexGrow: 0,
      },
      statusDiv2: {
        position: 'absolute',
        height: 20,
        top: 20,
        marginLeft: 18,
        alignItems: 'center',
      },
      statusDiv2Pending: {
        position: 'absolute',
        height: 20,
        top: 2,
        marginLeft: 30,
        alignItems: 'center',
      },
      statusDiv2Paid: {
        position: 'absolute',
        height: 20,
        top: 2,
        marginLeft: 41,
        alignItems: 'center',
      },
      statusText: {
        height: 16,
        top: 4,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 10,
        textTransform: 'uppercase',
        color: '#FFFFFF',
      },
      statusRectangle: {
        height: 20,
        left: 428,
        top: 2,
        background: '#FF9900',
        borderRadius: 11,
      },
      statusRectanglePending: {
        height: 20,
        left: 20,
        top: 2,
        background: '#006DFD',
        borderRadius: 11,
      },
      statusRectanglePaid: {
        height: 20,
        left: 20,
        top: 2,
        background: '#58B000',
        borderRadius: 11,
      },
      useridDiv: {
        width: 'fit-content',
        height: 40,
        left: 20,
        top: 168,
        marginTop: 40,
      },
      userStringDiv: {
        width: 31,
        height: 16,
        left: 20,
        top: 168,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#262121'
      },
      userStringID: {
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        color: '#242933'
      },
      userEmailDiv: {
        width: 'fit-content',
        height: 40,
        left: 20,
        top: 232,
        marginTop: 15,
      },
      paymentinfoDiv: {
        width: "auto",
        // height: 40,
        left: 20,
        top: 232,
        marginTop: 8,
      },
      paymentinfoDiv2: {
        width: 335,
        height: 40,
        left: 20,
        top: 232,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      paymentinfoString: {
        // width: 31,
        height: 16,
        left: 20,
        top: 168,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#262121'
      },
      paymentinfoStringAmount: {
        // position: 'absolute',
        height: 16,
        left: 20,
        top: 168,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#262121'
      },
      paymentinfoStringAmountUsd: {
        // position: 'absolute',
        height: 16,
        left: 20,
        top: 168,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#262121',
        marginLeft: 40,
      },
      paymentinfoStringExhangeRate: {
        height: 16,
        left: 20,
        top: 168,
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#262121',
        marginLeft: 40,
      },
      paymentinfoData: {
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        color: '#242933'
      },
      copyText: {
        fontSize: 10,
        cursor: 'pointer',
        color: '#3a9acf',
        textDecoration: 'none'
      },
      bonusDiv: {
        width: 448,
        height: 128,
        left: 20,
        top: 512,
        marginTop: 48,
      },
      bonusDivText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 0,
        gap: 8,
        width: 369,
        height: 24,
        left: 20,
        top: 512,
      },
      bonusText: {
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        textTransform: 'capitalize',
        color: '#438600',
      },
      bonusAmountDiv: {
        boxSizing: 'border-box',
        // position: absolute;
        width: 448,
        height: 88,
        left: 20,
        top: 552,
        background: 'rgba(88, 176, 0, 0.05)',
        border: '1px solid #438600',
        borderRadius: 8,
      },
      bonusAmountTextDiv: {
        width: 108,
        height: 48,
        left: 20,
        top: 572,
        marginLeft: 177,
      },
      bonusAmountText: {
        fontFamily: 'SF Pro',
        fontStyle: 'normal',
        fontWeight: 590,
        fontSize: 32,
        color: '#438600',
      },
      pLeft: {
        float: 'left',
        marginLeft: 10,
        fontFamily: 'SF Pro Medium',
        color: '#525966'
      },
      pRight: {
        float: 'right',
        marginRight: 20,
        fontFamily: 'SF Pro Medium',
      },
      note: {
        color: '#ff4d4d',
        fontSize: 8,
      }
    }), []);

  const classes = mainStyles()

  const { dispatch } = props;
  const { transactionId } = useParams()

  const [invoiceInfo, setInvoiceInfo] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarWarningOpen, setSnackbarWarningOpen] = useState(false)

  const { getAccessTokenSilently } = useAuth0()
  const now = new Date()

  useEffect(() => {
    const invoiceInfo = JSON.parse(sessionStorage.getItem(transactionId))
    if (now.getTime() / 1000 >= invoiceInfo?.expire || props?.chargeInfo?.status == 'paid') {
      sessionStorage.removeItem(transactionId)
    }
    setInvoiceInfo(invoiceInfo)

  }, []);

  useEffect(() => {
    if (props?.chargeInfo?.status != 'paid') {
      dispatch(actRequestChargeStatus(getAccessTokenSilently, transactionId));
    }
  }, []);

  useEffect(() => {
    if (props?.chargeInfo?.status != 'paid') {
      const interval = setInterval(() => {
        dispatch(actRequestChargeStatus(getAccessTokenSilently, transactionId));
        if (props?.chargeInfo?.status == 'paid') {
          clearInterval(interval);
          sessionStorage.removeItem(transactionId);
        }
      }, 30000);
      return () => clearInterval(interval);
    }
    return;
  }, []);


  const coptyToBuffer = (text) => {
    navigator.clipboard.writeText(text)
    // navigator.clipboard.readText().then((clipText) => (console.log(clipText)))
    setSnackbarOpen(true)
    setSnackbarWarningOpen(true)
    
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  };

  const formatDate = (date) => {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  };

  const dateTime = new Date(props?.chargeInfo?.date);
  const format = formatDate(dateTime);

  const getUsdBonus = (usdAmount, bonus) => {
    return Math.round(usdAmount * bonus / 100);
  };

  const amountUsd = props?.chargeInfo?.amountUsd / 100;
  const exchangeRate = props?.chargeInfo?.exchangeRate / 100;

  const usdBonus = getUsdBonus(amountUsd, props?.chargeInfo?.bonusType);

  return (
        <div className={classes.maindiv}>
          <div className={classes.dataDiv}>
            <div className={classes.headerDiv}>
              <div className={classes.chargeid}><Typography variant="string" ><strong>{transactionId}</strong></Typography></div>
              <div className={classes.statusdiv1}>
                {props.chargeInfo?.status == "no payments" ? (
                  <div>
                    <div className={classes.statusDiv2}><Typography variant="string" className={classes.statusText}>{props.chargeInfo?.status}</Typography></div>
                    <div className={classes.statusRectangle}></div>
                  </div>
                ) : props.chargeInfo?.status == "paid" ? (
                  <div>
                    <div className={classes.statusDiv2Paid}><Typography variant="string" className={classes.statusText}>{props.chargeInfo?.status}</Typography></div>
                    <div className={classes.statusRectanglePaid}></div>
                  </div>
                ) : (
                  <div>
                    <div className={classes.statusDiv2Pending}><Typography variant="string" className={classes.statusText}>{props.chargeInfo?.status}</Typography></div>
                    <div className={classes.statusRectanglePending}></div>
                  </div>
                )
                }
              </div>
            </div>
            <div className={classes.useridDiv}>
              <div className={classes.userStringDiv}><Typography variant="string">UserID</Typography></div>
              <div className={classes.userStringID}><Typography variant="string"><strong>{props.account.userID}</strong></Typography></div>
            </div>
            <div className={classes.userEmailDiv}>
              <div className={classes.userStringDiv}><Typography variant="string">Email</Typography></div>
              <div className={classes.userStringID}><Typography variant="string"><strong>{props.account.email}</strong></Typography></div>
            </div>
            {props.chargeInfo?.status == "no payments" ? (
              <div>
                <div className={classes.paymentinfoDiv}>
                  <div className={classes.paymentinfoString}><Typography variant="string">Type</Typography></div>
                  <div className={classes.paymentinfoData}><Typography variant="string"><strong>{props?.chargeInfo?.type.toUpperCase()}</strong></Typography></div>
                </div>
                <div className={classes.paymentinfoDiv}>
                  <div className={classes.paymentinfoString}><Typography variant="string">Address <strong><span className={classes.copyText} onClick={() => coptyToBuffer(invoiceInfo?.currencyAddress)}>copy</span></strong></Typography></div>
                  <div className={classes.paymentinfoData}><Typography variant="string"><strong>{props?.chargeInfo?.address}</strong></Typography></div>
                </div>
                <div className={classes.paymentinfoDiv}>
                {invoiceInfo?.currencyType == 'trc20usdt' && 
                  <div className={classes.note}>
                      <Typography variant="string">NOTE: We stop monitoring the wallet after 40 minutes and if you send funds after this time, we will not receive this transaction and your amount will be lost. There is also no way to find this transaction and add it manually.</Typography>
                  </div>
                }
                </div>
                <div className={classes.paymentinfoDiv}>
                  <div className={classes.note}>
                      <Typography variant="string" >NOTE: while sending crypto be sure that wallet you are copy from site is the same that you paste.</Typography>
                  </div>
                  </div>
                <div className={classes.paymentinfoDiv}>
                  <div className={classes.paymentinfoString}><Typography variant="string">Currency amount <strong><span className={classes.copyText} onClick={() => coptyToBuffer(invoiceInfo?.currencyAmount)}>copy</span></strong></Typography></div>
                  <div className={classes.paymentinfoData}><Typography variant="string"><strong>{invoiceInfo?.currencyAmount}</strong></Typography></div>
                </div>
                <div className={classes.paymentinfoDiv}>
                 {invoiceInfo?.currencyType == 'btc' ? (
                    <QRCodeSVG value={"bitcoin:"+invoiceInfo?.currencyAddress+"?amount="+invoiceInfo?.currencyAmount} />
                    // <img src={"https://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=bitcoin:"+invoiceInfo?.currencyAddress+"?amount="+invoiceInfo?.currencyAmount} />
                  ):invoiceInfo?.currencyType == 'ltc' ? (
                    <QRCodeSVG value={"litecoin:"+invoiceInfo?.currencyAddress+"?amount="+invoiceInfo?.currencyAmount} />
                    // <img src={"https://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=litecoin:"+invoiceInfo?.currencyAddress+"?amount="+invoiceInfo?.currencyAmount} />
                  ): (
                    // <QRCodeSVG value={"trc20:"+invoiceInfo?.currencyAddress+"?amount="+invoiceInfo?.currencyAmount} />
                    <></>
                  )
                  }
                </div>
              </div>
            ) : props.chargeInfo?.status == "paid" ? (
              <div>
                <div className={classes.paymentinfoDiv}>
                  <div className={classes.paymentinfoString}><Typography variant="string">Type</Typography></div>
                  <div className={classes.paymentinfoData}><Typography variant="string"><strong>{props?.chargeInfo?.type.toUpperCase()}</strong></Typography></div>
                </div>
                <div className={classes.paymentinfoDiv}>
                  <div className={classes.paymentinfoString}><Typography variant="string">Date time</Typography></div>
                  <div className={classes.paymentinfoData}><Typography variant="string"><strong>{format}</strong></Typography></div>
                </div>
                <div className={classes.paymentinfoDiv2}>
                  <div className={classes.paymentinfoStringAmount}>
                    <div className={classes.paymentinfoString}><Typography variant="string">Currency amount</Typography></div>
                    <div className={classes.paymentinfoData}><Typography variant="string"><strong>{props?.chargeInfo?.amountCrypto}</strong></Typography></div>
                  </div>
                  <div className={classes.paymentinfoStringAmountUsd}>
                    <div className={classes.paymentinfoString}><Typography variant="string">USD amount</Typography></div>
                    <div className={classes.paymentinfoData}><Typography variant="string"><strong>{amountUsd}</strong></Typography></div>
                  </div>
                  <div className={classes.paymentinfoStringExhangeRate}>
                    <div className={classes.paymentinfoString}><Typography variant="string">Exchange Rate</Typography></div>
                    <div className={classes.paymentinfoData}><Typography variant="string"><strong>{exchangeRate}</strong></Typography></div>
                  </div>
                </div>
                {props?.chargeInfo?.bonusType > 0 ? (
                  <div className={classes.bonusDiv}>
                    <div className={classes.bonusDivText}>
                      <Typography variant="string" className={classes.bonusText}>Congratulations, You earned the bonus!</Typography>
                    </div>
                    <div className={classes.bonusAmountDiv}>
                      <div className={classes.bonusAmountTextDiv}><div className={classes.bonusAmountText}><Typography variant="string"><strong>${usdBonus}</strong></Typography></div></div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            ) : (
              <div>
                <div className={classes.paymentinfoDiv}>
                  <div className={classes.paymentinfoString}><Typography variant="string">Type</Typography></div>
                  <div className={classes.paymentinfoData}><Typography variant="string"><strong>{props?.chargeInfo?.type.toUpperCase()}</strong></Typography></div>
                </div>
                <div className={classes.paymentinfoDiv}>
                  <div className={classes.paymentinfoString}><Typography variant="string">Address</Typography></div>
                  <div className={classes.paymentinfoData}><Typography variant="string"><strong>{props?.chargeInfo?.address}</strong></Typography></div>
                </div>
                <div className={classes.paymentinfoDiv}>
                  <div className={classes.paymentinfoString}><Typography variant="string">Currency amount</Typography></div>
                  <div className={classes.paymentinfoData}><Typography variant="string"><strong>{props?.chargeInfo?.amountCrypto}</strong></Typography></div>
                </div>
              </div>
            )
            }
          </div>
          <CopySnackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} />
          <Snackbar
            open={snackbarWarningOpen}
            autoHideDuration={5000} onClose={()=>setSnackbarWarningOpen(false)}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                  <Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>
                    <b>NOTE: while sending crypto be sure that wallet you are copy from site is the same that you have paste.</b>
                  </Alert>
            </Snackbar>
        </div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
  account: state.account,
  chargeInfo: state.chargeInfo,
  currencyAmount: state.addFunds.amount
});

export default connect(mapStateToProps)(ChargePage);
