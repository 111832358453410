import React, { useState } from 'react';
import { connect } from 'react-redux';
import chipCard from '../styles/chipCard';
import { makeStyles, Box, Popover } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { countryCodesSortedByCountryName, countryNames } from '../data/countryNames';
import ReactCountryFlag from 'react-country-flag';
import { FixedSizeList } from 'react-window';
import { actChangeCountry } from '../redux/backend/proxies';
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../redux/auth"


const menuWidth = 232;

const useStyles = makeStyles((theme) => ({
    card: chipCard(theme),
    active: {
        borderColor: theme.palette.primary.main,
    },
    content: {
        display: 'inline',
        padding: 0,
        margin: 0,
    },
    expandIcon: {
        height: 15,
        position: 'relative',
        top: 3,
    },
    menuPaper: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1.5),
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
        borderWidth: 1,
        cursor: 'default',
    },
    menuItemContent: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        width: menuWidth,
        maxWidth: menuWidth,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
        },
    },
    menuItemFlag: {
        marginRight: theme.spacing(1),
        width: 24,
        minWidth: 24,
        height: 16,
        minHeight: 16,
    },
    menuItemCountryName: {
        flexGrow: 1,
    },
}));

const MoreCountries = (props) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMenuActive = () => Boolean(anchorEl);
    const handleClick = (ev) => {
        anchorEl == null ? setAnchorEl(ev.currentTarget) : setAnchorEl(null);
    };
    const closeMenu = () => setAnchorEl(null);

    const { getAccessTokenSilently } = useAuth0();
    const handleCountryItemClick = (countryCode) => {
        props.dispatch(actChangeCountry(getAccessTokenSilently, countryCode));
        closeMenu();
    };

    const ellipsizeName = (name) => name.length > 15 ? name.substr(0, 15) + '...' : name;

    const filteredCountryCodes = countryCodesSortedByCountryName.filter((cc) => props.countryTotals[cc]);
    
    const [countryCode, setCountryCode] = useState(props?.filter?.country)
    const [countryName, setCountryName] = useState(countryNames[props?.filter?.country])
    // const [countryFlag, setCountryFlag] = useState('')


    const CountryMenuItem = ({ index, style }) => {
        const countryCode = filteredCountryCodes[index];
        const countryName = countryNames[countryCode];
        return (
            <Box style={style} className={classes.menuItemContent}
                onClick={() => {
                    handleCountryItemClick(countryCode);
                    setCountryCode(countryCode)
                    setCountryName(countryName)
                    props.dispatch(actChangeCountry(getAccessTokenSilently, countryCode))
                }}>
                <ReactCountryFlag countryCode={countryCode} svg className={classes.menuItemFlag} />
                <Box className={classes.menuItemCountryName}>{ellipsizeName(countryName)}</Box>
                <Box>{props.countryTotals[countryCode]}</Box>
            </Box>
        );
    };

    return (
        <div className='more-countries-container'>
            <Box className={`${classes.card} ${isMenuActive() ? classes.active : ''}`} onClick={handleClick}>
                {
                    window.innerWidth <= 480 ?
                        <Box
                            className={classes.content}
                            component='span'
                            onClick={() => {}}>
                            <ReactCountryFlag
                                countryCode={countryCode}
                                svg
                                className={classes.menuItemFlag}
                            />
                             {countryName} <ExpandMoreIcon className={classes.expandIcon} /> </Box> 
                        : <Box className={classes.content} component='span'>
                            More <ExpandMoreIcon className={classes.expandIcon} />
                        </Box>
                }
                <Popover
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    keepMounted
                    open={isMenuActive()}
                    onClose={closeMenu}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transitionDuration={0}
                    classes={{ paper: classes.menuPaper }}>
                    <FixedSizeList
                        height={400}
                        itemCount={filteredCountryCodes.length}
                        itemSize={40}
                        width={menuWidth}
                    >
                        {CountryMenuItem}
                    </FixedSizeList>
                </Popover>
            </Box>
        </div>
    );
};

const mapStateToProps = (state) => ({
    countryTotals: state.proxies.countryTotals || {},
    filter: state.proxies.filter
});

export default connect(mapStateToProps)(MoreCountries);
