import { actSetGlobalBusy } from "../actions"
import { actLoadAccount } from "./account"
import { actLoadActiveLeases } from "./active-leases"
import { RENEW_STATUS_NOT_ENOUGH_FUNDS, RENEW_STATUS_RENEWED } from "./api-client"


export const initialRenewState = () => offState

const offState = {
    type: 'OFF'
}

export const actConfirmRenew = (lease) => ({
    type: 'CONFIRM_RENEW',
    reduce: (state) => ({
        ...state,
        renewFlow: {
            type: 'CONFIRM',
            lease,
        }
    }),
})

export const actResetRenew = () => ({
    type: 'RESET_RENEW',
    reduce: (state) => ({
        ...state,
        renewFlow: offState,
    })
})

export const actPerformRenew = (getToken) => {
    return async (dispatch, getState) => {
        const lease = getState().renewFlow.lease

        dispatch({ 
            type: 'PERFORM_RENEW',
            reduce: (state) => ({
                ...state,
                renewFlow: {
                    type: 'PROGRESS',
                    lease: lease,
                }
            })
        })

        let result = null
        dispatch(actSetGlobalBusy(true))
        try {
            result = await getState().apiClient.withToken(getToken).renewLease(lease.id)
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
        
        if (result === RENEW_STATUS_RENEWED)  {
            dispatch({
                type: 'RENEW_SUCCESS',
                reduce: (state) => ({
                    ...state,
                    renewFlow: {
                        type: 'SUCCESS',
                        lease: lease,
                    }
                })
            })

            dispatch(actLoadActiveLeases(getToken, true))
            dispatch(actLoadAccount(getToken))
        } else if (result === RENEW_STATUS_NOT_ENOUGH_FUNDS) {
            dispatch({
                type: 'RENEW_NOT_ENOUGH_FUNDS',
                reduce: (state) => ({
                    ...state,
                    renewFlow: {
                        type: 'NOT_ENOUGH_FUNDS',
                    }
                })
            })
        } else {
            dispatch({
                type: 'RENEW_FAILED',
                reduce: (state) => ({
                    ...state,
                    renewFlow: {
                        type: 'FAILED'
                    }
                })
            })
        }
    }
}

export const calculateRenewPrice = (lease) => {
    const start = new Date(lease.start)
    const until = new Date(lease.until)

    const hours = (until.getTime() - start.getTime()) / 1000.0 / 60.0 / 60.0

    return Math.floor((lease.purchasePriceC / hours) * 24.0)
}
