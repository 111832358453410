import React from 'react'
import { Dialog, DialogContent, CircularProgress, Typography, makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { useDialogStyles } from './LeaseFlow'


const LeaseLeasing = (props) => {
    const dialogClasses = useDialogStyles()
    const classes = makeStyles((theme) => ({
        content: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(6),
            marginLeft: theme.spacing(14),
            marginRight: theme.spacing(14),
            textAlign: 'center',
        },
        heading: {
            display: 'block',
            marginBottom: theme.spacing(4),
        },
    }))()

    return (
        <Dialog open={true} disableBackdropClick={true} classes={{paper: dialogClasses.dialog}} transitionDuration={0}>
            <DialogContent className={`${classes.content} ${dialogClasses.content}`}>
                <Typography variant='p' className={classes.heading}>
                    Purchasing the proxy
                </Typography>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    leaseFlow: state.leaseFlow,
})

export default connect(mapStateToProps)(LeaseLeasing)
