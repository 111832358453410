import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { actResetRefundFailed } from '../../redux/backend/active-leases'
import { REFUND_STATUS_ALIVE, REFUND_STATUS_INELIGIBLE } from '../../redux/backend/api-client'
import { useDialogStyles } from '../dialog'


const RefundFailedDialog = (props) => {
    const dialogClasses = useDialogStyles()

    let message = null
    switch (props.reason) {
    case REFUND_STATUS_INELIGIBLE:
        message = (
            <>
                <p>Your lease is not eligible for a refund.</p>
                <p>Please contact support in <a href="tg://resolve?domain=bigma_network" target="_blank">telegram</a>.</p>
            </>
        )
        break
    case REFUND_STATUS_ALIVE:
        message = (
            <>
                <p>The proxy is responsive and thus ineligible for an auto-refund.</p>
                <p>Please contact support in <a href="tg://resolve?domain=bigma_network" target="_blank">telegram</a> for further questions.</p>
            </>
        )
        break
    default:
        message = (
            <>
                <p>There was an error processing the refund request.</p>
                <p>Please contact support in <a href="tg://resolve?domain=bigma_network" target="_blank">telegram</a>.</p>
            </>
        )
    }

    const close = () => {
        props.dispatch(actResetRefundFailed())
    }

    return (
        <Dialog open={props.failedEvent} onClose={close} classes={{paper: dialogClasses.dialog}}>
            <DialogTitle>Refund Canceled</DialogTitle>
            <DialogContent className={dialogClasses.content}>
                {message}
            </DialogContent>
            <DialogActions classes={{root: dialogClasses.actions}}>
                <Button
                    onClick={close}
                    variant='contained' 
                    color='primary' 
                    disableElevation>OK</Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    failedEvent:   state.activeLeases.refundFailedEvent,
    reason: state.activeLeases.refundFailReason,
})

export default connect(mapStateToProps)(RefundFailedDialog)