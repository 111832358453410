// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../../redux/auth"
import { Paper, styled, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { actSetFilter } from '../../redux/backend/proxies';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useEffect } from 'react';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import { ClipLoader } from 'react-spinners';
import { NavLink } from 'react-router-dom';
import './style.css';


const Filter = (props) => {

  const dispatch = props.dispatch;
  const filter = props.filter;
  const { getAccessTokenSilently } = useAuth0();
  const FilterPaper = styled(Paper)({
    minWidth: 300,
    fontSize: 14,
  });

  const SegmentFilterField = (props) => {
    // console.log(props.value,'valueeeeeeee');
    return (
      <Autocomplete
        disabled={(!props.value) && props.disabled}
        freeSolo={Boolean(props.value)}
        openOnFocus={true}
        filterSelectedOptions={true}
        renderInput={(params) =>
          <TextField {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              autoComplete: 'new-password',
              readOnly: Boolean(props.value),
            }}
            autoComplete='new-password'
            placeholder='Any' />}
        options={props.options || []}
        PaperComponent={FilterPaper}
        value={props.value}
        getOptionLabel={props.getOptionLabel || ((o) => o)}
        onChange={(_, v) => {
          // console.log(v, 'v---------');
          // eslint-disable-next-line no-unused-expressions
          props?.filter(v);
          dispatch(actSetFilter(getAccessTokenSilently));
        }} />
    );
  };

  const changeType = (_, v, props) => {
    dispatch(actSetFilter(getAccessTokenSilently, props.filter(v)));
  };

  
  const changeCentralZip = (e) => {
    setCentralZip(e.target?.value || null);
  };

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 80,
      label: '80',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  useEffect(() => {
    dispatch(getActiveItem('Filter'));
  }, [dispatch]);

  const [region, setRegion] = useState(props?.filter?.region && props?.filter?.region);
  const [city, setCity] = useState(props?.filter?.city && props?.filter?.city);
  const [isp, setIsp] = useState(props?.filter?.isp && props?.filter?.isp);
  const [leases, setLeases] = useState(props?.filter?.leases && props?.filter?.leases);
  const [cleanliness, setCleanliness] = useState(6);
  const [connection, setConnection] = useState(props?.filter?.conn && props?.filter?.conn);
  const [searchRadius, setSearchRadius] = useState(0);
  const [age, setAge] = useState(props?.filter?.age && props?.filter?.age);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [centralZip, setCentralZip] = useState(props?.filter?.geoCentralZip || '');

  const onApply = () => {
    props.dispatch(actSetFilter(getAccessTokenSilently, {
      geoCentralZip: centralZip ? centralZip.toUpperCase() : '',
      geoSearchRadius: searchRadius,
      region: region,
      city: city,
      isp: isp,
      leases: leases,
      exstars: cleanliness,
      conn: connection,
      age: age
    }
    ));
  };

  return (
    <div>
      {
        props?.segments ? <div className='shop-filter-page'>
          <div className='region'>Region</div>
          <SegmentFilterField
            disabled={!props.segments?.regions}
            options={props.segments?.regions}
            value={region}
            label='region'
            filter={(v) => (setRegion(v))}
            defaultValue={props?.filter?.region}
          />
          <div className='city'>City</div>
          <SegmentFilterField
            options={props?.segments?.cities}
            value={city}
            label='city'
            defaultValue={props?.filter?.city && props?.filter?.city}
            filter={(v) => (setCity(v))}
          />
          <div className='isp'>ISP</div>
          <SegmentFilterField
            options={props?.segments?.isps}
            value={isp}
            label='isp'
            defaultValue={props?.filter?.isp && props?.filter?.isp}
            filter={(v) => (setIsp(v))}
          />
          <div className='type'>Type</div>
          <div style={{ background: 'white', padding: '16px', borderRadius: '8px', width: '90%', margin: 'auto' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={props?.filter?.leases ? props?.filter?.leases : 'any'}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="any"
                  control={<Radio  defaultChecked={props?.filter?.leases && props?.filter?.leases === 'any' ? true : false}/>}
                  label="Any"
                  onClick={() => setLeases('any')}
                // onChange={() => dispatch(actSetFilter(getAccessTokenSilently, { leases: 'any' }))}
                />
                <FormControlLabel
                  value="none"
                  control={<Radio defaultChecked={props?.filter?.leases && props?.filter?.leases === 'none' ? true : false}/>}
                  label="Private"
                  onClick={() => setLeases('none')}
                // onChange={() => dispatch(actSetFilter(getAccessTokenSilently, { leases: 'none' }))}
                />
                <FormControlLabel
                  value="some"
                  control={<Radio defaultChecked={props?.filter?.leases === 'some' ? true : false}/>}
                  label="Shared"
                  onClick={() => setLeases('some')}
                // onChange={() => dispatch(actSetFilter(getAccessTokenSilently, { type: 'some' }))}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='cleanliness'>Cleanliness</div>
          <Box>
            <Slider
              aria-label="Temperature"
              defaultValue={props?.filter?.exstars ? props?.filter?.exstars : 0}
              getAriaValueText={setCleanliness}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={10}
            // onChange={() => dispatch(actSetFilter(getAccessTokenSilently, { exstars: cleanliness }))}
            />
          </Box>
          <div className='connection'>Connection</div>
          <div style={{ background: 'white', padding: '16px', borderRadius: '8px', width: '90%', margin: 'auto' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={props?.filter?.conn ? props?.filter?.conn : 'any'}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="any"
                  control={<Radio defaultChecked={props?.filter?.conn === 'any' ? true : false}/>}
                  label="Any"
                  onClick={() => setConnection('any')}
                // onChange={() => dispatch(actSetFilter(getAccessTokenSilently, { conn: connection }))}
                />
                <FormControlLabel
                  value="wifi"
                  control={<Radio defaultChecked={props?.filter?.conn === 'wifi' ? true : false}/>}
                  label="WiFi"
                  onClick={() => setConnection('wifi')}
                // onChange={() => dispatch(actSetFilter(getAccessTokenSilently, { conn: connection }))}
                />
                <FormControlLabel
                  value="cell"
                  control={<Radio defaultChecked={props?.filter?.conn === 'cell' ? true : false}/>}
                  label="Cell"
                  onClick={() => setConnection('cell')}
                // onChange={() => dispatch(actSetFilter(getAccessTokenSilently, { conn: connection }))}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='age'>Age</div>
          <div style={{ background: 'white', padding: '16px', borderRadius: '8px', width: '90%', margin: 'auto' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={props?.filter?.age ? props?.filter?.age : "any"}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="any"
                  control={<Radio defaultChecked={props?.filter?.age === 'any' ? true : false}/>}
                  label="Any"
                  onClick={() => setAge('any')}
                // onChange={() => dispatch(actSetFilter(getAccessTokenSilently, { age: age }))}
                />
                <FormControlLabel
                  value="new"
                  control={<Radio defaultChecked={props?.filter?.age === 'new' ? true : false}/>}
                  label="New"
                  onClick={() => setAge('new')}
                // onChange={() => dispatch(actSetFilter(getAccessTokenSilently, { age: age }))}
                />
                <FormControlLabel
                  value="old"
                  control={<Radio defaultChecked={props?.filter?.age === 'old' ? true : false}/>}
                  label="Old"
                  onClick={() => setAge('old')}
                // onChange={() => dispatch(actSetFilter(getAccessTokenSilently, { age: age }))}
                />
              </RadioGroup>
            </FormControl>
          </div>
          {
            showAdvancedFilters ?
              <div onClick={() => setShowAdvancedFilters(false)} className='see-more'>Hide advanced options</div> :
              <div onClick={() => setShowAdvancedFilters(true)} className='see-more'>Show advanced options</div>
          }
          {
            showAdvancedFilters &&
            <div className='central-zip-container'>
              <TextField
                id="outlined-basic"
                label='Center ZIP/Postal'
                variant="outlined"
                  value={centralZip}
                  defaultValue={props?.filter?.geoCentralZip ? props?.filter?.geoCentralZip : ""}
                onChange={changeCentralZip}
              />
              <div className='search-radius'>Search radius (mi)</div>
              <div className='search-radius-container'>
                <Slider
                  aria-label="Custom marks"
                  defaultValue={props?.filter?.geoSearchRadius ? props?.filter?.geoSearchRadius : 0}
                  getAriaValueText={setSearchRadius}
                  step={10}
                  valueLabelDisplay="auto"
                  marks={marks}
                />
              </div>
            </div>
          }
          <div className='apply-filter-cont'>
            <div className='apply-filter' onClick={onApply}>
              <NavLink to='/shop' >Apply Filter</NavLink>
            </div>
          </div>
        </div> : <div style={{ width: '100%', textAlign: 'center' }}>
          <ClipLoader
            color='#1589EE'
            size={30}
            className='cliploader'
          />
        </div>
      }
    </div>

  );
};

const mapStateToProps = (state) => ({
  country: state.proxies.filter.country,
  segments: state.proxies.segments,
  filter: state.proxies.filter,
  agents: state.proxies
});

export default connect(mapStateToProps)(Filter);
