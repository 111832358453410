import { actSetGlobalBusy } from "../actions"
import { PORT_CREATED, PORT_CREATE_ERROR, PORT_LOADED, PORT_LOAD_ERROR,
  COUNTRY_LIST_LOADED , COUNTRY_LIST_LOAD_ERROR, PRICE_LOADED, PRICE_LOADED_ERROR,
  PORT_WHITELIST_UPDATED, PORT_WHITELIST_ERROR, PORT_REFUNDED, PORT_REFUNDED_ERROR,
  PORT_REACTIVATED, PORT_REACTIVATED_ERROR, PORT_REGION_LOADED, PORT_REGION_LOAD_ERROR,
  PORT_CITY_LOADED, PORT_CITY_LOAD_ERROR, PORT_WHITELIST_TO_LOGIN_UPDATED, PORT_WHITELIST_TO_LOGIN_ERROR} from "./api-client"

export const initialPortCreateState = () => ({
    modelOpen: false,
    modalConfirmOpen: false,
    list:{
      package_active:[],
      package_history:[],
    },
    regions: [],
    regionsLoadError: null,
    cities: [],
    citiesLoadError: null,
    selectedPort: {},
    createPortData: null,
    reloadList: false,
    loadError: false,
    createError: false,
    createResult: null,
    newPortPrice: 0,
    newPortPriceError: null,
    updatePortWhiteListError:false,
    updatePortWhiteListResult:null,
    reactivatePortResult: false,
    reactivatePortError: false,
    refundPortResult: false,
    refundPortError: false,
    isReactivate:false,
    isRefund:false,
    updatePortWhiteToLoginAuthListResult: null,
    updatePortWhiteListToLoginAuthError: false,
})

export const actRequestCreatePort = (getToken, portData) => {
    return async (dispatch, getState) => {
      try {
          dispatch(actSetGlobalBusy(true))
          const result = await getState().portApiClient.withToken(getToken).createPort(portData)

          switch (result[0]) {
              case PORT_CREATED:
                return dispatch(actSetCreatePortResult(result[1]))
              case PORT_CREATE_ERROR:
                return dispatch(actSetCreatePortError(result[1]))
              default:
                return dispatch(actSetCreatePortError(result[1]))
          }

      } finally {
          dispatch(actSetGlobalBusy(false))
      }
    }
}

export const actLoadPortsList = (userId, getToken) => {
    return async (dispatch, getState) => {
      try {
        if (userId == null){
          userId = getState().account.userID
        }
        // dispatch(actSetGlobalBusy(true))

        const oldSelected = getState().ports.selectedPort

        const result = await getState().portApiClient.withToken(getToken).loadPortsList(userId)

        switch (result[0]) {
            case PORT_LOADED:
              dispatch(actSetLoadPortsListResult(result[1]))
              break
            case PORT_LOAD_ERROR:
              dispatch(actSetLoadPortsListError(result[1]))
              break
            default:
              dispatch(actSetLoadPortsListResult(result[1]))
        }
        // console.log('oldSelected ' + oldSelected)
        if (oldSelected === undefined || !oldSelected.hasOwnProperty('id') || oldSelected === null) {
          if (result[1]!==undefined && result[1]!==null){
            if(result[1].hasOwnProperty('package_active')) {
                dispatch(actSetActivePort(result[1]['package_active'][0]))
            }
          }
        } else {
          if(result[1].hasOwnProperty('package_active')) {
            result[1]['package_active'].forEach((element,index) => {
              if(element['id'] == oldSelected['id']){
                // console.log(element)
                dispatch(actSetActivePort(result[1]['package_active'][index]))
              }
            });
          }
        }

      } finally {
          // dispatch(actSetGlobalBusy(false))
      }
    }
}

export const actLoadCountriesList = (getToken) => {
    return async (dispatch, getState) => {
      try {
        // dispatch(actSetGlobalBusy(true))
        const result = await getState().portApiClient.withToken(getToken).loadCountryList()

        switch (result[0]) {
            case COUNTRY_LIST_LOADED:
              return dispatch(actSetLoadCountriesListResult(result[1]))
            case COUNTRY_LIST_LOAD_ERROR:
              return dispatch(actSetLoadCountriesListResult(result[1]))
            default:
              return dispatch(actSetLoadCountriesListResult(result[1]))
        }
      } finally {
          // dispatch(actSetGlobalBusy(false))
      }
    }
}

export const actGetPortPrice = (data, getToken) => {
    return async (dispatch, getState) => {
      try {
        // dispatch(actSetGlobalBusy(true))
        const result = await getState().portApiClient.withToken(getToken).getPortPrice(data)

        switch (result[0]) {
            case PRICE_LOADED:
              return dispatch(actSetGetPriceResult(result[1]))
            case PRICE_LOADED_ERROR:
              return dispatch(actSetGetPriceError(result[1]))
            default:
              return dispatch(actSetGetPriceResult(result[1]))
        }
      } finally {
          // dispatch(actSetGlobalBusy(false))
      }
    }
}

export const actUpdatePortWhitelist = (data, getToken) => {
    return async (dispatch, getState) => {
      try {
        // dispatch(actSetGlobalBusy(true))
        const result = await getState().portApiClient.withToken(getToken).updatePortToWhitelistAuth(data)
        // console.log(result)
        switch (result[0]) {
            case PORT_WHITELIST_UPDATED:
              return dispatch(actSetUpdatePortWhitelistResult(result[1]))
            case PORT_WHITELIST_ERROR:
              return dispatch(actSetUpdatePortWhitelistError(result[1]))
            default:
              return dispatch(actSetUpdatePortWhitelistResult(result[1]))
        }
      } finally {
          // dispatch(actSetGlobalBusy(false))
      }
    }
}

export const actUpdatePortWhitelistToLoginAuth = (packageId, getToken) => {
  return async (dispatch, getState) => {
    try {
      // dispatch(actSetGlobalBusy(true))
      const result = await getState().portApiClient.withToken(getToken).updatePortLoginAuth(packageId)
      // console.log(result)
      switch (result[0]) {
          case PORT_WHITELIST_TO_LOGIN_UPDATED:
            return dispatch(actSetUpdatePortWhitelistToLoginAuthResult(result[1]))
          case PORT_WHITELIST_TO_LOGIN_ERROR:
            return dispatch(actSetUpdatePortWhitelistToLoginAuthError(result[1]))
          default:
            return dispatch(actSetUpdatePortWhitelistToLoginAuthResult(result[1]))
      }
    } finally {
        // dispatch(actSetGlobalBusy(false))
    }
  }
}

export const actReActivatePort = (id, getToken) => {
    return async (dispatch, getState) => {
      try {
        dispatch(actSetGlobalBusy(true))
        const result = await getState().portApiClient.withToken(getToken).getReActivatePort(id)
        // console.log(result)
        switch (result[0]) {
            case PORT_REACTIVATED:
              return dispatch(actSetReActivateResult(result[1]))
            case PORT_REACTIVATED_ERROR:
              return dispatch(actSetReActivatePortError(result[1]))
            default:
              return dispatch(actSetReActivateResult(result[1]))
        }
      } finally {
          dispatch(actSetGlobalBusy(false))
      }
    }
}

export const actRefundPort = (id, getToken) => {
    return async (dispatch, getState) => {
      try {
        dispatch(actSetGlobalBusy(true))
        const result = await getState().portApiClient.withToken(getToken).getRefundPort(id)
        // console.log(result)
        switch (result[0]) {
            case PORT_REFUNDED:
              return dispatch(actSetRefundResult(result[1]))
            case PORT_REFUNDED_ERROR:
              return dispatch(actSetRefundError(result[1]))
            default:
              return dispatch(actSetRefundResult(result[1]))
        }
      } finally {
          dispatch(actSetGlobalBusy(false))
      }
    }
}

export const actLoadRegionList = (country, getToken) => {
    return async (dispatch, getState) => {
      try {
        // dispatch(actSetGlobalBusy(true))
        const result = await getState().portApiClient.withToken(getToken).loadRegionList(country)

        switch (result[0]) {
            case PORT_REGION_LOADED:
              return dispatch(actSetLoadRegionListResult(result[1]))
            case PORT_REGION_LOAD_ERROR:
              return dispatch(actSetLoadRegionListError(result[1]))
            default:
              return dispatch(actSetLoadRegionListResult(result[1]))
        }
      } finally {
          // dispatch(actSetGlobalBusy(false))
      }
    }
}

export const actLoadCitiesList = (country, region, getToken) => {
    return async (dispatch, getState) => {
      try {
        // dispatch(actSetGlobalBusy(true))
        const result = await getState().portApiClient.withToken(getToken).loadCitiesList(country, region)

        switch (result[0]) {
            case PORT_CITY_LOADED:
              return dispatch(actSetLoadCitiesListResult(result[1]))
            case PORT_CITY_LOAD_ERROR:
              return dispatch(actSetLoadCitiesListError(result[1]))
            default:
              return dispatch(actSetLoadCitiesListResult(result[1]))
        }
      } finally {
          // dispatch(actSetGlobalBusy(false))
      }
    }
}

export const actSetLoadCitiesListResult = (list) => ({
    type: 'SET_LOAD_CITIES_LIST_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          cities: list,
          citiesLoadError: null,
        },
        globalError: null
    })
})

export const actSetLoadCitiesListError = (err) => ({
    type: 'SET_LOAD_CITIES_LIST_ERROR',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          cities: [],
          citiesLoadError: err,
        },
        globalError: null
    })
})

export const actSetLoadRegionListResult = (list) => ({
    type: 'SET_LOAD_REGION_LIST_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          regions: list,
          cities: [],
          regionsLoadError: null,
        },
        globalError: null
    })
})

export const actSetLoadRegionListError = (err) => ({
    type: 'SET_LOAD_REGION_LIST_ERROR',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          regionsLoadError: err,
          regions: [],
          cities: [],
        },
        globalError: null
    })
})

export const actResetReActivateResult = () => ({
    type: 'RESET_REACTIVATE_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          reactivatePortResult: false,
          reactivatePortError: false,
          isReactivate:false,
        }
    })
})

export const actResetRefundResult = () => ({
    type: 'RESET_REFUND_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          refundPortResult: false,
          refundPortError: false,
          isRefund: false,
        }
    })
})

export const actSetRefundResult = (result) => ({
    type: 'SET_REFUND_PORT_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          refundPortResult: result,
          refundPortError: false,
          reloadList: true,
          isRefund: false,
        },
        globalError: null
    })
})

export const actSetRefundError = (result) => ({
    type: 'SET_REFUND_PORT_ERROR',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          refundPortResult: false,
          refundPortError: true,
          isRefund: false,
        },
        globalError: null
    })
})

export const actSetReActivateResult = (result) => ({
    type: 'SET_REACTIVATE_PORT_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          reactivatePortResult: result,
          reactivatePortError: false,
          reloadList: true,
          isReactivate:false,
        },
        globalError: null
    })
})

export const actSetReActivatePortError = (result) => ({
    type: 'SET_REACTIVATE_PORT_ERROR',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          reactivatePortResult: false,
          reactivatePortError: true,
          isReactivate:false,
        },
        globalError: null
    })
})

export const actSetUpdatePortWhitelistResult = (result) => ({
    type: 'SET_UPDATE_PORT_WHITELIST_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          updatePortWhiteListResult: result,
          updatePortWhiteListError: false,
        },
        globalError: null
    })
})

export const actSetUpdatePortWhitelistToLoginAuthResult = (result) => ({
  type: 'SET_UPDATE_PORT_WHITELIST_TO_LOGIN_RESULT',
  reduce: (state) => ({
      ...state,
      ports: {
        ...state.ports,
        updatePortWhiteToLoginAuthListResult: result,
        updatePortWhiteListToLoginAuthError: false,
      },
      globalError: null
  })
})

export const actSetOpenBuyConfirmDialog = (portData, isReactivate=false) => ({
    type: 'SET_OPEN_PORT_CONFIRM_DIALOG',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
        modalConfirmOpen: true,
        createPortData: portData,
        isReactivate: isReactivate,
        },
        globalError: null
    })
})

export const actSetOpenRefundConfirmDialog = (id) => ({
    type: 'SET_OPEN_PORT_REFUND_CONFIRM_DIALOG',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
        modalConfirmOpen: true,
        isRefund: id,
        },
        globalError: null
    })
})

export const actSetCloseBuyConfirmDialog = () => ({
    type: 'SET_CLOSE_PORT_CONFIRM_DIALOG',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
        modalConfirmOpen: false,
        createPortData: null,
        isRefund: false,
        },
        globalError: null
    })
})

export const actResetUpdatePortWhitelistResult = () => ({
  type: 'SET_UPDATE_PORT_WHITELIST_RESET',
  reduce: (state) => ({
      ...state,
      ports: {
        ...state.ports,
        updatePortWhiteListError: false
      },
      globalError: null
  })
})

export const actResetUpdatePortWhitelisToLoginAuthResult = () => ({
  type: 'SET_UPDATE_PORT_WHITELIST_RESET',
  reduce: (state) => ({
      ...state,
      ports: {
        ...state.ports,
        updatePortWhiteListError: false,
        updatePortWhiteToLoginAuthListResult: null,
      },
      globalError: null
  })
})

export const actSetUpdatePortWhitelistError = (error) => ({
    type: 'SET_UPDATE_PORT_WHITELIST_ERROR',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          updatePortWhiteListError: true
        },
        globalError: null
    })
})

export const actSetUpdatePortWhitelistToLoginAuthError = (error) => ({
  type: 'SET_UPDATE_PORT_WHITELIST_TO_LOGIN_AUTH_ERROR',
  reduce: (state) => ({
      ...state,
      ports: {
        ...state.ports,
        updatePortWhiteListToLoginAuthError: true
      },
      globalError: null
  })
})

export const actSetActivePort = (portData) => ({
    type: 'SET_ACTIVE_PORT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          selectedPort: portData
        },
        globalError: null
    })
})

export const actSetGetPriceResult = (result) => ({
    type: 'SET_LOAD_PORT_PRICE_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          newPortPrice: result.result
        },
        globalError: null
    })
})

export const actSetGetPriceError = (error) => ({
    type: 'SET_LOAD_PORT_PRICE_ERROR',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          newPortPrice: 0,
          newPortPriceError: error
        },
        globalError: null
    })
})

export const actSetLoadCountriesListResult = (list) => ({
    type: 'SET_LOAD_COUNTRIES_LIST_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          data: list
        },
        globalError: null
    })
})

export const actSetLoadPortsListResult = (list) => ({
    type: 'SET_LOAD_PORT_LIST_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          list: list,
          reloadList: false,
          reactivatePortResult: false,
          reactivatePortError: false,
          refundPortResult: false,
          refundPortError: false,
          isRefund:false,
        },
        globalError: null
    })
})

export const actSetLoadPortsListError = (error) => ({
    type: 'SET_LOAD_PORT_LIST_ERROR',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          loadError: error,
          reactivatePortResult: false,
          reactivatePortError: false,
          refundPortResult: false,
          refundPortError: false,
          isRefund: false,
        },
        globalError: null
    })
})

export const actSetResetResult = () => ({
    type: 'SET_RESET_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          createResult: null,
          createError: null,
        },
        globalError: null
    })
})

export const actSetCreatePortResult = (result) => ({
    type: 'SET_CREATE_PORT_RESULT',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          createResult: result.result,
          reloadList: true,
          modelOpen: false,
          createPortData: null,
          isRefund:false,
        },
        globalError: null
    })
})

export const actResetLoadList = () => ({
    type: 'SET_RESET_LOADLIST',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          reloadList: false,
        },
        globalError: null
    })
})

export const actSetCreatePortError = (error) => ({
    type: 'SET_CREATE_PORT_ERROR',
    reduce: (state) => ({
        ...state,
        ports: {
          ...state.ports,
          createError: true,
          createPortData: null,
        },
        globalError: null
    })
})

export const actOpenCreatePortDialog = () => ({
    type: 'OPEN_CREATE_PORT_DIALOG',
    reduce: (state) => ({
        ...state,
        ports: {
            ...state.ports,
            modelOpen: true,
        },
    })
})

export const actCloseCreatePortDialog = () => ({
    type: 'CLOSE_CREATE_PORT_DIALOG',
    reduce: (state) => ({
        ...state,
        ports: {
            ...state.ports,
            modelOpen: false,
        },
    })
})
