import React from 'react'

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { useDialogStyles } from '../dialog'
import { connect } from 'react-redux'

const BuyAgainErrorDialog = (props) => {
    const dialogClasses = useDialogStyles()

    return (
        <Dialog 
            classes={{paper: dialogClasses.dialog}} 
            open={props.open} 
            onClose={props.onClose} 
        >
            <DialogTitle>Proxy Unavailable</DialogTitle>
            <DialogContent className={dialogClasses.content}>
                {props.message}
            </DialogContent>
            <DialogActions classes={{root: dialogClasses.actions}}>
                <Button id='ok_button' variant='text' color='primary' onClick={props.onClose}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps)(BuyAgainErrorDialog)