
export const getProxiesHistory = (getToken) => {
  return async (dispatch, getState) => {
    // dispatch(getProxiesHistory())
    const data = await getState().apiClient.withToken(getToken).getProxiesHistory1()
    dispatch(getProxyHistories(data))
  }
}

export const getProxyHistories = (data) => ({
  type: 'GET_PROXY_HISTORIES',
  reduce: (state) => ({
    ...state,
    histories: data.items,
})
})