import axios from 'axios'
import { auth0Cfg } from "../../config/auth0"
import { actSetGlobalBusy } from "../actions"

export const actResetPassword = (user) => {
    return async (dispatch, getState) => {
        dispatch(actSetGlobalBusy(true))
        try {
            await resetPassword(user)
            dispatch(actSetResetPasswordSuccess())
        } catch (err) {
            console.log(err)
            dispatch(actSetResetPasswordFailed())
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}

export const RESET_PASSWORD_STATE_NONE = "NONE"
export const RESET_PASSWORD_STATE_SUCCESS = "SUCCESS"
export const RESET_PASSWORD_STATE_FAILED = "FAILED"

const actSetResetPasswordSuccess = () => ({
    type: 'RESET_PASSWORD_SUCCESS',
    reduce: (state) => ({
        ...state,
        resetPasswordState: RESET_PASSWORD_STATE_SUCCESS,
    })
})

const actSetResetPasswordFailed = () => ({
    type: 'RESET_PASSWORD_SUCCESS',
    reduce: (state) => ({
        ...state,
        resetPasswordState: RESET_PASSWORD_STATE_FAILED,
    })
})

export const actResetResetPasswordState = () => ({
    type: 'RESET_RESET_PASSWORD_STATE',
    reduce: (state) => ({
        ...state,
        resetPasswordState: RESET_PASSWORD_STATE_NONE,
    })
})

const resetPassword = async (user) => {
    return await axios({
        method: 'POST',
        url: auth0Cfg.domain + '/dbconnections/change_code',
        data: {'email': user.email}//, 'client_id': auth0Cfg.clientId, 'connection': 'Username-Password-Authentication'},
    })
}
