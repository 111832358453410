import React, { useCallback } from 'react';
import { useState } from 'react';
import './style.css';

const ChoseCurrency = (props) => {

  const [currencyTipe, setCurrencyTipe] = useState('ltc')

  const { onChange } = props

  const changecurrencyTipe = useCallback((item) => {
    setCurrencyTipe(item)
    onChange(item)
  }, [onChange])
  
  return (
    <div className='chose-currency-container'>
      <div className={currencyTipe === 'ltc' ? 'active-btn ': 'passive-btn'} onClick={() => changecurrencyTipe('ltc')}>LTC</div>
      <div className={currencyTipe === 'btc' ? 'active-btn ' : 'passive-btn'} onClick={() => changecurrencyTipe('btc')}>BTC</div>
      <div className={currencyTipe === 'trc20usdt' ? 'active-btn ': 'passive-btn'} onClick={() => changecurrencyTipe('trc20usdt')}>USDT TRC20</div>
    </div>
  );
};

export default ChoseCurrency;