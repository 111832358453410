import { countryNames } from "../../data/countryNames"
import { connTypeLabels } from "../ProxyTable"

export const infoChanged = (lease) => {
    return (lease.currentInfo !== undefined && (lease.info.ip !== lease.currentInfo.ip)) || (lease.currentInfo !== undefined && (lease.info.loc.cc !== lease.currentInfo.loc.cc)) ||
        (lease.currentInfo !== undefined && (lease.info.loc.reg !== lease.currentInfo.loc.reg)) || (lease.currentInfo !== undefined && (lease.info.loc.city !== lease.currentInfo.loc.city))
}

export const changedFields = (lease) => {
    const changed = []
    const old = lease.info
    const curr = lease.currentInfo

    if (old.ip !== curr.ip) {
        changed.push(["Device IP", curr.ip])
    }
    if (old.host !== curr.host) {
        changed.push(["Hostname", curr.host ? curr.host : "(none)"])
    }
    if (old.conn !== curr.conn) {
        changed.push(["Connection", connTypeLabels[curr.conn]])
    }
    if (old?.loc?.cc !== curr.loc.cc) {
        changed.push(["Country", countryNames[curr.loc.cc]])
    }
    if (old.loc.reg !== curr.loc.reg) {
        changed.push(["Region", curr.loc.reg])
    }
    if (old.loc.city !== curr.loc.city) {
        changed.push(["City", curr.loc.city])
    }

    return changed
}