import { actSetGlobalBusy } from "../actions"
import { AUTHPORT_CREATED, AUTHPORT_ERROR } from "./api-client"

export const actRequestAuthPort = (getToken, leaseId, whitelist) => {
    return async (dispatch, getState) => {
        try {
            dispatch(actSetGlobalBusy(true))
            const result = await getState().apiClient.withToken(getToken).createAuthPort(leaseId,  whitelist)
            
            switch (result[0]) {
                case AUTHPORT_CREATED:
                    break
                default:
                    dispatch(actSetAuthPortResult(result[0]))
                    return
            }

            const info = result[1]
            
            dispatch(({
                type: 'SET_AUTHPORT_INFO',
                reduce: (state) => {
                	const activeLeases = []
                    let newSelected
                    for (const proxy of state.activeLeases.activeLeases) {
                        const p = {...proxy}
                        if (p.id === leaseId) {
                            p.authPort = info
                        }
                        if (p.id === state.activeLeases.selected?.id) {
                            newSelected = p
                        }
                        activeLeases.push(p)
                    }

                    return {...state, activeLeases: {...state.activeLeases, activeLeases, selected: newSelected}}
                }
            }))
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}
export const actSetAuthPortResult = (result) => ({
    type: 'SET_AUTHPORT_RESULT',
    reduce: (state) => ({
        ...state,
        authPort: result,
        globalError: null
    })
})