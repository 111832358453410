import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useAuth0 } from "../../redux/auth"
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, makeStyles } from '@material-ui/core'
import { useDialogStyles } from '../leaseflow/LeaseFlow'
import { NavLink } from 'react-router-dom'
import '../leaseflow/leaseFlow.css'
import { actCancelStaticLease, actCreateStaticLease } from '../../redux/backend/lease'

const useStyles = makeStyles((theme) => ({
    ipWarning: {
        fontWeight: 700,
        color: '#b47b05',
    }
}));

const StaticLeaseConfirm = (props) => {
    const classes = useDialogStyles()
    const extraClasses = useStyles()
    const { getAccessTokenSilently } = useAuth0()

   

    const handleBuyClick = () => {
        props.dispatch(actCreateStaticLease(getAccessTokenSilently))
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.cancel}
            classes={{ paper: classes.dialog }}
            disableBackdropClick={true}
            className='confirm-purchase-dialog'
        >
            <DialogTitle>Static Proxy Confirm Purchase</DialogTitle>
            <DialogContent style={{ maxWidth: 416 }} className={classes.content}>
                <p>
                    The Static Proxy will be leased to you for 24 hours and only in the Private mode.
                </p>
                <p>
                    <span className={extraClasses.ipWarning}>
                        The Static Proxy may have some blacklists or high risk score.
                    </span>
                </p>
                <p>
                    If the proxy doesn't work from the beginning of your lease, you have 1 hour to refund it. You will be eligible to get a full refund to your balance
                    via the support telegram bot by providing the lease id.
                </p>
                <p>
                    <span className={extraClasses.ipWarning}>
                        A refund is no longer possible after 1 hour or if proxy was used.
                    </span>
                </p>
            </DialogContent>
            <DialogActions classes={{ root: classes.actions }}>
                <Button id='cancel_button' variant='text' color='primary' onClick={props.cancel}>Cancel</Button>
                {window.innerWidth <= 480
                    ? <NavLink to="/shop" className='buy-proxy-confirm-btn' onClick={handleBuyClick}>
                        Buy Proxy
                    </NavLink>
                    : <Button
                        id='confirm_button'
                        variant='contained'
                        disableElevation
                        color='primary'
                        onClick={handleBuyClick}
                    >Buy Proxy</Button>}


            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    staticLeaseFlow: state.staticLeaseFlow,
});

export default connect(mapStateToProps)(StaticLeaseConfirm);
