import React from 'react'
import { makeStyles, Box } from '@material-ui/core'
import humanFormat from 'human-format'

const useStyles = makeStyles((theme) => ({
    stats: {
        display: 'flex',
        flexWrap: 'wrap',
        color: '#3A404D',
    },
    statBlock: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        minWidth: 120,
        minHeight: 48,
    },
    statHeader: {
        fontSize: 13,
    },
    statValue: {
        fontSize: 28,
        fontWeight: 700,
    },
}))

const PortStats = (props) => {
    const classes = useStyles()
// console.log('STAT')
// console.log(props.stats)

    let stats = props.stats
    if (!stats || stats === undefined) {
        stats = {
            requests: 0,
            errors: 0,
            upload: 0,
            download: 0,
        }
    }

    let fails = stats.requests === 0 ? 0 : (stats.errors/stats.requests)*100

    // console.log(stats)

    const StatBlock = (props) => {
        return (
            <Box className={classes.statBlock}>
                <Box className={classes.statHeader}>{props.header}</Box>
                <Box className={classes.statValue}>{props.value}</Box>
            </Box>
        )
    }

    return (
        <Box className={classes.stats}>
            <StatBlock header="Total Requests" value={humanFormat(stats.requests, {decimals: 0})} />
            <StatBlock header="Failed" value={Math.floor(fails) + "%"} />
            <StatBlock header="You→Port" value={humanFormat.bytes(stats.upload, {decimals: 0})} />
            <StatBlock header="Port→You" value={humanFormat.bytes(stats.download, {decimals: 0})} />
        </Box>
    )
}

export default PortStats
