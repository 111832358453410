import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { formatMoney } from '../../util/money';
import MoreCountries from '../MoreCountries';
import ShopMobileItem from './shopMobileItem';
import filterIcom from '../../images/filter-icon.png';
import { NavLink } from 'react-router-dom';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import { useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import './style.css';
import { actChangeProxyPage, actSetSelectedProxy } from '../../redux/backend/proxies';
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../../redux/auth"
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import WifiIcon from '@material-ui/icons/Wifi';

const ShopMobile = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveItem('Shop'));
  }, [dispatch]);

  const { getAccessTokenSilently } = useAuth0();

  const handleRowClick = (proxy) => {
    props.dispatch(actSetSelectedProxy(proxy));
  };

  const [myPage, setMyPage] = useState(0);

  const handlePageChange = () => {
    props.dispatch(actChangeProxyPage(getAccessTokenSilently, myPage));
  };

  return (
    <div id='shop-page'>
      <div><MoreCountries /></div>
      {
        props?.proxies ? <div>
          {
            props?.proxies?.map((item, index) => {
              const price = item.priceShrC ? formatMoney(item?.priceShrC) : "";

              let priceExc = item.priceExcC ? formatMoney(item?.priceExcC) : '';

              let leaseTypeStatus = "Private";


              if (item.stars <= 5) {
                if (item.leases != null && item.leases.count > 0) {
                  leaseTypeStatus = item.leases.count + " of 4"; // TODO config from backend
                }
              } else {
                if (item.leases != null && item.leases.count > 0) {
                  leaseTypeStatus = item.leases.count + " of 4"; // TODO config from backend
                }
              }
              return <ShopMobileItem
                key={index}
                ip={item?.ip?.includes('_') ? item?.ip?.replaceAll('_', '*') : item?.ip}
                city={item?.loc?.city ? item?.loc?.city : ""}
                region={item?.loc?.reg ? item?.loc?.reg : ""}
                zip={item?.loc?.zip ? item?.loc?.zip : ''}
                isp={item?.loc?.isp}
                priceShrc={leaseTypeStatus === "Private" ? formatMoney(item?.priceExcC) : price}
                score={item?.stars} loader
                id={item?.id}
                priceExcC={leaseTypeStatus === "Private" ? price : leaseTypeStatus}
                mobileConIcon={item?.conn === 'cell' ?
                  <PhoneAndroidIcon style={{ width: '20px' }} /> : <WifiIcon />
                }
                onClick={() => handleRowClick(item)}
              />;
            })
          }
        </div> : <div style={{ width: '100%', textAlign: 'center' }}>
          <ClipLoader
            color='#1589EE'
            size={30}
            className='cliploader shop'
          />
        </div>
      }
      {props?.proxies && props?.proxies?.length !== props.totalProxies && !props.loading && props?.agents.cursor !== 0 &&
        <div
          className='show-more-btn'
          onClick={() => {
            handlePageChange();
            setMyPage(myPage + 1);
          }}>Show more</div>
      }
      {
        props?.proxies && props?.proxies?.length>0 && props.loading && 
        <div style={{ width: '100%', textAlign: 'center' }}>
          <ClipLoader
            color='#1589EE'
            size={30}
            className='cliploader shop'
          />
        </div>
      }
      <div className='filter-shops-container'>
        <NavLink
          to='/filter' className='filter' onClick={() => window.scrollTo(0, 0)}
        >
          <img src={filterIcom} alt='' />
          Filter
        </NavLink>
      </div>
    </div >
  );
};

const mapStateToProps = (state) => ({
  loadedAll: state.proxies.loadedAll,
  totalProxies: state.proxies.total ? state.proxies.total : 0,
  proxies: state.proxies.proxies,
  loading: state.proxies.loading,
  pagination: state.proxies.pagination,
  proxyPage: state.proxies.pagination.page,
  proxyRowsPerPage: state.proxies.pagination.rowsPerPage,
  agents: state.proxies
});

export default connect(mapStateToProps)(ShopMobile);