// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../../redux/auth"
import { makeStyles, TablePagination } from '@material-ui/core'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { actChangeLeaseHistoryPage, actChangeLeaseHistoryRowsPerPage } from '../../redux/backend/lease-history'
import ProxyTablePaginationActions from '../ProxyTablePaginationActions'


const useStyles = makeStyles((theme) => ({
    paginationRoot: {
        borderWidth: 0,
    },
    paginationCaption: {
        fontWeight: 500,
    },
    paginationSpacer: {
        display: 'none',
    },
}))

const LeaseHistoryPaginator = (props) => {
    const classes = useStyles()

    const dispatch = props.dispatch
    const { getAccessTokenSilently } = useAuth0()

    const handlePageChange = (_, page) => {
        dispatch(actChangeLeaseHistoryPage(getAccessTokenSilently, page))
    }

    const handleRowsChange = (ev) => {
        dispatch(actChangeLeaseHistoryRowsPerPage(getAccessTokenSilently, ev.target.value))
    }

    return (
        <TablePagination
            component='div'
            classes={{ root: classes.paginationRoot, spacer: classes.paginationSpacer, caption: classes.paginationCaption }}
            rowsPerPageOptions={[10, 20]}
            rowsPerPage={props.rowsPerPage}
            count={props.total}
            page={props.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsChange}
            ActionsComponent={ProxyTablePaginationActions}
        />
    )
}

const mapStateToProps = (state) => ({
    ...state.leaseHistory
})

export default connect(mapStateToProps)(LeaseHistoryPaginator)
