import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, makeStyles, Typography } from '@material-ui/core'
import { useDialogStyles } from '../leaseflow/LeaseFlow'
import { actRequestCreatePort, actSetCloseBuyConfirmDialog,
        actCloseCreatePortDialog, actReActivatePort, actRefundPort } from '../../redux/backend/ports'
// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../../redux/auth"

const useStyles = makeStyles((theme) => ({
    ipWarning: {
        fontWeight: 700,
        color: '#b47b05',
    },
    amountTitle: {
        fontWeight: 400,
        fontSize: 15,
    }
}))

const PortBuyConfirmDialog = (props) => {
    const classes = useDialogStyles()
    const extraClasses = useStyles()
    const {getAccessTokenSilently} = useAuth0()

    const openConfirm = props.openConfirm

    const portData = useSelector((state) => state.ports.createPortData)
    const isReactivate = useSelector((state) => state.ports.isReactivate)
    const isRefund = useSelector((state) => state.ports.isRefund)

    const handleBuyClick = () => {
      if (!isReactivate){
        props.dispatch(actRequestCreatePort(getAccessTokenSilently, portData))
      } else {
        props.dispatch(actReActivatePort(portData.id, getAccessTokenSilently))
      }
      props.dispatch(actCloseCreatePortDialog())
      close()
    }

    const handleRefundClick = () => {
      props.dispatch(actRefundPort(isRefund, getAccessTokenSilently))
      props.dispatch(actCloseCreatePortDialog())
      close()
    }

    const close = () => {
      props.dispatch(actSetCloseBuyConfirmDialog())
    }

    return (
      <div>
      {isRefund == false ? (
        <Dialog
            open={openConfirm}
            classes={{paper: classes.dialog}}>
            <DialogTitle>Confirm Purchase</DialogTitle>
            <DialogContent style={{maxWidth: 416}} className={classes.content}>
                <p>
                  <span className={extraClasses.amountTitle}><strong>${portData?.price}</strong> will deducted from your balance</span>
                </p>

                        {portData?.traffic?.type=='limit' ? (
                            <span className={extraClasses.ipWarning}>
                              <Typography variant="body2">
                                <strong>Refund port</strong> with type <strong>"limit"</strong>
                                can be done in next case :
                                <li>port with traffic limit 1 Gb: if total traffic on port less or equal 10 Mb</li>
                                <li>port with traffic more 1 Gb: each new Gb plus more then 10 Mb will be rounded to hightest number and with per 1Gb price rate.
                                Example: 1.011 Gb will calculated as 2 Gb</li>
                              </Typography>
                            </span>
                        ):(
                          <span className={extraClasses.ipWarning}>
                            <Typography variant="body2">
                              <strong>Refund port</strong> with type <strong>"unlimit"</strong> can be done in next case :
                              <li>if total traffic on port less or equal 10 Mb</li>
                            </Typography>
                          </span>
                        )}
                        <p>
                          Each port type can be refunded without traffic before half time will be expired
                        </p>
            </DialogContent>
            <DialogActions classes={{root: classes.actions}}>
                <Button id='cancel_button' variant='text' color='primary' onClick={close}>Cancel</Button>
                <Button id='confirm_button' variant='contained' disableElevation color='primary' onClick={handleBuyClick}>Buy Port</Button>
            </DialogActions>
        </Dialog>
      ):(
        <Dialog
            open={openConfirm}
            classes={{paper: classes.dialog}}>
            <DialogTitle>Port Refund Confirmation</DialogTitle>
            <DialogContent style={{maxWidth: 416}} className={classes.content}>
                <p>
                  <span className={extraClasses.amountTitle}><strong>Port will canceled and your settings will be lost</strong></span>
                </p>
            </DialogContent>
            <DialogActions classes={{root: classes.actions}}>
                <Button id='cancel_button' variant='text' color='primary' onClick={close}>Cancel</Button>
                <Button id='confirm_button' variant='contained' disableElevation color='primary' onClick={handleRefundClick}>Refund</Button>
            </DialogActions>
        </Dialog>
      )}
      </div>
    )
}

const mapStateToProps = (state) => ({
  openConfirm: state.ports.modalConfirmOpen,
})

export default connect(mapStateToProps)(PortBuyConfirmDialog)
