// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../../redux/auth"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, SnackbarContent } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { actOpenAddFunds } from '../../redux/backend/add-funds'
import { actPerformRenew, actResetRenew, calculateRenewPrice } from '../../redux/backend/renew'
import { formatMoney } from '../../util/money'
import { useDialogStyles } from '../dialog'


const RenewFlow = (props) => {
    const classes = useDialogStyles()

    const handleReset = () => {
        props.dispatch(actResetRenew())
    }

    const {getAccessTokenSilently} = useAuth0()
    const handleRenew = () => {
        props.dispatch(actPerformRenew(getAccessTokenSilently))
    }

    const handleAddFunds = () => {
        handleReset()

        props.dispatch(actOpenAddFunds())
    }

    return (
        <>
            {props.flow.type === 'CONFIRM' && 
                <Dialog open={true} onClose={handleReset} classes={{paper: classes.dialog}}>
                    <DialogTitle>Confirm Renewal</DialogTitle>
                    <DialogContent className={classes.content}>
                        Do you want to extend the purchase of this proxy for another 24 hours? <br /><br />
                        Your balance will be charged <strong>{formatMoney(calculateRenewPrice(props.flow.lease))}</strong>.
                    </DialogContent>
                    <DialogActions classes={{root: classes.actions}}>
                        <Button onClick={handleReset} color='primary'>Cancel</Button>
                        <Button onClick={handleRenew} variant='contained' color='primary'>Renew Proxy</Button>
                    </DialogActions>
                </Dialog>
            }
            <Snackbar open={props.flow.type === 'SUCCESS'} autoHideDuration={5000} onClose={handleReset}>
                <SnackbarContent message="Renewed the proxy successfully" action={<Button size='small' color='secondary' onClick={handleReset}>Dismiss</Button>} />
            </Snackbar>
            <Snackbar open={props.flow.type === 'NOT_ENOUGH_FUNDS'} autoHideDuration={5000} onClose={handleReset}>
                <SnackbarContent message="Not enough funds on your balance" action={<Button size='small' 
                    color='secondary' onClick={handleAddFunds}>Add Funds</Button>} />
            </Snackbar>
            <Snackbar open={props.flow.type === 'FAILED'} autoHideDuration={5000} onClose={handleReset}>
                <SnackbarContent message="Renewal failed, please contact support for help" 
                    action={<Button size='small' color='secondary' onClick={handleReset}>Dismiss</Button>} />
            </Snackbar>
        </>
    )
}

const mapStateToProps = (state) => ({
    flow: state.renewFlow,
})

export default connect(mapStateToProps)(RenewFlow)
