import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.css';

const ProxyHistoryItem = ({ port, country, city, price, id, region }) => {

  return (
    <NavLink
      className='proxy-histroy-item-container'
      to={`/my/history-details/${id}`}
      onClick={() => {
        window.scrollTo(0, 0)
      }}
    >
      <div>
        <div className='port'>{port}</div>
        <div className='location'>{country}, {city}, {region} </div>
      </div>
      <div className='price'>{price}</div>
    </NavLink>
  )
};

export default ProxyHistoryItem;