import moment from 'moment'
import { actSetGlobalBusy } from '../actions'

export const initialLeaseHistoryState = () => ({
    firstDate: moment().startOf('day').add(-60, 'day'), // TODO use server config when available
    lastDate: moment().startOf('day'), 
    minDate: moment().startOf('day').add(-60, 'day'),
    query: null,

    items: [],

    total: 0,
    page: 0,
    rowsPerPage: 10,

    neverLoaded: true,

    leaseForBuyingAgain: null,
    agentForBuyingAgainNotFound: false,
    agentForBuyingAgain: null,
})

export const actLoadLeaseHistory = (getToken, keepPage, quiet, ifEverLoaded) => {
    return async (dispatch, getState) => {
        const state = getState().leaseHistory

        if (ifEverLoaded && state.neverLoaded) {
            return
        }

        if (! quiet) {
            dispatch({
                type: "LEASE_HISTORY_LOADING",
                reduce: (state) => ({
                    ...state,
                    leaseHistory: {
                        ...state.leaseHistory,
                        loading: true,
                    }
                })
            })    
        }

        let json = null
        try {
            json = await getState().apiClient.withToken(getToken).loadLeaseHistory(state.page * state.rowsPerPage,
                state.rowsPerPage, state.firstDate.format(), state.lastDate.clone().add(1, 'day').format(), state.query, 
                {ignoreOffline: quiet, ignoreStatuses: [429]})    
        } catch (err) {
            endLoading(dispatch)
            return
        } 

        if (window.location.href.indexOf("mockData") > -1) {
            json = mockData(state.rowsPerPage, getState())
        }

        const page = keepPage ? state.page : 0

        dispatch(({
            type: 'SET_LEASE_HISTORY',
            reduce: (state) => ({
                ...state,
                leaseHistory: {
                    ...state.leaseHistory,
                    items: json.items,
                    page: page,
                    neverLoaded: false,
                    total: json.total,
                    loading: false,
                }
            })
        }))

        if (! quiet) {
            endLoading(dispatch)
        }
    }
}

const endLoading = (dispatch) => {
    dispatch({
        type: "LEASE_HISTORY_LOADED",
        reduce: (state) => ({
            ...state,
            leaseHistory: {
                ...state.leaseHistory,
                loading: false,
            }
        })
    })  
}

const mockData = (pageSize, state) => {
    const template = {
        "items": [
            {
                "leaseID": "KVLM-2312",
                "agentID": state.proxies.proxies[0].id,
                "ip": "123.123.1.2",
                "start": "2021-02-10T22:18:40.639276512Z",
                "paidUntil": "2021-02-24T22:18:40.639276512Z",
                "loc": {
                    "cc": "US",
                    "reg": "Georgia",
                    "city": "Atlanta",
                    "zip": "30344",
                    "isp": "T-Mobile USA, Inc."
                },
                "lastPaymentC": 50
            },
            {
                "leaseID": "LMVK-5923",
                "agentID": "SXCS-6898",
                "ip": "123.123.111.4",
                "start": "2021-02-10T22:18:50.531454856Z",
                "paidUntil": "2021-02-24T22:18:50.531454856Z",
                "loc": {
                    "cc": "US",
                    "reg": "California",
                    "city": "San Diego",
                    "zip": "92102",
                    "isp": "T-Mobile USA, Inc."
                },
                "lastPaymentC": 170
            },
            {
                "leaseID": "OKCB-5051",
                "agentID": "PNKJ-4719",
                "ip": "123.123.100.127",
                "start": "2021-02-17T21:23:48.555654269Z",
                "paidUntil": "2021-02-24T21:23:48.555654269Z",
                "loc": {
                    "cc": "GB",
                    "reg": "England",
                    "city": "Portsmouth",
                    "zip": "PO6",
                    "isp": "British Telecommunications - A Very Long ISP Name PLC"
                },
                "lastPaymentC": 40
            }
        ]
    }

    const result = Object.assign({}, template)

    let i = 0
    let c = 0
    while (result.items.length < pageSize) {
        const item = { ...template.items[i] }
        item.leaseID = item.leaseID + "-" + c
        c++
        result.items.push(item)
        if (i++ >= template.items.length) {
            i = 0
        }
    }
    result.total = 50

    return result
}

export const actChangeLeaseHistoryPage = (getToken, page) => {
    return async (dispatch, getState) => {
        dispatch({
            type: "CHANGE_LEASE_HISTORY_PAGE",
            reduce: (state) => ({
                ...state,
                leaseHistory: {
                    ...state.leaseHistory,
                    page: page,
                }
            })
        })

        dispatch(actLoadLeaseHistory(getToken, true))
    }
}

export const actChangeLeaseHistoryRowsPerPage = (getToken, rows) => {
    return async (dispatch, getState) => {
        dispatch({
            type: "CHANGE_LEASE_HISTORY_ROWS_PER_PAGE",
            reduce: (state) => ({
                ...state,
                leaseHistory: {
                    ...state.leaseHistory,
                    rowsPerPage: rows,
                }
            })
        })

        dispatch(actLoadLeaseHistory(getToken))
    }
}

export const actLoadAgentForBuyingAgain = (getToken, lease) => {
    return async (dispatch, getState) => {
        dispatch(actSetGlobalBusy(true))

        dispatch(({
            type: 'SET_LEASE_FOR_BUYING_AGAIN',
            reduce: (state) => ({
                ...state,
                leaseHistory: {
                    ...state.leaseHistory,
                    leaseForBuyingAgain: lease,
                    agentForBuyingAgainNotFound: false,
                }
            })
        }))

        try {
            let json = null;
            try {
                json = await getState().apiClient.withToken(getToken).loadSingleAgent(lease.agentID,  {ignoreStatuses: [404]})
            } catch (err) {
                if (err.response?.status === 404) {
                    dispatch({
                        type: 'SET_AGENT_FOR_BUYING_AGAIN_NOT_FOUND',
                        reduce: (state) => ({
                            ...state,
                            leaseHistory: {
                                ...state.leaseHistory,
                                agentForBuyingAgainNotFound: true,
                                agentForBuyingAgain: null,
                            }            
                        })
                    })
                }
                return;
            }    

            dispatch({
                type: 'SET_AGENT_FOR_BUYING_AGAIN',
                reduce: (state) => ({
                    ...state,
                    leaseHistory: {
                        ...state.leaseHistory,
                        agentForBuyingAgain: json,
                    }
                })
            })
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}

export const actResetBuyAgain = () => ({
    type: 'RESET_BUY_AGAIN',
    reduce: (state) => ({
        ...state,
        leaseHistory: {
            ...state.leaseHistory,
            leaseForBuyingAgain: null,
            agentForBuyingAgainNotFound: false,        
            agentForBuyingAgain: null,
        }
    })
})

export const actSetFirstLeaseHistoryDate = (getToken, date) => {
    return (dispatch, getState) => {
        if (date.isSame(getState().leaseHistory.firstDate)) {
            return
        }

        let last = getState().leaseHistory.lastDate
        if (last < date) {
            last = date
        }

        dispatch({
            type: 'SET_LEASE_HISTORY_DATES',
            reduce: (state) => ({
                ...state,
                leaseHistory: {
                    ...state.leaseHistory,
                    firstDate: date,
                    lastDate: last,
                }
            })
        })
    }
}

export const actSetLastLeaseHistoryDate = (getToken, date) => {
    return (dispatch, getState) => {
        if (date.isSame(getState().leaseHistory.lastDate)) {
            return
        }

        let first = getState().leaseHistory.firstDate
        if (first > date) {
            first = date
        }

        dispatch({
            type: 'SET_LEASE_HISTORY_DATES',
            reduce: (state) => ({
                ...state,
                leaseHistory: {
                    ...state.leaseHistory,
                    firstDate: first,
                    lastDate: date,
                }
            })
        })
    }
}

export const actSetLeaseHistoryQuery = (query) => ({
    type: 'SET_LEASE_HISTORY_QUERY',
    reduce: (state) => ({
        ...state,
        leaseHistory: {
            ...state.leaseHistory,
            query: query,
        }
    })
})
