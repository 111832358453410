import { actLoadProxies, resetProxies, actReloadSelectedProxy, actLoadStaticProxies } from "./proxies"
import { actLoadAccount } from "./account"
import { actLoadActiveLeases } from "./active-leases"
import { actLoadTxRecords } from "./payments"
import { LEASE_STATUS_CREATED, LEASE_STATUS_NOT_ENOUGH_FUNDS, LEASE_STATUS_PRICE_CHANGED, LEASE_STATUS_PROBE_FAILED,
            LEASE_STATUS_AGENT_INFO_CHANGED, 
            LEASE_STATUS_AGENT_ALREADY_LEASED, LEASE_STATUS_AGENT_NOT_FOUND, LEASE_STATUS_GENERIC_ERROR, 
            STATIC_LEASE_STATUS_CREATED} from "./api-client"

export const offState = {
    type: 'OFF',
}

const leasingState = (state, skipTest, proxy) => ({
    type: 'LEASING',
    skipTest,
    proxy,
})

export const actStartLease = (proxy) => {
    return {
        type: 'START_LEASE',
        reduce: (state) => {
            const s = {
                ...state,
        
                leaseFlow: {type: 'CONFIRM', proxy: proxy},
            }
        
            return s        
        }
    }
}

export const actCancelLease = () => ({
    type: 'CANCEL_LEASE',
    reduce: (state) => {
        return {
            ...state,
            leaseFlow: offState,
        }
    }  
})

export const actStartStaticLease = (proxy) => {
    console.log(proxy)
    return {
        type: 'START_STATIC_LEASE',
        reduce: (state) => {
            const s = {
                ...state,
                
                staticLeaseFlow: {type: 'CONFIRM', proxy: proxy},
            }
        
            return s        
        }
    }
}

export const actCancelStaticLease = () => ({
    type: 'CANCEL_STATIC_LEASE',
    reduce: (state) => {
        return {
            ...state,
            staticLeaseFlow: offState,
        }
    }  
})

export const actCreateStaticLease = (getToken) => {
    return async (dispatch, getState) => {
        const proxy = getState().staticLeaseFlow.proxy

        dispatch({
            type: 'STATIC_PROXY_LEASING',
            reduce: (state) => ({
                ...state,
                staticLeaseFlow: {type: 'LEASING', ...proxy},
            })
        })  

        try {
            const response = await getState().apiClient.withToken(getToken).createStaticLease(proxy.id, proxy.price)
            console.log('RESPONSE')
            console.log(response)
            processStaticLeasePostResponse(response, getToken, dispatch)
        } catch (err) {
            dispatch({
                type: 'STATIC_LEASE_FAILED_GENERIC',
                reduce: (state) => ({
                    ...state,
                    staticLeaseFlow: {type: 'GENERIC_ERROR'},
                })
            })
        }
    }
}

const processStaticLeasePostResponse = (response, getToken, dispatch) => {
    switch (response[0]) {
    case STATIC_LEASE_STATUS_CREATED:
        dispatch({
            type: 'LEASE_SUCCESS',
            reduce: (state) => ({
                ...state,
                staticLeaseFlow: {type: 'SUCCESS'},
            }),
        })
        dispatch(actLoadStaticProxies(getToken))
        dispatch(actLoadAccount(getToken))
        dispatch(actLoadActiveLeases(getToken))
        dispatch(actLoadTxRecords(getToken))
        break
    case LEASE_STATUS_NOT_ENOUGH_FUNDS:
        dispatch({
            type: 'LEASE_NOT_ENOUGH_FUNDS',
            reduce: (state) => ({
                ...state,
                staticLeaseFlow: {type: 'NOT_ENOUGH_FUNDS'},
            })
        })
        break
    case LEASE_STATUS_AGENT_ALREADY_LEASED:
        dispatch({
            type: 'LEASE_AGENT_ALREADY_LEASED',
            reduce: (state) => ({
                ...state,
                staticLeaseFlow: {type: 'ALREADY_LEASED'},
            })
        })
        dispatch(actLoadStaticProxies(getToken))
        break
    case LEASE_STATUS_AGENT_NOT_FOUND:
        dispatch({
            type: 'LEASE_STATUS_AGENT_NOT_FOUND',
            reduce: (state) => ({
                ...state,
                staticLeaseFlow: {type: 'AGENT_NOT_FOUND'},
            })
        })
        break
    case LEASE_STATUS_GENERIC_ERROR:
        dispatch({
            type: 'LEASE_STATUS_GENERIC_ERROR',
            reduce: (state) => ({
                ...state,
                staticLeaseFlow: {type: 'GENERIC_ERROR'},
            })
        })
        break
    default:
        dispatch({
            type: 'LEASE_STATUS_GENERIC_ERROR',
            reduce: (state) => ({
                ...state,
                staticLeaseFlow: {type: 'GENERIC_ERROR'},
            })
        })
        break
    }
}

export const actConfirmLease = (getToken, skipTest) => {
    return async (dispatch, getState) => {
        const proxy = getState().leaseFlow.proxy

        dispatch({
            type: 'LEASE_LEASING',
            reduce: (state) => ({
                ...state,
                leaseFlow: leasingState(state, skipTest, proxy),
            })
        })  
        
        const privateLease = getState().proxies.leaseType === 'PRIVATE'

        try {
            const response = await getState().apiClient.withToken(getToken).createLease(proxy.id, 
                privateLease ? proxy.priceExcC : proxy.priceShrC, !skipTest, privateLease)
            processLeasePostResponse(response, getToken, dispatch)
        } catch (err) {
            dispatch({
                type: 'LEASE_FAILED_GENERIC',
                reduce: (state) => ({
                    ...state,
                    leaseFlow: {type: 'GENERIC_ERROR'},
                })
            })
        }
    }
}

const processLeasePostResponse = (response, getToken, dispatch) => {
    switch (response[0]) {
    case LEASE_STATUS_CREATED:
        dispatch({
            type: 'LEASE_SUCCESS',
            reduce: (state) => ({
                ...resetProxies(state),
                leaseFlow: {type: 'SUCCESS'},
            }),
        })
        dispatch(actLoadProxies(getToken, 0))
        dispatch(actLoadAccount(getToken))
        dispatch(actLoadActiveLeases(getToken))
        dispatch(actLoadTxRecords(getToken))
        break
    case LEASE_STATUS_NOT_ENOUGH_FUNDS:
        dispatch({
            type: 'LEASE_NOT_ENOUGH_FUNDS',
            reduce: (state) => ({
                ...state,
                leaseFlow: {type: 'NOT_ENOUGH_FUNDS'},
            })
        })
        break
    case LEASE_STATUS_PRICE_CHANGED:
        dispatch({
            type: 'LEASE_PRICE_CHANGED',
            reduce: (state) => ({
                ...state,
                leaseFlow: {type: 'PRICE_CHANGED'},
            })
        })
        dispatch(actReloadSelectedProxy(getToken))
        break
    case LEASE_STATUS_PROBE_FAILED:
        dispatch({
            type: 'LEASE_TEST_FAILED',
            reduce: (state) => ({
                ...state,
                leaseFlow: {type: 'TEST_FAILED'},
            })
        })        
        break
    case LEASE_STATUS_AGENT_INFO_CHANGED:
        dispatch({
            type: 'LEASE_AGENT_INFO_CHANGED',
            reduce: (state) => ({
                ...state,
                leaseFlow: {type: 'INFO_CHANGED', changes: response[1]},
            })            
        })
        dispatch(actReloadSelectedProxy(getToken))
        break
    case LEASE_STATUS_AGENT_ALREADY_LEASED:
        dispatch({
            type: 'LEASE_AGENT_ALREADY_LEASED',
            reduce: (state) => ({
                ...state,
                leaseFlow: {type: 'ALREADY_LEASED'},
            })
        })
        break
    case LEASE_STATUS_AGENT_NOT_FOUND:
        dispatch({
            type: 'LEASE_STATUS_AGENT_NOT_FOUND',
            reduce: (state) => ({
                ...state,
                leaseFlow: {type: 'AGENT_NOT_FOUND'},
            })
        })
        break
    case LEASE_STATUS_GENERIC_ERROR:
        dispatch({
            type: 'LEASE_STATUS_GENERIC_ERROR',
            reduce: (state) => ({
                ...state,
                leaseFlow: {type: 'GENERIC_ERROR'},
            })
        })
        break
    default:
        dispatch({
            type: 'LEASE_STATUS_GENERIC_ERROR',
            reduce: (state) => ({
                ...state,
                leaseFlow: {type: 'GENERIC_ERROR'},
            })
        })
        break
    }
}

export const actDismissLeaseSuccess = () => ({
    type: 'LEASE_SUCCESS_DISMISS',
    reduce: (state) => ({
        ...state,
        leaseFlow: offState,
    })
})

export const actDismissStaticLeaseSuccess = () => ({
    type: 'STATIC_LEASE_SUCCESS_DISMISS',
    reduce: (state) => ({
        ...state,
        staticLeaseFlow: offState,
    })
})
