import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Box, Snackbar, SnackbarContent, Button, Tabs, Tab } from '@material-ui/core';
import { headerStyle } from '../Shop';
import { pageTitle, leftBorder, topBorder } from '../../styles/common';
import MyProxySidebar from './MyProxySidebar';
import MyProxyTable from './MyProxyTable';
import { actResetRefundSuccess } from '../../redux/backend/active-leases';
import RefundFailedDialog from './RefundFailedDialog';
import RefundInProgressDialog from './RefundInProgressDialog';
import MyProxyFilter from './MyProxyFilter';
import LeaseHistoryTable from './LeaseHistoryTable';
import { Link, Route, useLocation } from 'react-router-dom';
import LeaseHistoryPaginator from './LeaseHistoryPaginator';
import MyProxiesMobilePage from '../myProxiesMobile/myProxiesMobile';
import { useEffect } from 'react';


const useStyles = makeStyles((theme) => ({
    tab: {
        height: '100%',
        width: '100%',

        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        gridTemplateColumns: '1fr auto',
    },
    header: {
        ...headerStyle(theme),
        paddingBottom: theme.spacing(1.5),
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 2,
    },
    content: {
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 2,
        gridRowEnd: 3,

        overflowY: 'scroll',
    },
    sidebar: {
        ...leftBorder(theme),
        gridColumnStart: 2,
        gridColumnEnd: 3,
        gridRowStart: 1,
        gridRowEnd: 3,
    },
    footer: {
        padding: 0,
        paddingLeft: theme.spacing(1),
        ...topBorder(theme),

        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 3,
        gridRowEnd: 4,
    },
    title: {
        ...pageTitle,
    },
}));

const MyProxyPage = (props) => {
    const classes = useStyles()

    const resetRefundSuccess = () => {
        props.dispatch(actResetRefundSuccess())
    }

    const location = useLocation()

    return (
        <>
            <div className='my-proxies-web' style={{marginTop: '50px'}}>
                <Box className={classes.tab}>
                    <Box className={classes.header}>
                        <Tabs value={location.pathname}>
                            <Tab label="Active Proxies" component={Link} value="/my" to="/my" />
                            <Tab label="History" component={Link} value="/my/history" to="/my/history" />
                        </Tabs>
                    </Box>
                    <Box className={classes.content}>
                        <Route path="/my/history">
                            <LeaseHistoryTable />
                        </Route>
                        <Route exact path="/my">
                            <MyProxyFilter label='Filter by IP address, location or ISP' />
                            <MyProxyTable />
                        </Route>
                    </Box>
                    <Box className={classes.sidebar}>{(location.pathname === "/my") && <MyProxySidebar />}</Box>
                    {(location.pathname === "/my/history") &&
                        <Box className={classes.footer}>
                            <LeaseHistoryPaginator />
                        </Box>}
                    <Snackbar open={props.refundSuccess} autoHideDuration={2000} onClose={resetRefundSuccess}>
                        <SnackbarContent
                            message="Refund successful"
                            action={<Button size='small' color='secondary' onClick={resetRefundSuccess}>Dismiss</Button>}
                        />
                    </Snackbar>
                    <RefundInProgressDialog />
                    <RefundFailedDialog />
                </Box>
            </div>
            <div className='my-proxies-mobile'>
                <MyProxiesMobilePage />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    refundSuccess: state.activeLeases.refundSuccessEvent,
    filter: state.activeLeases.filter,
    account: state.account,
});

export default connect(mapStateToProps)(MyProxyPage);
