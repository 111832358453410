import React from 'react'
import { connect } from 'react-redux'
import LeaseConfirm from './LeaseConfirm'
import { Button } from '@material-ui/core'
import { actCancelLease, actDismissLeaseSuccess } from '../../redux/backend/lease'
import LeaseSuccess from './LeaseSuccess'
import LeaseTestFailed from './LeaseTestFailed'
import LeaseLeasing from './LeaseLeasing'
import LeaseFailed from './LeaseFailed'
import { useHistory } from 'react-router-dom'
import { useDialogStyles as useDialogStylesBase } from '../dialog'


export const useDialogStyles = useDialogStylesBase

const LeaseFlow = (props) => {
    const cancelLease = () => {
        props.dispatch(actCancelLease())
    }

    const dismissLeaseSuccess = () => {
        props.dispatch(actDismissLeaseSuccess())
    }

    const history = useHistory()

    const redirectToPayments = () => {
        props.dispatch(actCancelLease())
        history.push('/payments')
    }

    const type = props.leaseFlow.type

    // console.log('TYPE : '+type)

    return (
        <div>
            {type === 'CONFIRM' && <LeaseConfirm onClose={cancelLease} />}
            {type === 'LEASING' && <LeaseLeasing />}
            <LeaseSuccess onClose={dismissLeaseSuccess} /> {/* this one is rendered differently to support the exit transition */}
            {type === 'TEST_FAILED' && <LeaseTestFailed onClose={cancelLease} />}
            {type === 'PRICE_CHANGED' && (
                <LeaseFailed 
                    onClose={cancelLease} 
                    title='Price Changed' 
                    warning={true}
                    hideDismiss={true}
                    extraButton={<Button 
                                    id='price_review' 
                                    variant='contained' 
                                    color='primary' 
                                    onClick={cancelLease}
                                >
                                    Review
                                </Button>}>
                    The price of the proxy has just been updated. <br />
                    Review the updated price before buying the proxy.
                </LeaseFailed>
            )}
            {type === 'NOT_ENOUGH_FUNDS' && (
                <LeaseFailed 
                    onClose={cancelLease} 
                    title='Not Enough Funds'
                    extraButton={<Button 
                                    id='add_funds' 
                                    variant='contained' 
                                    color='primary' 
                                    onClick={redirectToPayments}
                                >
                                    Add Funds
                                </Button>}
                >
                    You do not have enough funds on your balance. <br />
                    Make a deposit and try again.
                </LeaseFailed>
            )}
            {type === 'GENERIC_ERROR' && (
                <LeaseFailed onClose={cancelLease} title='Purchase Failed'>
                    There was an error. <br />
                    Try again or contact support.
                </LeaseFailed>
            )}
            {type === 'INFO_CHANGED' && (
                <LeaseFailed 
                    onClose={cancelLease} 
                    title='Proxy Parameters Changed' 
                    warning={true}
                    hideDismiss={true}
                    extraButton={<Button 
                                    id='info_review' 
                                    variant='contained' 
                                    color='primary' 
                                    onClick={cancelLease}
                                >
                                    Review
                                </Button>}>
                    The following parameters of the proxy have changed: <br />
                    <ul>
                        {props.leaseFlow.changes.map((ch) => <li id='ch'>{changeMap[ch] || ch}</li>)}
                    </ul>
                    Note that the price may also change because of these changes.<br />
                    Review the updated information before buying the proxy. 
                </LeaseFailed>
            )}
            {type === 'ALREADY_LEASED' && (
                <LeaseFailed 
                    onClose={cancelLease} 
                    title='Already Purchased' 
                    warning={true}
                    hideDismiss={true}
                    extraButton={<Button 
                                    id='already_purchased_ok' 
                                    variant='contained' 
                                    color='primary' 
                                    onClick={cancelLease}
                                >
                                    OK
                                </Button>}>
                    This proxy is currently purchased and unavailable.
                </LeaseFailed>
            )}
            {type === 'AGENT_NOT_FOUND' && (
                <LeaseFailed 
                    onClose={cancelLease} 
                    title='Proxy offline' 
                    warning={true}
                    hideDismiss={true}
                    extraButton={<Button 
                                    id='already_offline_ok' 
                                    variant='contained' 
                                    color='primary' 
                                    onClick={cancelLease}
                                >
                                    OK
                                </Button>}>
                    This proxy is currently offline.
                </LeaseFailed>
            )}
        </div>
    )
}

const changeMap = {
    'ip': "IP Address",
    'location': "Geolocation",
    'blacklists': "Blacklists",
    'hostname': "DNS Hostname",
    'ipScore': "IP Score",
    'rating': "Rating",
}

const mapStateToProps = (state) => ({
    leaseFlow: state.leaseFlow
})

export default connect(mapStateToProps)(LeaseFlow)