import { actLoadAccount } from "./account"
import { actLoadTxRecords } from "./payments"
import moment from 'moment'
import { REFUND_STATUS_ALIVE, REFUND_STATUS_INELIGIBLE, REFUND_STATUS_REFUNDED } from "./api-client"


export const initialActiveLeaseState = () => ({
    activeLeases: [],
    neverLoaded: true,
    loading: false,
    selected: null,
    refundInProgress: false,
    refundSuccessEvent: false,
    refundFailedEvent: false,
    refundFailReason: null,
    filter: "",
})

export const actLoadActiveLeases = (getToken, quiet) => {
    return async (dispatch, getState) => {
        if (!quiet) {
            dispatch(actSetActiveLeaseLoading(true))
        }

        const oldSelected = getState().activeLeases.selected


        let activeLeases = []
        try {
            activeLeases = await getState().apiClient.withToken(getToken).loadAllActiveLeases({ ignoreOffline: quiet })
        } catch (err) {
            return
        }

        let newSelected = null
        if (oldSelected) {
            for (const al of activeLeases) {
                if (al.id === oldSelected.id) {
                    newSelected = al
                    // TODO: this is a hack related to the weird flow of speedcheck that needs to be fixed
                    // and then this can be removed
                    newSelected.currentInfo.speedCheck = oldSelected.currentInfo.speedCheck
                    break
                }
            }    
        }

        if (newSelected == null) {
            if (activeLeases.length > 0) {
                newSelected = activeLeases[0]
            }
        }

        activeLeases.sort((a, b) => moment(a.until).unix() - moment(b.until).unix())

        dispatch(({
            type: 'SET_ACTIVE_LEASES',
            reduce: (state) => ({
                ...state,
                activeLeases: {
                    ...state.activeLeases,
                    activeLeases: activeLeases,
                    selected: newSelected,
                },
            })
        }))
        
        if (!quiet) {
            dispatch(actSetActiveLeaseLoading(false))
        }
    }
}

const actSetActiveLeaseLoading = (loading) => ({
    type: 'SET_ACTIVE_LEASE_LOADING',
    reduce: (state) => ({
        ...state,
        activeLeases: {
            ...state.activeLeases,
            loading,
            neverLoaded: false,
        }
    })
})

export const actSetSelectedActiveLease = (lease) => ({
    type: 'SET_SELECTED_ACTIVE_LEASE',
    reduce: (state) => ({
        ...state,
        activeLeases: {
            ...state.activeLeases,
            selected: lease,
        }
    })
})

const actSetRefundInProgress = (progress) => ({
    type: 'REFUND_IN_PROGRESS',
    reduce: (state) => ({
        ...state,
        activeLeases: {
            ...state.activeLeases,
            refundInProgress: progress,
        }
    })
})

export const actRequestRefund = (getToken, lease) => {
    return async (dispatch, getState) => {
        dispatch(actSetRefundInProgress(true))

        let result = null
        try {
            result = await getState().apiClient.withToken(getToken).requestRefund(lease.id)
        } catch (err) {
            return
        }

        switch (result) {
        case REFUND_STATUS_REFUNDED:
            dispatch(actLoadAccount(getToken))
            dispatch(actLoadActiveLeases(getToken))
            dispatch(actLoadTxRecords(getToken))
            dispatch({
                type: 'SET_REFUND_SUCCESS',
                reduce: (state) => ({
                    ...state,
                    activeLeases: {
                        ...state.activeLeases,
                        refundSuccessEvent: true,
                    }
                })
            })    
            break
        case REFUND_STATUS_INELIGIBLE:
        case REFUND_STATUS_ALIVE:
            dispatch({
                type: 'SET_REFUND_FAILED',
                reduce: (state) => ({
                    ...state,
                    activeLeases: {
                        ...state.activeLeases,
                        refundFailedEvent: true,
                        refundFailReason:  result,
                    }
                })
            })    
            break
        default:
        }

        dispatch(actSetRefundInProgress(false))
    }
}

export const actResetRefundSuccess = () => ({
    type: 'RESET_REFUND_SUCCESS',
    reduce: (state) => ({
        ...state,
        activeLeases: {
            ...state.activeLeases,
            refundSuccessEvent: false,
        }
    })
})

export const actResetRefundFailed = () => ({
    type: 'RESET_REFUND_FAILED',
    reduce: (state) => ({
        ...state,
        activeLeases: {
            ...state.activeLeases,
            refundFailedEvent: false,
        }
    })
})

export const actSetActiveLeaseFilter = (f) => ({
    type: 'SET_ACTIVE_LEASE_FILTER',
    reduce: (state) => ({
        ...state,
        activeLeases: {
            ...state.activeLeases,
            filter: f,
        }
    })
})

export const actSwitchAutoRenew = (getToken, lease) => {
    return async (dispatch, getState) => {
        const state = !lease.autoRenew

        const leases = [...getState().activeLeases.activeLeases]
        for (const l of leases) {
            if (l.id === lease.id) {
                l.autoRenew = state
                break
            }
        }       

        dispatch({
            type: 'UPDATE_LEASES_AFTER_SET_AUTORENEW',
            reduce: (state) => ({
                ...state,
                activeLeases: {
                    ...state.activeLeases,
                    activeLeases: leases,
                }
            })
        })

        try {
            await getState().apiClient.withToken(getToken).setLeaseAutoRenew(lease.id, state)
        } catch (err) {
            return
        }
    }
}

export const actSetLeaseNote = (getToken, lease, note) => {
    note = note?.trim()

    return async (dispatch, getState) => {
        const leases = [...getState().activeLeases.activeLeases]
        for (const l of leases) {
            if (l.id === lease.id) {
                l.note = note
                break
            }
        }    

        dispatch({
            type: 'SET_LEASE_NOTE',
            leaseId: lease.id,
            note: note,
            reduce: (state) => ({
                ...state,
                activeLeases: {
                    ...state.activeLeases,
                    activeLeases: leases,
                }
            })
        })

        const agentId = lease.agent.substring(lease.agent.lastIndexOf('/') + 1)
        try {
            await getState().apiClient.withToken(getToken).setAgentNote(agentId, note)
        } catch (err) {
            return
        }
    }
}