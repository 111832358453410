import { makeStyles, Tab, Tabs } from '@material-ui/core'
import { TabContext, TabPanel } from '@material-ui/lab'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import BlacklistSection from './BlacklistSection'
import IPScoreSection from './IPScoreSection'
import SpeedCheckSection from './SpeedCheckSection'

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        width: '100%',
    },
    tabPanel: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}))

export const tabStyles = makeStyles((theme) => ({
    root: {
        minWidth: 20,
        width: 20,
        textTransform: 'none',
        fontSize: 12,
        color: theme.palette.text.secondary,
        '&$selected': {
            '& *': {
              color: theme.palette.secondary.main,
            },
        },
        '&:hover': {
            '& $wrapper': {
                color: theme.palette.secondary.main,
            },
        },
    },
    selected: {},
    textColorInherit: {
        opacity: 1,
    },
    wrapper: {
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 500,
    },
}))

const ProxyDetails = (props) => {
    const classes = useStyles()
    const tabClasses = tabStyles()

    const [selectedTab, setSelectedTab] = useState("0")
    
    return (
        <>
        {!props.static ? ( 
            <TabContext value={selectedTab}>
                <Tabs value={selectedTab} className={classes.tabContainer} onChange={(_, v) => setSelectedTab(v)} variant='fullWidth'>
                    <Tab classes={tabClasses} label="Blacklists" value="0"></Tab>
                    <Tab classes={tabClasses} label="IP Score" value="1"></Tab>
                    <Tab classes={tabClasses} label="Speed" value="2"></Tab>
                </Tabs>

                <TabPanel className={classes.tabPanel} value="0">
                    <BlacklistSection proxy={props.proxy} />
                </TabPanel>
                <TabPanel className={classes.tabPanel} value="1">
                    <IPScoreSection scoreCheck={props.scoreCheck} proxyId={props.proxyId} />
                </TabPanel>
                <TabPanel className={classes.tabPanel} value="2">                        
                    <SpeedCheckSection proxyId={props.proxyId} speedCheck={props.speedCheck} />
                </TabPanel>
            </TabContext>
        ): (
            <TabContext value={selectedTab}>
                <Tabs value={selectedTab} className={classes.tabContainer} onChange={(_, v) => setSelectedTab(v)} variant='fullWidth'>
                    <Tab classes={tabClasses} label="IP Score" value="0"></Tab>
                    <Tab classes={tabClasses} label="Speed" value="1"></Tab>
                </Tabs>
                <TabPanel className={classes.tabPanel} value="0">
                    <IPScoreSection scoreCheck={props.scoreCheck} proxyId={props.proxyId} />
                </TabPanel>
                <TabPanel className={classes.tabPanel} value="1">                        
                    <SpeedCheckSection proxyId={props.proxyId} speedCheck={props.speedCheck} />
                </TabPanel>
            </TabContext>
        )}
        </>
    )
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(ProxyDetails)
