import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { useDialogStyles } from './LeaseFlow'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'

const LeaseFailed = (props) => {
    const dialogClasses = useDialogStyles()
    const classes = makeStyles((theme) => ({
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            color: 'gray',
            marginRight: theme.spacing(1),
        },
    }))()

    return (
        <Dialog
            open={true}
            onClose={props.onClose}
            transitionDuration={0}
            classes={{paper: dialogClasses.dialog}}
        >
            <DialogTitle>
                <div className={classes.titleContainer}>
                    {props.warning ? 
                        <WarningIcon className={classes.icon} fontSize='large' /> :
                        <ErrorIcon className={classes.icon} fontSize='large' />}
                    <div style={{flexGrow: 1}}>{props.title ? props.title : 'Purchase failed'}</div>
                </div>
            </DialogTitle>
            <DialogContent className={`${classes.content} ${dialogClasses.content}`}>
                {props.children}
            </DialogContent>
            <DialogActions classes={{root: dialogClasses.actions}}>
                {!props.hideDismiss && 
                    <Button 
                        id='lease_failed_dismiss' 
                        onClick={props.onClose} 
                        variant='text' 
                        color='primary'                
                    >
                        Dismiss
                    </Button>
                }
                {props.extraButton ? props.extraButton : <div />}
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    leaseFlow: state.leaseFlow,
})

export default connect(mapStateToProps)(LeaseFailed)
