// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../../redux/auth"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, SnackbarContent } from '@mui/material';
import { getActiveElement } from '@mui/x-date-pickers/internals/utils/utils';
import React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch, useSelector} from 'react-redux';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import { actResetPassword, actResetResetPasswordState, RESET_PASSWORD_STATE_FAILED, RESET_PASSWORD_STATE_SUCCESS } from '../../redux/backend/auth';
import { useDialogStyles } from '../dialog';
import './style.css';

const ChangePasswordMobile = (props) => {

  const { user } = useAuth0()

  const email = useSelector((state) => state.account.email)

  const handleResetPassword = () => {
        props.dispatch(actResetPassword(user))
  }
  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getActiveItem('Change Password'))
  }, [dispatch])

  return (
    <div className='change-password-mobile-container'>
      <div className='title'>Change your password?</div>
      <div>An email will be sent to <span className='user-email'>{email}</span> with the instructions</div>
      <button onClick={handleResetPassword} className='save-changes-btn'>Change Password</button>
    </div>
  )
};

const mapStateToProps = (state) => ({
  state: state.resetPasswordState,
})

export default connect(mapStateToProps)(ChangePasswordMobile)
