import { Tooltip } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'


const SmartTooltip = (props) => {
    const [isOverflown, setOverflown] = useState(false)
    const contentEl = useRef()
    const children = props.children
    
    useEffect(() => {
        setOverflown(contentEl.current.scrollWidth > contentEl.current.clientWidth)
    }, [])

    return (
        <Tooltip title={children} disableHoverListener={!isOverflown}>
            <div
                ref={contentEl}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {children}
            </div>
        </Tooltip>
    )
}

export default SmartTooltip