import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Table, TableBody, TableRow, TableHead, useTheme, Box } from '@material-ui/core'
import { actLoadTxRecords, actSetSelectedTxRecord } from '../../redux/backend/payments'
// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../../redux/auth"
import { formatMoney } from '../../util/money'
import moment from 'moment'
import { LoadingRow, useTableStyles, TableHeadCell, TableDataCell, largeColumnWidth, hugeColumnWidth, mediumColumnWidth } from '../table'
import NoResults from '../NoResults'


const PaymentTable = (props) => {
    const tableClasses = useTableStyles()

    const theme = useTheme()
    const HeadCell = TableHeadCell(theme)
    const DataCell = TableDataCell(theme)

    const neverLoaded = props.neverLoaded
    const dispatch = props.dispatch
    const { getAccessTokenSilently } = useAuth0()
    useEffect(() => {
        if (neverLoaded) {
            dispatch(actLoadTxRecords(getAccessTokenSilently))
        }
    }, [neverLoaded, dispatch, getAccessTokenSilently])

    const setSelectedTxRecord = (txr) => {
        dispatch(actSetSelectedTxRecord(txr))
    }

    let body = <div />
    if (props.loading) {
        body = (
            <TableBody><LoadingRow /></TableBody>
        )
    } else {
        body = (
            <TableBody>
                {props.txRecords.map((txr) => (
                    <TableRow 
                        hover 
                        key={txr.id} 
                        className={tableClasses.dataRow}
                        selected={txr.id === props.selected?.id}
                        onClick={() => setSelectedTxRecord(txr)}>
                        <DataCell>{moment(txr.time).format('L LT')}</DataCell>
                        <DataCell>{txr.id}</DataCell>
                        <DataCell>{txr.type}</DataCell>
                        <DataCell>{txr.memo}</DataCell>
                        <DataCell align='right'><strong>{formatMoney(txr.amtC)}</strong></DataCell>
                        <DataCell align='right' style={{paddingRight: theme.spacing(2)}}>
                            <strong>{txr.balC ? formatMoney(txr.balC) : ''}</strong>
                        </DataCell>
                    </TableRow>
                ))}
            </TableBody>
        )
    }

    return (
        <Box className={tableClasses.tableAndNoResultsContainer}>
            <Table className={tableClasses.table} stickyHeader>
                <TableHead>
                    <HeadCell style={{width: hugeColumnWidth}}>Date</HeadCell>
                    <HeadCell style={{width: largeColumnWidth}}>ID</HeadCell>
                    <HeadCell style={{width: mediumColumnWidth}}>Type</HeadCell>
                    <HeadCell style={{width: '100%'}}>Memo</HeadCell>
                    <HeadCell style={{width: mediumColumnWidth}} align='right'>Amount</HeadCell>
                    <HeadCell style={{width: mediumColumnWidth, paddingRight: theme.spacing(2)}} align='right'>Balance</HeadCell>
                </TableHead>
                {body}
            </Table>
            {(!props.loading && (props.txRecords.length === 0)) && <NoResults message='No transactions found' />}
        </Box>
    )
}

const mapStateToProps = (state) => ({
    ...state.payments
})

export default connect(mapStateToProps)(PaymentTable)