
import { initialProxyState } from './backend/proxies'
import { offState } from './backend/lease'
import { initialActiveLeaseState } from './backend/active-leases'
import { initialPaymentsState } from './backend/payments'
import { initialAddFundsState } from './backend/add-funds'
import { RESET_PASSWORD_STATE_NONE } from './backend/auth'
import { initialRenewState } from './backend/renew'
import { initialLegalState } from './legal'
import { initialLeaseHistoryState } from './backend/lease-history'
import { initialPortCreateState } from './backend/ports'

export default {
    apiClient: null,

    portApiClient: null,

    initState: 'NO',

    globalBusy: false,

    offline: false,

    maintenance: false,

    globalError: null,

    account: null,

    proxies: initialProxyState(),

    leaseFlow: offState,
    
    staticLeaseFlow: offState,

    activeLeases: initialActiveLeaseState(),

    payments: initialPaymentsState(),

    addFunds: initialAddFundsState(),

    resetPasswordState: RESET_PASSWORD_STATE_NONE,

    renewFlow: initialRenewState(),

    legal: initialLegalState(),

    leaseHistory: initialLeaseHistoryState(),

    ports: initialPortCreateState(),
}
