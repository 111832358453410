import React, { useState } from 'react'
import { useAuth0 } from "../redux/auth"
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@mui/material/Snackbar'

const mainStyles = makeStyles({
    maindiv: {
        width: 800,
        position: 'relative',
        margin: 'auto',
        marginTop: 70,
        paddingBottom: 100
    },
    h1text: {
        height: 120,
        width: 496,
        color: '#6C7380',
        fontFamily: "SF Pro Regular",
        fontSize: '12',
        letterSpacing: 0,
        lineHeight: '24px',
        textAlign: 'left'
      },
      h4text: {
        color: '#6b6969',
        fontFamily: "SF Pro Regular",
      },
      divtext: {
        display: 'flex',
        flexDirection: 'row',
      },
})
const ReferralPage = (props) => {
    const { getAccessTokenSilently } = useAuth0()
    const refCode = useState()

    const classes = mainStyles()

    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const refLink = "https://market.bigmama.network/?invite="+props.account.refcode

    const copy = () => {
        navigator.clipboard.writeText(refLink)
        setSnackbarOpen(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackbarOpen(false);
    }

    return (
        <>
        <div className={classes.maindiv}>
			<h1 align="center" style={{color: '#525966'}}>BigMama Referral Program</h1>
            <h4 align="left" className={classes.h4text}>How to start earn with BigMama?</h4>
            <div className={classes.divtext}>
                <div align="left" className={classes.h4text}>Just invite new users with your personale referral code by the following link: <b><span style={{cursor:'pointer'}} onClick={copy}>{refLink}</span></b></div>
            </div>
            <div>
            <br/>
            <div align="left" className={classes.h4text}>
                <Typography variant="string" display="block">you will receive:</Typography>
                <br/>
                <div className={classes.divtext}>
                        <Typography variant="string" display="block" style={{paddingRight: '10px'}}><b>10%</b></Typography>
                        <Typography variant="string" display="block">of the first transaction to loading the balance of each referred user.</Typography>
                </div>
                <div className={classes.divtext}>
                        <Typography variant="string" display="block" style={{paddingRight: '15px'}}><b>5%</b></Typography>
                        <Typography variant="string" display="block">from all other transactions to loading the balance of the referred user.</Typography>
                </div>
                <br/>
                <Typography variant="string" display="block">Each time your referral user will load balance, you will receive your referral earing on intrenal balance. Referral earnig will be dispalayed in Payments page.</Typography>
            </div>
            </div>
            
        </div>
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Copied to clipboard"
      />
        </>
    )
}

const mapStateToProps = (state) => ({
    account: state.account
})

export default connect(mapStateToProps)(ReferralPage)