import React from 'react'
import ReactDOM from 'react-dom'
import { auth0Cfg } from './config/auth0'
import { AuthProvider } from "./components/AuthProvider"
import AppWrapper from './components/AppWrapper'
import { Provider } from 'react-redux'
import { makeStore } from './redux/store'
import { isIE } from 'react-device-detect'


let content = null
if (isIE) {
  content = <div>Internet Explorer is not supported. Please use Chrome, Firefox or Edge.</div>
} else {
  content = (
    <AuthProvider
      domain={auth0Cfg.domain}
      redirectUri={window.location.origin}
    >
      <Provider store={makeStore()}>
        <React.StrictMode>
          <AppWrapper />
        </React.StrictMode>
      </Provider>
    </AuthProvider>
  )
}

ReactDOM.render(content, document.getElementById('root'))
