import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.css';

const MyProxiesMobileItem = ({ numbers, country, region, time, city, status, inactive, id, sharedCount, onClick }) => {

  return (
    <NavLink
      className='my-proxies-mbile-item'
      onClick={e => {
        onClick(e)
        window.scrollTo(0, 0)
      }}
      to={`/my/${id}`}
    >
      <div className='first-row'>
        <div className='numbers'>{numbers}</div>
        <div className='location'>{country}, {region}, </div>
        <div className='location'>{city}</div>
      </div>
      <div>
        <div className={inactive ? 'time' : 'time red'}>{time} left</div>
        <div className='status'>{status === "Private" ? "Private" : `Shared ${sharedCount}/4`}</div>
      </div>
    </NavLink>
  )
};

export default MyProxiesMobileItem;