import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { openTermsAndConditions } from '../../util/util';
import { actStartLease } from '../../redux/backend/lease';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import './style.css';

const ConfirmPurchase = (props) => {

  const handleBuyClick = () => {
    if (props.onBuy) {
      props.onBuy();
    }
    props.dispatch(actStartLease(props.selected));
  };

  useEffect(() => {
    props.dispatch(getActiveItem('Confirm Purchase'));
  }, [props]);

  return (
    <div className='confirm-purchase-page'>
      <div>
        <div className='deadline'>The proxy will be leased to you for 24 hours.</div>
        <div className='min-amount'>Minimum amount is 5$</div>
        <div className='popups'>Please allow pop-ups, if your browser asks you.</div>
        <div className='crypto-transaction'>Please note that balance cannot be refunded to currency. <br />
          See our <Link href="#" onClick={openTermsAndConditions}>Terms and Conditions</Link> for more details.</div>
      </div>
      <div className='buy-proxy-btn-container'>
        <Button
          id='lease_button'
          variant='contained'
          disableElevation
          color='primary'
          onClick={handleBuyClick}
        >Buy Proxy</Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  leaseFlow: state.leaseFlow
});

export default connect(mapStateToProps)(ConfirmPurchase);