import { actSetGlobalBusy } from "../actions"
import { REFUND_STATUS_ALIVE, REFUND_STATUS_INELIGIBLE, REFUND_STATUS_REFUNDED,
    GET_USERINFO_BY_EMAIL_SUCCESS,GET_USERINFO_BY_EMAIL_ERROR } from "./api-client"

export const actLoadUserInfo = (getToken, userId) => {
    return async (dispatch, getState) => {
        dispatch(actSetGlobalBusy(true))
        try {
            const info = await getState().apiClient.withToken(getToken).loadAccountInfo(userId)

            dispatch(actSetUserInfo(userId, info))
            dispatch(actSetUserInfoByEmail(null))
        } catch (err) {
            return
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}

export const actUpdateUserInfo = (getToken, userId, updateJson) => {
    return async (dispatch, getState) => {
        dispatch(actSetGlobalBusy(true))
        try {
            const newInfo = await getState().apiClient.withToken(getToken).updateAccount(userId, updateJson)
            dispatch(actSetUserInfo(userId, newInfo))
        } catch (err) {
            return
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}

export const actGetLeaseInfo = (getToken, leaseId) => {
  return async (dispatch, getState) => {
      dispatch(actSetGlobalBusy(true))
      try {
          const info = await getState().apiClient.withToken(getToken).loadLeaseInfo(leaseId)

          dispatch(actSetLeaseInfo(leaseId, info))
      } catch (err) {
          return
      } finally {
          dispatch(actSetGlobalBusy(false))
      }
  }
}

export const actBlockUser = (getToken, userEmail) => {
    return async (dispatch, getState) => {
        dispatch(actSetGlobalBusy(true))
        try {
            const info = await getState().apiClient.withToken(getToken).adminBlockUser(userEmail)
            if(info.result === true) {
                dispatch(actGetUserInfoByEmail(getToken, userEmail))
            } else {
                dispatch(actSetUserBlockError(true))
            }
            
        } catch (err) {
            dispatch(actSetUserBlockError(true))
            return
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
  }

export const actGetUserInfoByEmail = (getToken, email) => {
    return async (dispatch, getState) => {
        dispatch(actSetGlobalBusy(true))
        try {
            const info = await getState().apiClient.withToken(getToken).getUserInfoByEmail(email)
            switch(info[0]){
                case GET_USERINFO_BY_EMAIL_SUCCESS:
                    dispatch(actSetUserInfoByEmail(info[1]))
                    break
                case GET_USERINFO_BY_EMAIL_ERROR:
                    dispatch(actSetUserInfoByEmailError(info[1]))
                    break
                default:
            }
            return        
        } catch (err) {
            return err
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}

export const actChangeUserPassword = (getToken, email, password) => {
    return async (dispatch, getState) => {
        dispatch(actSetGlobalBusy(true))
        try {
            const info = await getState().apiClient.withToken(getToken).changeUserPassword(email, password)
            console.log('actChangeUserPassword')
            console.log(info)
            return        
        } catch (err) {
            return err
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}

export const actRefundLease = (getToken, leaseId) => {
  return async (dispatch, getState) => {
      dispatch(actSetGlobalBusy(true))
      let result = null
      try {
          const result = await getState().apiClient.withToken(getToken).requestRefund(leaseId)
        //   console.log('REFUND RESULT')
        //   console.log(result)
          dispatch(actSetLeaseRefundStatus(leaseId, null))

          switch (result) {
          case REFUND_STATUS_REFUNDED:
              dispatch(actSetLeaseRefundStatus(leaseId,true))
              break
          case REFUND_STATUS_INELIGIBLE:
          case REFUND_STATUS_ALIVE:
              dispatch(actSetLeaseRefundStatus(leaseId, false))
              break
          default:
              dispatch(actSetLeaseRefundStatus(leaseId, false))
          }
      } catch (err) {
          return
      } finally {
          dispatch(actSetGlobalBusy(false))
      }
  }
}

export const actGetTransactionInfo = (getToken, data) => {
  return async (dispatch, getState) => {
      dispatch(actSetGlobalBusy(true))
      try {
          const info = await getState().apiClient.withToken(getToken).loadTransactionInfo(data)

          dispatch(actSetTransactionInfo(info))
      } catch (err) {
          return
      } finally {
          dispatch(actSetGlobalBusy(false))
      }
  }
}

export const actGetUserPayments = (getToken, offset, limit, startDateIso, endDateIso, uid) => {
    return async (dispatch, getState) => {
        dispatch(actSetGlobalBusy(true))
        try {
            const info = await getState().apiClient.withToken(getToken).loadUserPaymentsData(offset, limit, startDateIso, endDateIso, uid)
  
            dispatch(actSetUserPaymentInfo(info))
        } catch (err) {
            return
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}

const actSetUserPaymentInfo = (data) => ({
    type: 'SET_USER_PAYMENT_INFO',
    reduce: (state) => ({
        ...state,
        admin: {
            ...state.admin,
           paymentData: data,
        }
    })
})

const actSetUserInfo = (userId, userInfo) => ({
    type: 'SET_USER_INFO',
    reduce: (state) => ({
        ...state,
        admin: {
            ...state.admin,
            userId,
            userInfo,
        }
    })
})

const actSetUserInfoByEmail = (userInfo) => ({
    type: 'SET_USER_INFO_BY_EMAIL',
    reduce: (state) => ({
        ...state,
        admin: {
            ...state.admin,
            userData: userInfo
        }
    })
})

const actSetUserInfoByEmailError = (error) => ({
    type: 'SET_USER_INFO_BY_EMAIL',
    reduce: (state) => ({
        ...state,
        admin: {
            ...state.admin,
            userDataError: error,
            userData: null
        }
    })
})

const actSetLeaseInfo = (leaseId, leaseInfo) => ({
    type: 'SET_USER_INFO',
    reduce: (state) => ({
        ...state,
        admin: {
            ...state.admin,
            leaseId:leaseId,
            leaseInfo:leaseInfo,
            refundStatus: null,
            refundLease: null
        }
    })
})

const actSetLeaseRefundStatus = (leaseId, status) => ({
    type: 'SET_USER_INFO',
    reduce: (state) => ({
        ...state,
        admin: {
            ...state.admin,
            leaseId: null,
            leaseInfo: null,
            refundStatus: status,
            refundLease: leaseId
        }
    })
})

const actSetTransactionInfo = (data) => ({
    type: 'SET_TRANSACTION_INFO',
    reduce: (state) => ({
        ...state,
        admin: {
            ...state.admin,
            transactionData: data
        }
    })
})

export const actSetUserBlockError = (status) => ({
    type: 'SET_BLOCKUSER_ERROR',
    reduce: (state) => ({
        ...state,
        admin: {
            ...state.admin,
            userBlockError: status
        }
    })
})
