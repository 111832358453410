import { Button, Tooltip, withStyles } from '@material-ui/core';
import React from 'react'


const PointerEventButton = withStyles({
    root: {
      "&.Mui-disabled": {
        pointerEvents: "auto"
      }
    }
})(Button);


const ButtonWithTooltip = ({ tooltipText, disabled, onClick, ...other }) => {
    const adjustedButtonProps = {
      disabled: disabled,
      component: disabled ? "div" : undefined,
      onClick: disabled ? undefined : onClick
    };
    return (
      <Tooltip title={tooltipText}>
        <PointerEventButton {...other} {...adjustedButtonProps} />
      </Tooltip>
    )
}

export default ButtonWithTooltip
