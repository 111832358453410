import { CircularProgress, Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { useDialogStyles } from '../dialog'


const RefundInProgressDialog = (props) => {
    const dialogClasses = useDialogStyles()
    const classes = makeStyles((theme) => ({
        content: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(6),
            marginLeft: theme.spacing(14),
            marginRight: theme.spacing(14),
            textAlign: 'center',
        },
        heading: {
            display: 'block',
            marginBottom: theme.spacing(4),
        },
    }))()

    return (
        <Dialog open={props.refundInProgress} disableBackdropClick={true} classes={{paper: dialogClasses.dialog}} transitionDuration={0}>
            <DialogContent className={`${classes.content} ${dialogClasses.content}`}>
                <Typography variant='p' className={classes.heading}>
                    Testing the proxy
                </Typography>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    refundInProgress:   state.activeLeases.refundInProgress,
})

export default connect(mapStateToProps)(RefundInProgressDialog)