import moment from 'moment'

export const initialPaymentsState = () => ({
    firstDate: moment().startOf('day'),
    lastDate: moment().startOf('day'),
    minDate: moment().startOf('day').add(-90, 'day'),

    txRecords: [],
    total: 0,
    page: 0,
    rowsPerPage: 10,
    loading: false,
    neverLoaded: true,
    selected: null,
})

export const actLoadTxRecords = (getToken, keepPage, quiet) => {
    return async (dispatch, getState) => {
        if (!quiet) {
            dispatch(actSetTxRecordsLoading(true))
        }

        const state = getState().payments
        const oldSelected = getState().selected

        let json = null
        try {
            json = await getState().apiClient.withToken(getToken).loadTxRecords(state.page * state.rowsPerPage,
                state.rowsPerPage, state.firstDate.format(), state.lastDate.clone().add(1, 'day').format(), {ignoreOffline: quiet})    
        } catch (err) {
            return
        }

        let newSelected = json.txRecords.length > 0 ? json.txRecords[0] : null
        if (oldSelected) {
            for (const txr of json.txRecords) {
                if (txr.id === oldSelected.id) {
                    newSelected = txr
                    break
                }
            }    
        }

        const page = keepPage ? state.page : 0

        dispatch({
            type: 'SET_TX_RECORDS',
            reduce: (state) => ({
                ...state,
                payments: {
                    ...state.payments,
                    txRecords: json.txRecords,
                    total: json.total,
                    page: page,
                    selected: newSelected,
                }
            })
        })

        if (!quiet) {
            dispatch(actSetTxRecordsLoading(false))
        }
    }
}

export const actSetTxRecordsLoading = (loading) => {
    return {
        type: 'SET_TX_RECORDS_LOADING',
        reduce: (state) => ({
            ...state,
            payments: {
                ...state.payments,
                loading: loading,
                neverLoaded: false,
            }
        })
    }
}

export const actSetSelectedTxRecord = (txr) => {
    return {
        type: 'SET_SELECTED_TX_RECORD',
        reduce: (state) => ({
            ...state,
            payments: {
                ...state.payments,
                selected: txr,
            }
        })
    }
}

export const actChangeTxRecordPage = (getToken, page) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SET_TX_RECORD_PAGE',
            reduce: (state) => ({
                ...state,
                payments: {
                    ...state.payments,
                    page,
                }
            })
        })
        dispatch(actLoadTxRecords(getToken, true))
    }
}

export const actSetFirstTxRecordDate = (getToken, date) => {
    return (dispatch, getState) => {
        if (date.isSame(getState().payments.firstDate)) {
            return
        }

        let last = getState().payments.lastDate
        if (last < date) {
            last = date
        }

        dispatch({
            type: 'SET_TX_RECORD_DATES',
            reduce: (state) => ({
                ...state,
                payments: {
                    ...state.payments,
                    firstDate: date,
                    lastDate: last,
                }
            })
        })
        dispatch(actLoadTxRecords(getToken))
    }
}

export const actSetLastTxRecordDate = (getToken, date) => {
    return (dispatch, getState) => {
        if (date.isSame(getState().payments.lastDate)) {
            return
        }

        let first = getState().payments.firstDate
        if (first > date) {
            first = date
        }

        dispatch({
            type: 'SET_TX_RECORD_DATES',
            reduce: (state) => ({
                ...state,
                payments: {
                    ...state.payments,
                    firstDate: first,
                    lastDate: date,
                }
            })
        })
        dispatch(actLoadTxRecords(getToken))
    }
}