import { Link, makeStyles } from '@material-ui/core'
import React from 'react'
import EmailIcon from '@material-ui/icons/Email'
import TelegramIcon from '@material-ui/icons/Telegram'

const useStyles = makeStyles((theme) => ({
    page: {
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(13, 7, 31)',
        color: theme.palette.common.white,
    },
    content: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',   
        alignItems: 'center',
        marginBottom: 100,
    },
    contacts: {
    },
    contactItem: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    contactIcon: {
        marginRight: theme.spacing(1),
        position: 'relative',
        top: 2,
        padding: 10,
        paddingBottom: 5,
        borderRadius: 10,
        background: 'rgb(31, 38, 80)',
    },
    header: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 2,
        marginBottom: theme.spacing(2),
    },
}))

const Maintenance = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.page}>
            <div className={classes.content}>
                <div className={classes.header}>
                    {props.children}
                </div>
                {!props.hideContacts && <div className={classes.contacts}>
                    <Link href="mailto:support@bigma.org" className={classes.contactItem}>
                        <div className={classes.contactIcon}><EmailIcon /></div>
                        support@bigma.org
                    </Link>
                    <Link href="https://t.me/bigma_support" className={classes.contactItem}>
                        <div className={classes.contactIcon}><TelegramIcon /></div>
                        @bigmama_network
                    </Link>
                </div>}
            </div>
        </div>
    )
}

export default Maintenance