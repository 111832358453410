import { actSetGlobalBusy } from "../actions"

export const initialAddFundsState = () => ({
    modelOpen: false,
    newChargeCode: null,
})

export const actOpenAddFunds = () => ({
    type: 'OPEN_ADD_FUNDS',
    reduce: (state) => ({
        ...state,
        addFunds: {
            ...state.addFunds,
            modelOpen: true,
        },
    })
})

export const actCloseAddFunds = () => ({
    type: 'CLOSE_ADD_FUNDS',
    reduce: (state) => ({
        ...state,
        addFunds: {
            ...state.addFunds,
            modelOpen: false,
        },
    })
})

export const actCreateCharge = (getToken, amtC, usedId, userEmail, currencyType) => {
    return async (dispatch, getState) => {
        dispatch(actSetGlobalBusy(true))

        let json = null
        try {
            json = await getState().apiClient.withToken(getToken).createCryptoCharge(amtC, usedId, userEmail, currencyType)
            // console.log(json)
        } catch (err) {
            dispatch(actSetErrorChargeCode())
            return
        }
        
        if (json?.status !== 200 || json?.data?.error !== undefined) {
            // console.log('ERROR2')
            // console.log(json?.status)
            // console.log(json?.data?.error)
            dispatch(actSetErrorChargeCode())
        } else {
            dispatch({
                type: 'ACT_SET_NEW_CHARGE_CODE',
                reduce: (state) => ({
                    ...state,
                    addFunds: {
                        newChargeCode: json.data.uid,
                        currencyType: json.data.type,
                        currencyAddress: json.data.address,
                        currencyAmount: json.data.amount,
                        amountUSD: amtC,
                        error: false,
                    }
                })
            })
        }
        
        dispatch(actSetGlobalBusy(false))
    }
}

export const actSetErrorChargeCode= () => ({
    type: 'ACT_SET_ERROR_CHARGE_CODE',
    reduce: (state) => ({
        ...state,
        addFunds: {
            newChargeCode: null,
            currencyType: null,
            currencyAddress: null,
            currencyAmount: null,
            amountUSD: null,
            error: true,
        },
    })
})


export const actResetNewChargeCode = () => ({
    type: 'ACT_RESET_NEW_CHARGE_CODE',
    reduce: (state) => ({
        ...state,
        addFunds: {
            newChargeCode: null,
            currencyType: null,
            currencyAddress: null,
            currencyAmount: null,
            amountUSD: null,
            error: false,
        },
    })
})
