import { createMuiTheme } from "@material-ui/core";


export default () => {
    return createMuiTheme({
        palette: {
          type: 'light',
          primary: {
            main: '#4c97ff'
          },
          secondary: {
            main: '#4c97ff',
          },
          background: {
            default: '#ffffff',
          },
          divider: '#d0d4db',
        },
        typography: {
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,' + 
                      ' "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          fontSize: 14,
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1440,
            xl: 1920,
          },
        },
        overrides: {
          MuiButton: {
            containedPrimary: {
              color: 'white',
            },
          },
        },
    })
} 
