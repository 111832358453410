import React from 'react'
import { filteredBlacklists } from '../redux/backend/proxies'
import { makeStyles, Box, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    blacklists: {
    },
    blacklistTitle: {
        fontWeight: 600,
        marginBottom: theme.spacing(1),
    },
    blacklistItem: {
        
    },
}))

const BlacklistSection = (props) => {
    const classes = useStyles()
    const blacklists = filteredBlacklists(props.proxy)

    return (
        <>
        {blacklists.length > 0 && 
            <Box className={classes.blacklists}>
                <Typography className={classes.blacklistTitle}>Blacklist Records:</Typography>
                <ul>
                {blacklists.map(bl => 
                    <li key={bl} className={classes.blacklistItem}>{bl}</li>)}
                </ul>
            </Box>
        }
        {blacklists.length === 0 && 
            <Box className={classes.blacklists}>No blacklist records</Box>}
        </>
    )
}

export default BlacklistSection