// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../../redux/auth"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { actSetFilter } from '../../redux/backend/proxies'
import { useDialogStyles } from '../dialog'


const useStyles = makeStyles((theme) => ({
    content: {
        width: 400,
    },
    control: {
        margin: 10,
        width: 150,
    },
}))

const AdvancedSearchDialog = (props) => {
    const dialogClasses = useDialogStyles()
    const classes = useStyles()

    const {getAccessTokenSilently} = useAuth0()

    const [centralZip, setCentralZip] = useState(props.filter.geoCentralZip || '')
    const [searchRadius, setSearchRadius] = useState(props.filter.geoSearchRadius || 0)

    const changeCentralZip = (e) => {
        setCentralZip(e.target?.value || null)
    }

    const changeSearchRadius = (e) => {
        setSearchRadius(e.target?.value || 0)
    }

    const onEnter = () => {
        setCentralZip(props.filter.geoCentralZip || '')
        setSearchRadius(props.filter.geoSearchRadius || 0)
    }

    const onClose = () => {
        props.onClose()
    }

    const onReset = () => {
        resetAdvancedSearch(getAccessTokenSilently, props.dispatch)
        props.onClose()
    }

    const onApply = () => {
        props.dispatch(actSetFilter(getAccessTokenSilently, {
            geoCentralZip: centralZip ? centralZip.toUpperCase() : '', 
            geoSearchRadius: searchRadius}))
        props.onClose()
    }

    return (
        <Dialog
            {...props}
            onEnter={onEnter}
            onClose={onClose}
            classes={{paper: dialogClasses.dialog}}>
            <DialogTitle>Advanced Search</DialogTitle>
            <DialogContent className={`${classes.content} ${dialogClasses.content}`}>
                <FormControl className={classes.control}>
                    <TextField label='Center ZIP/Postal' className={classes.searchField} value={centralZip} onChange={changeCentralZip} />
                </FormControl>
                <FormControl className={classes.control}>
                    <InputLabel>Search Radius</InputLabel>
                    <Select value={searchRadius} onChange={changeSearchRadius}>
                        <MenuItem value={0}>None</MenuItem>
                        <MenuItem value={10}>10 mi</MenuItem>
                        <MenuItem value={15}>15 mi</MenuItem>
                        <MenuItem value={30}>30 mi</MenuItem>
                        <MenuItem value={50}>50 mi</MenuItem>
                        <MenuItem value={80}>80 mi</MenuItem>
                        <MenuItem value={100}>100 mi</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions classes={{root: dialogClasses.actions}}>
                <Button onClick={onReset} variant='text' color='primary'>Reset</Button>
                <Button onClick={onApply} variant='contained' color='primary'>Apply</Button>
            </DialogActions>
        </Dialog>
    )   
}

const mapStateToProps = (state) => ({
    filter: state.proxies.filter,
})

export default connect(mapStateToProps)(AdvancedSearchDialog)

export const resetAdvancedSearch = (getToken, dispatch) => {
    dispatch(actSetFilter(getToken, {geoCentralZip: null, geoSearchRadius: null}))
}
