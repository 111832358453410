/* eslint-disable react-hooks/exhaustive-deps */
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../../redux/auth"
import React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import { getProxiesHistory } from '../../redux/backend/proxyHistories';
import './style.css';
import { countryNames } from '../../data/countryNames';
import { ClipLoader } from 'react-spinners';
import { actLoadAgentForBuyingAgain, actLoadLeaseHistory, actResetBuyAgain } from '../../redux/backend/lease-history';
import { Button } from '@material-ui/core';
import BuyAgainDialog from '../my-proxies/BuyAgainDialog';
import BuyAgainErrorDialog from '../my-proxies/BuyAgainErrorDialog';



const HistoryDetails = (props) => {

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    dispatch(getProxiesHistory(getAccessTokenSilently));
  }, [dispatch, getAccessTokenSilently]);

  const location = useLocation();

  let data = props?.items?.filter((item) => location?.pathname?.includes(item?.ip));

  useEffect(() => {
    props.dispatch(getActiveItem('History Details'));
    props.dispatch(actLoadLeaseHistory(getAccessTokenSilently));
  }, [getAccessTokenSilently]);

  const onBuyAgainClick = (lease) => {
    dispatch(actLoadAgentForBuyingAgain(getAccessTokenSilently, lease));
  };

  console.log(props, 'historiiiiiiiiiiiiiii props');

  return (
    <div className='history-details-page'>
      {
        props?.items ? <div className='content-for-history-details'>
          <div className='history-details-container'>
            <div className='history-details-info'>
              <div className='history-details-info-label'>Lease ID</div>
              <div className='history-details-info-value'>{data?.[0]?.leaseID}</div>
            </div>
            <div className='history-details-info'>
              <div className='history-details-info-label'>IP Address:</div>
              <div className='history-details-info-value'>{data?.[0]?.ip}</div>
            </div>
            {/* <div className='history-details-info'>
              <div className='history-details-info-label'>Hostname</div>
              <div className='history-details-info-value'>{data?.[0]?.ip}</div>
            </div> */}
            <div className='history-details-info'>
              <div className='history-details-info-label'>Country</div>
              <div className='history-details-info-value'>{countryNames[data?.[0]?.loc?.cc]}</div>
            </div>
            <div className='history-details-info'>
              <div className='history-details-info-label'>City</div>
              <div className='history-details-info-value'>{data?.[0]?.loc?.city}</div>
            </div>
            <div className='history-details-info'>
              <div className='history-details-info-label'>State</div>
              <div className='history-details-info-value'>{data?.[0]?.loc?.reg}</div>
            </div>
            <div className='history-details-info'>
              <div className='history-details-info-label'>Zip</div>
              <div className='history-details-info-value'>{data?.[0]?.loc?.zip}</div>
            </div>
            <div className='history-details-info'>
              <div className='history-details-info-label'>Isp</div>
              <div className='history-details-info-value'>{data?.[0]?.loc?.isp}</div>
            </div>
            {/* <div className='history-details-info' >
              <div className='history-details-info-label'>Shared</div>
              <div className='history-details-info-value'>1 of 4</div>
            </div>
            <div className='history-details-info'>
              <div className='history-details-info-label'>Connection</div>
              <div className='history-details-info-value'>WiFi</div>
            </div> */}
          </div>
          <div className='buy-again-container'>
            {/* <NavLink to='/confirm-purchase' className='buy-again-btn'>Buy Again</NavLink> */}
            <div
              color="primary" size="small" variant="contained"
              className='buy-again-btn'
              onClick={() => onBuyAgainClick(data?.[0])}>
              Buy Again
            </div>
          </div>
        </div> : <ClipLoader
          color='#1589EE'
          size={30}
          style={{ margin: 'auto' }}
        />
      }
      <BuyAgainDialog
        open={props.agentForBuyingAgain !== null}
        onClose={() => dispatch(actResetBuyAgain())} />
      <BuyAgainErrorDialog
        open={props.agentForBuyingAgainNotFound}
        onClose={() => dispatch(actResetBuyAgain())}
        message="This proxy is currently unavailable for purchase." />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.leaseHistory,
});

export default connect(mapStateToProps)(HistoryDetails);
