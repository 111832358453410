
export const getActiveMenuItem = (item) => {
  return async (dispatch) => {
    // dispatch(getProxiesHistory())
    // const data = await getState().apiClient.withToken(getToken).getProxiesHistory1()
    dispatch(getActiveItem(item))
  }
}

export const getActiveItem = (item) => ({
  type: 'GET_ACTIVE_MENU_ITEM',
  reduce: (state) => ({
    ...state,
    activeItem: item,
})
})