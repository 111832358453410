import React from 'react'
import { useAuth0 } from "../redux/auth"
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';

const mainStyles = makeStyles({
	  maindiv: {
		  width: 800,
		  position: 'relative',
		  margin: 'auto',
		  marginTop: 70,
		  paddingBottom: 100
	  },
	  h1text: {
		  height: 120,
		  width: 496,
		  color: '#525966',
		  fontFamily: "SF Pro Regular",
		  fontSize: '12',
		  letterSpacing: 0,
		  lineHeight: '24px',
		  textAlign: 'left'
		},
		singleAPI: {
		  paddingTop: 56,
		  position:'absolute'
		},
		bold: {
			fontWeight: 'bold'
		},
		small: {
			fontSize: 8
		}
})

const ApiPage = (props) => {

	const classes = mainStyles()

	const { getAccessTokenSilently } = useAuth0()

	return (
			<div className={classes.maindiv}>
			<h1 align="center">API</h1>


				<h4 align="left">Geting authorization token</h4>

				<Typography variant="string"  className={classes.h1text}>
					<Typography variant="string" display="block">example : </Typography>
					<Typography variant="string" display="block">curl --request POST \</Typography>
					<Typography variant="string" display="block">--url https://auth.bigmama.network/oauth/token \ </Typography>
					<Typography variant="string" display="block">--header 'content-type: application/x-www-form-urlencoded' \ </Typography>
					<Typography variant="string" display="block">
					-d "client_id=iyinBdBok0wjoGIMdxuCDkYc5n0HuTlB&client_secret=W0H_5sc8HkjmIMjZTwy_alv23FtCOyDJe74BT4VSpe7u2wPPbVKOYZ7SdcL8WmDE"
					</Typography>
					<Typography variant="string" display="block">
					"&username=email&password=password&audience=https%3A%2F%2Fcollector&grant_type=password"
					</Typography>
					<Typography variant="string" display="block"><b>client_id</b> : iyinBdBok0wjoGIMdxuCDkYc5n0HuTlB</Typography>

					<Typography variant="string" display="block"><b>client_secret</b> : W0H_5sc8HkjmIMjZTwy_alv23FtCOyDJe74BT4VSpe7u2wPPbVKOYZ7SdcL8WmDE</Typography>
					<Typography variant="string" display="block"><b>email</b> :  your login </Typography>
					<Typography variant="string" display="block"><b>password</b> :  your password </Typography>
					<Typography variant="string" display="block"><b>audience</b> :  https%3A%2F%2Fcollector </Typography>
					<Typography variant="string" display="block"><b>grant_type</b> :  password </Typography>
				</Typography>

				<Typography variant="string" display="block">
				  &nbsp;
				</Typography>
				<Typography variant="string"  className={classes.h1text} display="block" display="block">
					All requests should provide "authorization" token, otherwise 403 Forbidden response will be sent.
				</Typography>

				<h4 align="left">Requesting proxy list</h4>
				<Typography variant="string" display="block">
					<b>GET</b> : <code>https://market.bigmama.network/api/agents?....</code>
				</Typography>
				<Typography variant="string" display="block" >
					<b>Header</b> : authorization: Bearer [token]
				</Typography>
				<Typography variant="string" display="block" className={classes.bold}>
				query vars:
				</Typography>
				<Typography variant="string" display="block">
				*cursor : cursor for next page (available in response) 0 - initial
				</Typography>
				<Typography variant="string" display="block">
					*segments : true/false - display segments data in response
				</Typography>
				<Typography variant="string" display="block">
					*cc : 2 letter country code
				</Typography>
				<Typography variant="string" display="block">
					exstars : 1..5 - show with rating only
				</Typography>
				<Typography variant="string" display="block">
					city :: string
				</Typography>
				<Typography variant="string" display="block">
					ip1 [ip2] : string first/second ip octet
				</Typography>
				<Typography variant="string" display="block">
					reg : region name
				</Typography>
				<Typography variant="string" display="block">
					isp : isp name
				</Typography>
				<Typography variant="string" display="block">
				leases : none/some - (private/shared)
				</Typography>
				<Typography variant="string" display="block">
					conn : wifi/cell
				</Typography>
				<Typography variant="string" display="block">
					fresh : fresh/worn (new/old)
				</Typography>
				<Typography variant="string" display="block">
					<b>response</b> :
				</Typography>
				<Typography variant="string" display="block" className={classes.small}>
				 <code>{`{ "agents": [ <AGENT_OBJECT>, .... ], "cursor": "222", "total": "333",
				 	segments: { "cities": [ <string>, ... ], "isps": [ <string>, ... ],
				 	"zips": [ <string>, ... ], "regions": [ <string>, .... ] } }`}
				</code>

				<Typography variant="string" display="block" className={classes.bold}>
				AGENT_OBJECT:
				</Typography>
				<code>{`{
					"self": "/api/agents/CR5Zmj6Re7X", "id": "CR5Zmj6Re7X",
					"ip": "66.176._._",
					"host": "c-*.hsd1.fl.comcast.net", "conn": "wifi",
					"dev": "android", "stars": 3,
					"loc": {
					"cc": "US",
					"reg": "Florida",
					"city": "Miami",
					"zip": "33177",
					"isp": "Comcast Cable Communications"
					}, "leases": {
					"count": 0,
					"worn": true },
					"score": 0.34999999403954, "actv": 0.29,
					"age": 4036502000000000, "priceShrC": 42, "priceExcC": 143, "blacklists": {
					"listIDs": [ "dnsbl.sorbs.net"
					] }`}
					</code>
				</Typography>

				<h4 align="left">Get leases</h4>
				<Typography variant="string" display="block">
					<b>GET</b> : <code>https://market.bigmama.network/api/leases?cursor=0</code>
				</Typography>
				<Typography variant="string" display="block" className={classes.bold}>
					query vars :
				</Typography>
				<Typography variant="string" display="block">
					cursor : cursor
				</Typography>
				<Typography variant="string" display="block">
					<b>response</b> :
				</Typography>
				<Typography variant="string" display="block" className={classes.small}>
					<code>
					{`
					{
					"leases": [ <LEASE_OBJECT>, ... ], "cursor": 0
					}
					`}
					</code>
					<Typography variant="string" display="block" className={classes.bold}>
					LEASE_OBJECT:
					</Typography>
					<code>
					{`
					{
						"self": "/api/leases/ZNLV-3662",
						"id": "ZNLV-3662",
						"user": "urn:user:auth0|5fd7dcaf5ac74c00683c00f0", "agent": "/api/agents/CR5Zmj6Re7X",
						"info": {
						"ip": "66.176.204.36",
						"host": "c-66-176-204-36.hsd1.fl.comcast.net", "loc": {
						"cc": "US",
						"reg": "Florida",
						"city": "Miami",
						"zip": "33177",
						"isp": "Comcast Cable Communications"
						},
						"score": 0.34999999403954, "leases": {
						"count": 1,
						"worn": true },
						"age": 4036502000000000, "actv": 0.29,
						"conn": "wifi",
						"blacklists": {
						"listIDs": [ "dnsbl.sorbs.net"
						] }
						}, "currentInfo": {
						"ip": "66.176.204.36",
						"host": "c-66-176-204-36.hsd1.fl.comcast.net", "loc": {
						"cc": "US",
						"reg": "Florida",
						"city": "Miami",
						"zip": "33177",
						"isp": "Comcast Cable Communications"
						},
						"score": 0.34999999403954, "leases": {
						"count": 1,
						"worn": true },
						"age": 4037102000000000, "actv": 0.29,
						"conn": "wifi",
						"blacklists": {
						"listIDs": [ "dnsbl.sorbs.net"
						] }
						},
						"start": "2021-06-02T17:12:45.43576174Z", "until": "2021-06-03T17:12:45.43576174Z", "exc": false,
						"socks": {
						"host": "market.bigmama.network", "ip": "78.129.169.15",
						"port": 1080,
						"user": "1ottd555dsbysd",
						"pwd": "346466666ovy9ba" },
						"stats": {
						"reqs": 0, "inBytes": 0, "outBytes": 0, "failPct": 0, "refundable": false
						},
						"purchasePriceC": 42, "renewable": false, "autoRenew": false
						}
					`}
					</code>
				</Typography>
				<h4 align="left">Lease proxy</h4>
				<Typography variant="string" display="block">
					<b>POST</b> : <code> https://market.bigmama.network/api/leases</code>
				</Typography>
				<Typography variant="string" display="block">
					json object in body:
					<Typography variant="string" display="block" className={classes.small}>
						<code>
							{`
							{"agentID":"<LEASE_ID>","expPriceC":<PRICE>,"probe":true,"exc":false}
							`}
						</code>
					</Typography>
				</Typography>
				<Typography variant="string" display="block">
					<b>exc</b> : true - private, false - shared
				</Typography>
				<Typography variant="string" display="block">
					<b>response</b> : [LEASE_OBJECT]
				</Typography>
				<h4 align="left">Refund lease</h4>
				<Typography variant="string" display="block">
					<b>DELETE</b> : <code>{'https://market.bigmama.network/api/leases/{LEASE_ID}'}</code>
				</Typography>
				<Typography variant="string" display="block" className={classes.bold}>
					path vars :
				</Typography>
				<Typography variant="string" display="block">
					<code>{`{LEASE_ID}`}</code>
				</Typography>
				<Typography variant="string" display="block">
					<b>response</b> :200 OK
				</Typography>

				<h4 align="left">Enable/Disable autorenew</h4>
				<Typography variant="string" display="block">
					<b>GET</b> : <code>{'https://market.bigmama.network/api/leases/{LEASE_ID}/autoRenew?set=true'}</code>
				</Typography>
				<Typography variant="string" display="block" className={classes.bold}>
					path vars :
				</Typography>
				<Typography variant="string" display="block">
					<code>{`{LEASE_ID}`}</code>
				</Typography>
				<Typography variant="string" display="block" className={classes.bold}>
					query vars :
				</Typography>
				<Typography variant="string" display="block">
					set: true/false
				</Typography>
				<Typography variant="string" display="block">
					<b>response</b> :200 OK
				</Typography>
		</div>
	)
}

const mapStateToProps = (state) => ({
    ...state.api,
})

export default connect(mapStateToProps)(ApiPage)
