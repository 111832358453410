export default (theme) => ({
    borderRadius: 16,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.0),
    paddingRight: theme.spacing(1.0),
    cursor: 'pointer',
    '&:hover': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
})
