import React, { useEffect, useState } from 'react'
import { useAuthContext } from "./AuthProvider"
import { useAuth0 } from "../redux/auth"
import { ThemeProvider } from '@material-ui/core/styles'
import App from './App'
import { CssBaseline } from '@material-ui/core'
import { connect } from 'react-redux'
import { actInitialize } from '../redux/actions'
import { actSetAccountReferralCode } from '../redux/backend/account'
import createTheme from '../theme/theme'
import LoadingScreen from './LoadingScreen'
import Maintenance from './Maintenance'
import { auth0Cfg } from '../config/auth0'
import { jwtDecode } from "jwt-decode"

const AppWrapper = (props) => {
  const initialized = props.initialized
  const dispatch = props.dispatch

  let params = new URLSearchParams(document.location.search)
  let token = params.get("token")

  const { user, getAccessTokenSilently, setToken, logout } = useAuth0()
  
  useEffect(()=>{
    if (token !== '' && token !== null) {
      setToken(token)
      window.location = window.location.origin
    } else {
      if (!user) {
        window.location = auth0Cfg.domain
      }
      // getAccessTokenSilently()
      const decoded = jwtDecode(user)
      if(decoded?.referralCode?.length > 0) {
        dispatch(actSetAccountReferralCode(decoded.referralCode[0]))
      } else {
        dispatch(actSetAccountReferralCode(''))
      }
      
      dispatch(actInitialize(getAccessTokenSilently, logout))
    }
  },[])

  // const { isAuthenticated, isLoading, user, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0()
  // const isAuthenticated = true
  
  
  // useEffect(() => {
  //   (async () => {
  //     if (!isLoading && !user) {
  //       var loginParams = {}
  //       const urlParams = new URLSearchParams(window.location.search)
  //       if (urlParams.has('invite')) {
  //         loginParams = {fragment: urlParams.get('invite').substring(0, 20)}
  //       }

  //       // await loginWithRedirect(loginParams)
  //     } else {
  //       dispatch(actInitialize(getAccessTokenSilently, logout))
  //     }   
  //   })()
  // }, [isLoading, user, loginWithRedirect, initialized, dispatch, getAccessTokenSilently, logout])

  const theme = createTheme()

  const isReady = () => {
    return user && (props.initState === 'YES')
  }

  let result = null
  if (props.offline) {
    result = (
      <Maintenance hideContacts={true}>
          Can't connect to the server. <br />
          Check your Internet connection and reload the page.
      </Maintenance>
    )
  } else if (props.maintenance) {
    result = (
      <Maintenance>
          BigMama Proxy Network is down for quick maintenance. <br />
          Please check back in a minute.
      </Maintenance>
    )
  } else if (props.globalError) {
    result = (
      <Maintenance>
          We had an error. <br />
          Try reloading the page or contact us if that doesn't help.
      </Maintenance>
    )
  } else if (!isReady()) {
    result = <LoadingScreen />
  } else {
    result = <App />
  }

  return (
    <ThemeProvider theme={theme}>      
      <CssBaseline />
      {result}
    </ThemeProvider>
  )
}

const mapStateToProps = state => ({
  initState: state.initState,
  offline: state.offline,
  maintenance: state.maintenance,
  globalError: state.globalError,
})

export default connect(mapStateToProps)(AppWrapper)
