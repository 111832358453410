import React, { useState, useEffect } from 'react'
// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../../redux/auth"
import { connect, useSelector } from 'react-redux'
import { Table, styled, TableHead, TableRow, TableBody, useTheme, makeStyles, Box, TableCell, Tooltip, Switch } from '@material-ui/core'
import NoResults from '../NoResults'
import { pageTitle, leftBorder, topBorder, bottomBorder } from '../../styles/common'
import { useLocation } from 'react-router-dom'
import { useTableStyles, TableDataCell, TableHeadCell, LoadingRow } from '../table'
import { actRequestCreatePort, actLoadPortsList, actSetResetResult, actSetActivePort,
  actResetUpdatePortWhitelistResul,} from '../../redux/backend/ports'


const useStyles = makeStyles((theme) => ({
}))

export const TableDataCellStatusActive = (theme) => styled(TableCell)({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    color: '#39ab2e',
    fontSize: 13.5,
    fontWeight: 'bold',

    ...bottomBorder(theme),
})

export const TableDataCellStatusDisabled = (theme) => styled(TableCell)({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    color: '#c74224',
    fontSize: 13.5,
    fontWeight: 'bold',

    ...bottomBorder(theme),
})

const PortsTable = (props) => {
  const classes = useTableStyles()
  const extraClasses = useStyles()
  const theme = useTheme()

  const HeadCell = TableHeadCell(theme)
  const DataCell = TableDataCell(theme)
  const DataCellStatusActive = TableDataCellStatusActive(theme)
  const DataCellStatusDisabled = TableDataCellStatusDisabled(theme)

  const { getAccessTokenSilently } = useAuth0()
  const { dispatch, neverLoaded } = props

  const portList = props?.ports?.list?.package_active != undefined ? props?.ports?.list?.package_active : []
  const whitelistChanged = props.ports.updatePortWhiteListResult
  const updatePortWhiteToLoginAuthListResult = props.ports.updatePortWhiteToLoginAuthListResult

  const loadListState = useSelector((state) => state.ports.reloadList)

  // console.log(portList)

  useEffect(() => {
          dispatch(actLoadPortsList(props.account.userID, getAccessTokenSilently))
  }, [whitelistChanged, loadListState, updatePortWhiteToLoginAuthListResult])


  const handleRowClick = (al)  =>  {
    dispatch(actSetActivePort(al))
  }

  const formatTime = (date) => {
    var a = new Date(date);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;

    return time;
  }

  let body = <div />
  
  body = portList.map((al) =>
     <TableRow
         key={al.id}
         className={classes.dataRow}
         hover
         classes={{ selected: classes.selected }}
         selected={props?.ports?.selectedPort?.id === al.id}
         onClick={() => handleRowClick(al)}
     >
         <DataCell>
             {al.traffic.type}
         </DataCell>
         <DataCell>
         {al.traffic.type === 'limit' &&
           al.traffic.amount +'Gb'
         }
         </DataCell>
         <DataCell>
          Region : {al.geo.region} / Country : {al.geo.country}
         </DataCell>
         <DataCell>
          {al.switch.type.toUpperCase()}
         </DataCell>
         <DataCell>
          {al.geo.connection_type=='both'?'Wifi+Cell':al.geo.connection_type.toUpperCase()}
         </DataCell>
         {al.status.type == 'active' ? (
           <DataCellStatusActive>
            {al.status.type.toUpperCase()}
           </DataCellStatusActive>
       ): (
         <DataCellStatusDisabled>
          {al.status.type.toUpperCase()}
         </DataCellStatusDisabled>
       )
     }
         <DataCell>
          {formatTime(al.date_start)}
         </DataCell>
         <DataCell>
          {formatTime(al.date_finish)}
         </DataCell>
     </TableRow>
   )

  return (
    <Box className={classes.tableAndNoResultsContainer}>
        <Table stickyHeader className={classes.table}>
            <TableHead>
                <TableRow>
                    <HeadCell style={{width: '8%'}}>Type</HeadCell>
                    <HeadCell style={{width: '8%'}}>Volume</HeadCell>
                    <HeadCell style={{width: '20%'}}>Location</HeadCell>
                    <HeadCell style={{width: '8%'}}>Rotation</HeadCell>
                    <HeadCell style={{width: '14%'}}>Connection Type</HeadCell>
                    <HeadCell style={{width: '10%'}}>Status</HeadCell>
                    <HeadCell style={{width: '18%'}}>Start time</HeadCell>
                    <HeadCell style={{width: '18%'}}>Expired</HeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {body}
            </TableBody>
        </Table>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  ...state,
})

export default connect(mapStateToProps)(PortsTable)
