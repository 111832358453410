/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { Link, NavLink } from "react-router-dom";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import mobileMenuIcon from '../../../src/images/mobile-menu-icon.svg';
import closeMobileMenu from '../../../src/images/close-mobile-menu.svg';
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../../redux/auth"
import { connect, useDispatch } from 'react-redux';
import { formatMoney } from '../../util/money';
import { auth0Cfg } from '../../config/auth0';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import { resetAdvancedSearch } from '../search/AdvancedSearchDialog';
import dots from '../../images/dots.png';
import PopupInfo from '../myProxiesMobile/popup';
import arrowBack from '../../images/arrow-back.svg';
import './style.css';
import { useState, useEffect } from 'react';
import { actSetFilter } from '../../redux/backend/proxies';

const MobileDrawerMenu = (props) => {

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const mobileMenuItems = [
    {
      link: '/shop',
      itemName: 'Shop'
    },
    {
      link: '/my',
      itemName: 'My Proxies'
    },
    {
      link: '/payments',
      itemName: 'Payments'
    },
    {
      link: '/faq',
      itemName: 'FAQ'
    }
  ];

  const { logout, user } = useAuth0();

  const handleLogout = () => {
    logout({ returnTo: auth0Cfg.logoutUrl });
  };

  const dispatch = useDispatch();

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ display: 'flex', padding: '45px 0 0 25px' }}
    >
      <div className='close-mobile-menu-icon-container'>
        <img src={closeMobileMenu} alt='' />
      </div>
      <div className='mobile-menu-item-container'>
        <List>
          <div className='big-mama-title'>BigMama</div>
          <div className='your-email'>{props.account.email}</div>
          <div className='funds-container'>
            <div>{props.account ? formatMoney(props.account.balanceCents) : "..."}</div>
            <NavLink
              to='/add-funds'
              className='add-funds'
            >Add Funds</NavLink>
          </div>
          {mobileMenuItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton>
                <NavLink to={item?.link} onClick={() => window.scrollTo(0, 0)}>{item?.itemName}</NavLink>
              </ListItemButton>
            </ListItem>
          ))}
          <NavLink
            to='/change-password'
            className='change-password'
            onClick={(event) => {
              dispatch(getActiveItem('Change Password'));
            }}
          >Change Password</NavLink>
      
          <div className='log-out' onClick={handleLogout}>Logout</div>

          <a
            href='https://bigmama.network/terms_conditions.html'
            target="_blank"
            className='terms-and-conditions'
          >Terms and Conditions</a>
          <a
            href='https://bigmama.network/privacy.html'
            target="_blank"
            className='privacy-policy'
          >Privacy Policy</a>
        </List>
      </div>
    </Box>
  );

  const { getAccessTokenSilently } = useAuth0();
  const onReset = () => {
    resetAdvancedSearch(getAccessTokenSilently, props.dispatch);
    props.dispatch(actSetFilter(getAccessTokenSilently, {
      geoCentralZip:  '',
      geoSearchRadius: '',
      region: '',
      city: '',
      isp: '',
      leases: '',
      exstars: 0,
      conn: 0,
      age: ''
    }
    ));
  };

  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className='top-fixed-menu'>
            {
              props?.active === 'Proxy Default' ?
                <NavLink to='/shop'><img src={arrowBack} alt='' /></NavLink> :
                props?.active === 'Proxy Details' ?
                  <NavLink to='/my'><img src={arrowBack} alt='' onClick={() => setOpenPopup(false)}/></NavLink> :
                  props?.active === 'History Details' ?
                  <NavLink to='/my'><img src={arrowBack} alt='' /></NavLink> :
                  <img src={mobileMenuIcon} onClick={toggleDrawer(anchor, true)} alt='' />
            }
            <div className='active-menu-item'>{props.active ? props.active : ''}</div>
            <div>
              {
                props?.active === 'Add Funds' ? <div></div> :
                  props?.active === 'Filter' ? <div
                    className='reset-btn'
                    onClick={onReset}
                  ><NavLink to='/shop'>Reset</NavLink></div> :
                    props?.active === 'Proxy Details' ?
                      <>
                        <div
                          onClick={() => setOpenPopup(!openPopup)} className='open-popup-icon'
                        ><img src={dots} alt='' /></div>
                        {openPopup && <div className='popup-container'><PopupInfo /></div>}
                      </>
                      :
                      props?.active === 'Confirm Purchase' ? <div></div> :
                        <Link to='/add-funds' className='total-money'>{props?.account ? formatMoney(props.account.balanceCents) : "..."}</Link>
              }
            </div>
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  account: state.account,
  active: state.activeItem
});

export default connect(mapStateToProps)(MobileDrawerMenu);