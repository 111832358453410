import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { useDialogStyles } from '../dialog';
import { formatMoney } from '../../util/money';
import { actRequestRefund } from '../../redux/backend/active-leases';
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "../../redux/auth"
import { NavLink } from 'react-router-dom';
import '../../App.css';


const ConfirmRefundDialog = (props) => {
    const dialogClasses = useDialogStyles();

    const ls = props.lease;

    const { getAccessTokenSilently } = useAuth0();

    const handleConfirmRefund = () => {
        props.dispatch(actRequestRefund(getAccessTokenSilently, ls));
        props.onClose();
    };

    return (
        <>
        {!ls.static ? (
            <Dialog open={props.open} onClose={props.onClose} classes={{ paper: dialogClasses.dialog }}>
                <DialogTitle>Refund proxy {ls.info.ip}?</DialogTitle>
                <DialogContent className={dialogClasses.content}>
                    You will receive the full amount you paid (<strong>{formatMoney(ls.purchasePriceC)}</strong>). <br />
                    You will not be able to use this proxy any more.
                </DialogContent>
                <DialogActions classes={{ root: dialogClasses.actions }}>
                    <Button id='cancel_refund_button' onClick={props.onClose} color='primary'>Cancel</Button>
                    {props.isMobile ?
                        <NavLink
                            to={'/my'}
                            onClick={handleConfirmRefund}
                            className='refund-mob-btn'
                        >Refund
                        </NavLink> :
                        <Button
                            id='confirm_refund_button'
                            onClick={handleConfirmRefund}
                            variant='contained'
                            color='primary'
                            disableElevation>
                            Refund
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        ):(
            <Dialog open={props.open} onClose={props.onClose} classes={{ paper: dialogClasses.dialog }}>
                <DialogTitle>Refund static Proxy {ls.info.ip}?</DialogTitle>
                <DialogContent className={dialogClasses.content}>
                    You will receive the full amount you paid (<strong>{formatMoney(ls.purchasePriceC)}</strong>). <br />
                    You will not be able to use this proxy any more.<br />
                    To get refund for static proxy please provide leased id : <strong>{ls?.id}</strong> <br />
                    to <a href="tg://resolve?domain=bigmama_network">support telegram bot.</a> 
                </DialogContent>
                <DialogActions classes={{ root: dialogClasses.actions }}>
                    <Button id='cancel_refund_button' onClick={props.onClose} color='primary'>OK</Button>
                </DialogActions>
            </Dialog>
        )}
        </>
    );
};

const mapStateToProps = (state) => ({
    lease: state.activeLeases.selected,
});

export default connect(mapStateToProps)(ConfirmRefundDialog);