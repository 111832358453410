import React from 'react';
import green from '../images/icon-quality-green.svg';
import lime from '../images/icon-quality-lime.svg';
import yellow from '../images/icon-quality-yellow.svg';
import orange from '../images/icon-quality-orange.svg';
import red from '../images/icon-quality-red.svg';
import greenCircle from '../images/green-circle.svg';
import limeCircle from '../images/lime-circle.svg'
import yellowCircle from '../images/yellow-circle.svg'
import orangeCircle from '../images/orange-circle.svg'
import redCircle from '../images/red-circle.svg'

import { makeStyles } from '@material-ui/core';

const map = {
    1: red,
    2: orange,
    3: yellow,
    4: lime,
    5: green,
    6: red,
    7: orange,
    8: yellow,
    9: lime,
    10: green
};

const mapMobile = {
    1: redCircle,
    2: orangeCircle,
    3: yellowCircle,
    4: limeCircle,
    5: greenCircle,
    6: redCircle,
    7: orangeCircle,
    8: yellowCircle,
    9: limeCircle,
    10: greenCircle
};

const QualityScore = (props) => {
    const classes = makeStyles((theme) => ({
        icon: {
            width: 48,
        }
    }))();

    return (
        (props.score > 0) && <>
            {window.innerWidth <= 480
                ? <img src={mapMobile[props.score]} alt={props.score} className={classes.icon} />
                : <img src={map[props.score]} alt={props.score} className={classes.icon} />}
        </>
    );
};

export default QualityScore;
