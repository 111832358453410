import { makeStyles, styled, TableCell, TableRow, CircularProgress } from '@material-ui/core'
import { bottomBorder } from '../styles/common'
import React from 'react'


export const useTableStyles = makeStyles((theme) => ({
    tableAndNoResultsContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    table: {
        maxWidth: '100%',
        tableLayout: 'fixed',
    },
    noResults: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        size: 16,
        color: '#3A404D',
        height: '440px',
    },
    dataRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: '#2196F31F',
        },
        cursor: 'pointer',
    },
    selected: {},
    loadingRow: {
    },
    loadingCell: {
        textAlign: 'center',
    },
    loadingProgress: {},
}))

export const headCellHeight = 48
export const smallColumnWidth = 90
export const mediumColumnWidth = 110
export const largeColumnWidth = 140
export const hugeColumnWidth = 200

export const TableHeadCell = (theme) => styled(TableCell)({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    height: headCellHeight,
    maxHeight: headCellHeight,
    ...bottomBorder(theme),
})

export const TableDataCell = (theme) => styled(TableCell)({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    color: '#525966',
    fontSize: 13.5,

    ...bottomBorder(theme),
})

export const LoadingRow = (props) => {
    const classes = useTableStyles()

    return (
        <TableRow className={classes.loadingRow}>
            <TableCell colSpan={11} rowSpan={10} className={classes.loadingCell}>
                <CircularProgress className={classes.loadingProgress} />
            </TableCell>
        </TableRow>
    )
}
