/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { getActiveItem } from '../../redux/backend/activeMenuItem';
import { actSetSelectedProxy } from '../../redux/backend/proxies';
import QualityScore from '../QualityScore';
import './style.css';

const ShopMobileItem = ({ ip, city, isp, priceShrc, priceExcC, status, score, region, id, mobileConIcon, zip, privShared, numbers, onClick }) => {

  return (
    <Link
      to={`/shop/${id}`}
      className='shop-mobile-item-container'
      onClick={e => {
        onClick(e)
        window.scrollTo(0, 0)
      }}
    >
      <div className='left-side'>
        <div className='left-side-ip'>{ip}</div>
        <div className='left-side-location'>{city}, {region} {zip} </div>
        <div className='left-side-isp'><span style={{marginRight: '10px'}}>{mobileConIcon}</span> {isp}</div>
      </div>
      <div className='righ-side'>
        <div className='right-side-private-amount'>{priceShrc}</div>
        <div className='right-side-shared'>{priceExcC}</div>
        <div className='left-side-quality-score'><QualityScore score={score}/></div>
      </div>
    </Link>
  )
};

const mapStateToProps = (state) => ({
  totalProxies: state.proxies.total ? state.proxies.total : 0,
  proxyRowsPerPage: state.proxies.pagination.rowsPerPage,
  proxyPage: state.proxies.pagination.page,
  countries: state.proxies.countries,
  extraCountries: state.proxies.extraCountries,
  selectedCountry: state.proxies.filter.country,
  countryTotals: state.proxies.countryTotals,
  selected: state.proxies.selected,
  leaseType: state.proxies.leaseType,
  proxies: state.proxies.proxies
});

export default connect(mapStateToProps)(ShopMobileItem);