import ls from 'local-storage'


export const initialLegalState = () => ({
    acceptedTermsVersion: ls.get('acceptedTermsVersion') || 0,
})

export const needToAcceptNewTerms = (legalState) => legalState.acceptedTermsVersion < currentTermsVersion

const currentTermsVersion = 3 // increase on each T&C update

export const actAcceptTerms = () => {
    return async (dispatch, getState) => {  
        ls.set('acceptedTermsVersion', currentTermsVersion)

        dispatch(({
            type: 'UPDATE_LEGAL_STATE',
            reduce: (state) => ({
                ...state,
                legal: {
                    ...state.legal,
                    acceptedTermsVersion: currentTermsVersion,
                }
            })
        }))
    }
}
