// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from "../../redux/auth"
import React, { useState } from 'react'
import { makeStyles, Link, Button, TextField, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { connect } from 'react-redux'
import { actRequestAuthPort } from '../../redux/backend/authport'
import { makeStore } from '../../redux/store'
import { useSelector } from 'react-redux'
import '../css/FaqPage.css'



const useStyles = makeStyles((theme) => ({
	field: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        maxWidth: '100%',
        overflowWrap: 'break-word',
        paddingBottom: theme.spacing(1)
    },
	label: {
        marginRight: theme.spacing(1),
    },
    value: {
        fontWeight: 600,
    },
    actionButton: {
    	width: '100%',
        marginRight: theme.spacing(1),
    },
    textfield : {
    	width: '100%',
    },
		sectionSocksSettingText: {
			borderWidth: 0,
			borderBottomColor: theme.palette.divider,
			borderStyle: 'solid',
			marginLeft: '12px',
			paddingLeft: '12px',
			fontFamily:'SF Pro Regular',
			color: '#6C7380',
			fontSize: 12,
			letterSpacing: 0,
			flexDirection: 'column',
		},
}))

const PortNoAuth = (props) => {

	// console.log(props, '')
	const classes = useStyles()
	const lease = props.proxy

	const authPortState = useSelector((state) => state.authPort)
	let authPortError = false
	const state = useSelector((state) => state)

	const actResetAuthPortResult = () => ({
	    type: 'RESET_AUTHPORT_RESULT',
	    reduce: (state) => ({
	        ...state,
	        authPort: null
	    })
	})


	const [whitelist, setWhitelist] = useState("")
	const [buttonStatus, setButtonDisbled] = useState(true)
	const [errorWhiteList, setWhiteListError] = useState(false)

	const socksIp = (lease.authPort !== undefined && (lease.authPort.node !== undefined &&
													  lease.authPort.node != "" &&
													  lease.authPort.node !== null)) ? lease.authPort.node : ''

	const socksPort= (lease.authPort !== undefined && (lease.authPort.port !== undefined &&
													   lease.authPort.port != 0 &&
													   lease.authPort.port != null)) ? lease.authPort.port : ''

	const  socksWhiteList = whitelist != "" ? whitelist : lease.authPort.whitelist


	const { getAccessTokenSilently } = useAuth0();

	const handleWhiteListChange = (e)  => {
		const regexExpIP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi

		if (e.target.value == ""){
			setButtonDisbled(true)
			setWhiteListError(false)
			setWhitelist(e.target.value)
			return
		}

		const ipsArr = e.target.value.split(',')
		let checkedCount = 0

		ipsArr.forEach(element => {
			if(!element.match(regexExpIP)) {
				setButtonDisbled(true)
				setWhiteListError(true)
			} else  {
				checkedCount++
			}
		})

		if  (checkedCount == ipsArr.length) {
			setWhiteListError(false)
			setButtonDisbled(false)
		}
		setWhitelist(e.target.value)
	}

	const requestPort = () => {
		props.dispatch(actRequestAuthPort(getAccessTokenSilently, lease.id, ({whitelist:whitelist})))
		setWhitelist("")
    }

	const handleCloseSnackbar = (event, reason) => {
		if (authPortState == "AUTHPORT_ERROR") {
			props.dispatch(actResetAuthPortResult())
		}
	}

	return (
			<div>
				<div>
					<div className={classes.field}>
						<span className={classes.label}>SOCKS IP:</span>
						<span className={classes.value}>{socksIp}</span>
					</div>
					<div className={classes.field}>
						<span className={classes.label}>SOCKS PORT:</span>
						<span className={classes.value}>{socksPort}</span>
					</div>
					<div className={classes.field}>
						<TextField
							required
							id="whitelist"
							label="white list ip(s), format: IP,IP,...IP"
							variant="outlined"
							className={classes.textfield}
							onChange={handleWhiteListChange}
							error={errorWhiteList}
							value={socksWhiteList}
						/>
					</div>
						<div className={classes.field}>
						<Button
							disabled={buttonStatus}
	                    	variant='contained'
	                    	color='primary'
	                    	onClick={requestPort}
							className={classes.actionButton}>
	                    Save
	                    </Button>
					</div>
					<div className={classes.sectionSocksSettingText}>
						<div><span>White list ip(s) are ips from which connection to port without auth are availabe.</span></div>
					</div>
				</div>
				<Snackbar
		        	open={authPortState}
					onClose={handleCloseSnackbar}
		        	autoHideDuration={3000}>
				<Alert severity="error">Error creating port</Alert>
				</Snackbar>
			</div>
	)
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps)(PortNoAuth)
