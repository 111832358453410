import React, {Suspense, useState, useDeferredValue} from 'react'
import { connect } from 'react-redux'
import { makeStyles, Box, Typography, TablePagination } from '@material-ui/core'
import { bottomBorder, leftBorder, topBorder, pageTitle } from '../../styles/common'
import { useAuth0 } from "../../redux/auth"
import ProxyTablePaginationActions from '../ProxyTablePaginationActions'
import { useEffect } from 'react'
import '../css/FaqPage.css'
import { actLoadStaticProxies, actResetCountryFilter, 
    actChangeStaticProxyRowsPerPage, actChangeStaticProxyPage } from '../../redux/backend/proxies'
import StaticProxyTable from './StaticProxyTable'
import CountryCard from '../CountryCard'
import StaticLeaseConfirm from './StaticLeaseConfirm'
import StaticLeaseSuccess from './StaticLeaseSuccess'
import LeaseLeasing from '../leaseflow/LeaseLeasing'
import LeaseFailed from '../leaseflow/LeaseFailed'
import { actCancelStaticLease, actDismissStaticLeaseSuccess} from '../../redux/backend/lease'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { LoadingRow } from '../table'

export const headerStyle = (theme) => ({
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
});

const useStyles = makeStyles((theme) => ({
    shop: {
        height: '100%',
        maxHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        gridTemplateColumns: '1fr auto',
    },
    header: {
        ...headerStyle(theme),
        ...bottomBorder(theme),

        gridColumnStart: 1,
        gridColumnEnd: 3,
        gridRowStart: 1,
        gridRowEnd: 2,
    },
    table: {
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 2,
        gridRowEnd: 3,
        overflowY: 'scroll',
    },
    sidebar: {
        ...leftBorder(theme),

        gridColumnStart: 2,
        gridColumnEnd: 3,
        gridRowStart: 2,
        gridRowEnd: 4,

        maxHeight: '100%',
        overflowY: 'scroll',
    },
    footer: {
        padding: 0,
        paddingLeft: theme.spacing(1),
        ...topBorder(theme),

        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 3,
        gridRowEnd: 4,
    },
    title: {
        ...pageTitle,
    },
    countries: {
        display: 'flex',
        "& div": {
            marginRight: theme.spacing(1),
            // marginTop: theme.spacing(2),
        },
    },
    paginationRoot: {
        borderWidth: 0,
    },
    paginationCaption: {
        fontWeight: 500,
    },
    paginationSpacer: {
        display: 'none',
    },
    actionTexMob: {
        marginLeft: '20px',
        fontFamily: 'SF Pro Medium',
        fontSize: 10,
        color: '#0da12f',
        fontSize: 14,
        letterSpacing: 0,
        fontWeight: 500
    },
    footer: {
        padding: 0,
        paddingLeft: theme.spacing(1),
        ...topBorder(theme),

        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 3,
        gridRowEnd: 4,
    },
    paginationRoot: {
        borderWidth: 0,
    },
    paginationCaption: {
        fontWeight: 500,
    },
    paginationSpacer: {
        display: 'none',
    },
}));

const StaticProxyShop = (props) => {
    const classes = useStyles()
    const { getAccessTokenSilently } = useAuth0()

    const dispatch = props.dispatch

    const selectedStaticCountry = props.countryStatic !== null ? props.countryStatic : 'US'

    const leaseState = props.staticLeaseFlow.type

    let openConfirmDialog = false
    
    if(leaseState == 'CONFIRM'){
        openConfirmDialog = true
    }else {
        openConfirmDialog = false
    }

    const history = useHistory()

    // console.log('selectedStaticCountry'+selectedStaticCountry)

    

    const handlePageChange = (_, page) => {
        dispatch(actChangeStaticProxyPage(getAccessTokenSilently, page));
    };

    const handleRowsChange = (ev) => {
        dispatch(actChangeStaticProxyRowsPerPage(getAccessTokenSilently, ev.target.value));
    }

    useEffect(() => {
        dispatch(actResetCountryFilter())
        dispatch(actLoadStaticProxies(getAccessTokenSilently, {country: selectedStaticCountry}))
    }, [])

    useEffect(() => {
        setList(props.staticList)
    }, [props.staticList])

    const handleLeaseCancel = () => {
        dispatch(actCancelStaticLease())
    }

    const redirectToPayments = () => {
        dispatch(actCancelStaticLease())
        history.push('/payments')
    }

    const dismissLeaseSuccess = () => {
        dispatch(actDismissStaticLeaseSuccess())
    }

    const [list, setList] = useState(props.staticList)
    const deferredVList = useDeferredValue(list)

    return (
    <>
        <div className='shop-web'>
            <Box className={classes.shop}>
                <Box className={classes.header}>
                    <Typography className={classes.title}>Static Proxies</Typography>
                    <Box className={classes.countries}>
                        <CountryCard key="US" country="US" type="static" />
                        <CountryCard key="CA" country="CA" type="static" />
                        <CountryCard key="GB" country="GB" type="static" />
                        <CountryCard key="AU" country="AU" type="static" />
                        <CountryCard key="DE" country="DE" type="static" />
                        <CountryCard key="FR" country="FR" type="static" />
                    </Box>
                </Box>
                <Box className={classes.table}>
                <Suspense fallback={<LoadingRow />}>
                    <StaticProxyTable staticList={deferredVList}/>
                </Suspense>
                </Box>
                <Box className={classes.footer}>
                    <TablePagination
                        component='div'
                        classes={{ root: classes.paginationRoot, spacer: classes.paginationSpacer, caption: classes.paginationCaption }}
                        rowsPerPageOptions={[10, 20, 50]}
                        rowsPerPage={props.proxyRowsPerPage}
                        count={props?.staticList?.total||0}
                        page={props.proxyPage}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsChange}
                        // ActionsComponent={ProxyTablePaginationActions}
                    />
                </Box>
            </Box>   
        </div>
        <div className='shop-mobile'>
            {/* <ShopMobile /> */}
        </div>
        <StaticLeaseConfirm open={openConfirmDialog} cancel={handleLeaseCancel}/>
        <StaticLeaseSuccess onClose={dismissLeaseSuccess} />
        {leaseState === 'LEASING' && <LeaseLeasing />}
        {leaseState === 'GENERIC_ERROR' && (
                <LeaseFailed onClose={handleLeaseCancel} title='Purchase Failed'>
                    There was an error. <br />
                    Try again or contact support.
                </LeaseFailed>
        )}
        {leaseState === 'NOT_ENOUGH_FUNDS' && (
                <LeaseFailed 
                    onClose={handleLeaseCancel} 
                    title='Not Enough Funds'
                    extraButton={<Button 
                                    id='add_funds' 
                                    variant='contained' 
                                    color='primary' 
                                    onClick={redirectToPayments}
                                >
                                    Add Funds
                                </Button>}
                >
                    You do not have enough funds on your balance. <br />
                    Make a deposit and try again.
                </LeaseFailed>
        )}
        {leaseState === 'ALREADY_LEASED' && (
                <LeaseFailed 
                    onClose={handleLeaseCancel} 
                    title='Already Purchased' 
                    warning={true}
                    hideDismiss={true}
                    extraButton={<Button 
                                    id='already_purchased_ok' 
                                    variant='contained' 
                                    color='primary' 
                                    onClick={handleLeaseCancel}
                                >
                                    OK
                                </Button>}>
                    This proxy is currently purchased and unavailable.
                </LeaseFailed>
            )}
        {leaseState === 'AGENT_NOT_FOUND' && (
                <LeaseFailed 
                    onClose={handleLeaseCancel} 
                    title='Proxy offline' 
                    warning={true}
                    hideDismiss={true}
                    extraButton={<Button 
                                    id='already_offline_ok' 
                                    variant='contained' 
                                    color='primary' 
                                    onClick={handleLeaseCancel}
                                >
                                    OK
                                </Button>}>
                    This proxy is currently offline.
                </LeaseFailed>
            )}
    </>
    )
}

const mapStateToProps = (state) => ({
    staticList: state.proxies.static,
    proxyRowsPerPage: state.proxies.paginationStatic.rowsPerPage,
    proxyPage: state.proxies.paginationStatic.page,
    countryStatic: state.proxies.filter.countryStatic,
    staticloading: state.proxies.staticloading,
    filter: state.proxies.static?.filter,
    staticLeaseFlow: state.staticLeaseFlow,
});

export default connect(mapStateToProps)(StaticProxyShop);