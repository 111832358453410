import React from 'react';
import { connect } from 'react-redux';
import './payments.css';

const PaymentItemMobile = ({title, description, amount, balance}) => {
  return (
    <div className='payment-mobile-container'>
      <div className='left-part'>
        <div className='left-part-title'>{title}</div>
        <div className='left-part-description'>{description}</div>
      </div>
      <div className='right-part'>
        <div className='amount'>{amount}</div>
        <div className='balance'>{balance}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.payments,
});

export default connect(mapStateToProps)(PaymentItemMobile);