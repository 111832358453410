
export const bottomBorder = (theme) => ({
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderStyle: 'solid',
})

export const topBorder = (theme) => ({
    borderWidth: 0,
    borderTopWidth: 1,
    borderTopColor: theme.palette.divider,
    borderStyle: 'solid',
})

export const leftBorder = (theme) => ({
    borderWidth: 0,
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.divider,
    borderStyle: 'solid',
})

export const pageTitle = {
    fontSize: 20,
    fontWeight: 650,
    marginTop: 60,
}

export const inlineIcon = (theme) => ({
    width: 15,
    opacity: 0.5,
    position: 'relative',
    top: 7,
})
