import { Box, Button, makeStyles, Modal, Typography } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { actAcceptTerms, needToAcceptNewTerms } from '../../redux/legal'
import { bottomBorder, pageTitle } from '../../styles/common'
import { headerStyle } from '../Shop'

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
//        display: 'flex',
//        display: 'grid',
//        gridTemplateRows: 'auto 1fr',
//        gridTemplateColumns: '50% 50%',
//        rowGap: theme.spacing(2),
//        columnGap: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'scroll',
    },
    textFrame: {
        height: 200,
        width: 600,
        border: '1px solid #ccc',
        marginTop: theme.spacing(1),
    },
    textFrameBox: {
        height: '100%',
        marginTop: theme.spacing(1),
        justifyContent: 'center',
    	alignItems: 'center',
    	display:'flex',
    },
    contentBox: {
    	justifyContent: 'center',
    	alignItems: 'center',
    	overflowY: 'scroll',
    },
    textBox: {
    	justifyContent: 'center',
    	alignItems: 'center',
    	display:'flex',
    },
    ulStyle: {
    	fontSize: 11
    },
    title: {
        gridColumnStart: 'span 2',
        textAlign: 'center',
        ...headerStyle(theme),
        ...bottomBorder(theme),
        ...pageTitle,
    }
   
}))

const LegalModal = (props) => {
    const classes = useStyles()

    const onAccept = () => {
        props.dispatch(actAcceptTerms())
    }

    return (
        <Modal open={needToAcceptNewTerms(props.state)}>
            <Box className={classes.paper}>
                <Box className={classes.title}>BigMama Proxy Network | Review and Accept the Latest Terms and Conditions</Box>
                <Box className={classes.contentBox}>
	                <Box className={classes.textFrameBox}>
	                	<iframe className={classes.textFrame} src="https://bigmama.network/terms_conditions.html" title="terms and conditions" />
	                </Box>

	                <Box style={{textAlign:'center'}}>
	                	<Typography>I accept the <strong>Terms and Conditions</strong> and explicitly acknowledge the following:</Typography>
	                </Box>
	              
	                
	                	
	                <Box className={classes.textBox}>
		                    <ul className={classes.ulStyle}>
		                        <li>BigMama Proxy Network provides its service for legal purposes only, as per US federal and state laws.</li>
		                        <li>
		                            Any attempt to use our services for fraud, scam, spam, transferring illegal content or similar
		                            activities will result in immediate and permanent account suspension and loss of balance. 
		                        </li>
		                        <li>We report illegal activity as required by law.</li>
		                    </ul>
	                </Box>
	                  
	                  <Box style={{textAlign: 'center'}}>
                      	<Button variant="contained" color="primary" onClick={onAccept}>Accept</Button>
                      </Box>
                </Box>
            </Box>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    state: state.legal,
})

export default connect(mapStateToProps)(LegalModal)
