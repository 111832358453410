import React from 'react'
import { makeStyles, Box } from '@material-ui/core'
import humanFormat from 'human-format'

const useStyles = makeStyles((theme) => ({
    stats: {
        display: 'flex',
        flexWrap: 'wrap',
        color: '#3A404D',
    },  
    statBlock: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        minWidth: 120,
        minHeight: 48,
    },
    statHeader: {
        fontSize: 13,
    },
    statValue: {
        fontSize: 28,
        fontWeight: 700,
    },
}))

const LeaseStats = (props) => {
    const classes = useStyles()

    let stats = props.lease.stats
    if (!stats) {
        stats = {
            reqs: 0,
            failPct: 0,
            inBytes: 0,
            outBytes: 0,
        }
    }

    const StatBlock = (props) => {
        return (
            <Box className={classes.statBlock}>
                <Box className={classes.statHeader}>{props.header}</Box>
                <Box className={classes.statValue}>{props.value}</Box>
            </Box>
        )
    }

    return (
        <Box className={classes.stats}>
            <StatBlock header="Total Requests" value={humanFormat(stats.reqs, {decimals: 0})} />
            <StatBlock header="Failed" value={Math.floor(stats.failPct) + "%"} />
            <StatBlock header="You→Proxy" value={humanFormat.bytes(stats.outBytes, {decimals: 0})} />
            <StatBlock header="Proxy→You" value={humanFormat.bytes(stats.inBytes, {decimals: 0})} />
        </Box>
    )
}

export default LeaseStats