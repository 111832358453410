export const actLoadAccount = (getToken) => {
    return async (dispatch, getState) => {
        try {
            const acct = await getState().apiClient.withToken(getToken).loadOrCreateAccount()
            dispatch(actSetAccount(acct))
        } catch (err) {
            return
        }
    }
}

export const actSetAccount = (account) => ({
    type: 'SET_ACCOUNT',
    reduce: (state) => ({
        ...state,
        account: {
            ...state.account,
            balanceCents: account.balanceCents,
            email: account.email,
            permissions: account.permissions,
            userID: account.userID,
        },
    })
})

export const actSetAccountReferralCode = (code) => ({
    type: 'SET_ACCOUNT_REF_CODE',
    reduce: (state) => ({
        ...state,
        account: {
            ...state.account,
            refcode: code,
        },
    })
})
