import { actLoadAccount } from './backend/account'
import { actLoadProxies, actLoadProxyCountryTotals } from './backend/proxies'
import { AUDIENCE } from './backend/api-client.js'
import { actLoadActiveLeases } from './backend/active-leases'
import { actLoadTxRecords } from './backend/payments'
import APIClient, { getCfgByOrigin } from './backend/api-client'
import { actLoadLeaseHistory } from './backend/lease-history'
import { actLoadPortsList } from './backend/ports'
import { actLoadStaticProxies } from './backend/proxies'
import { auth0Cfg } from '../config/auth0'

const REFRESH_INTERVAL_MS = 1 * 20 * 1000


export const actInitialize = (getToken, logout) => {
    return (dispatch, getState) => {
        const apiClient = new APIClient(getCfgByOrigin())
        apiClient.onServerUnreachable(() => {
            dispatch(actSetOffline(true))
        })
        apiClient.onError((err) => {
            // console.log(err)
            dispatch(actSetGlobalError(err + ""))
        })
        apiClient.onAuthNeeded(() => {
            // logout and return
            // logout({ returnTo: window.location.reload() })
            localStorage.removeItem('user', null)
            window.location = auth0Cfg.domain
        })
        apiClient.onBadGateway(() => {
            dispatch(actSetMaintenance(true))
        })
        //port API Client
        const portApiClient = new APIClient(getCfgByOrigin())
        portApiClient.onServerUnreachable(() => {
            dispatch(actSetOffline(true))
        })
        portApiClient.onError((err) => {
            dispatch(actSetGlobalError(err + ""))
        })
        portApiClient.onAuthNeeded(() => {
            // logout and return
            // logout({ returnTo: window.location.reload() })
            localStorage.removeItem('user', null)
            window.location = auth0Cfg.domain
        })
        portApiClient.onBadGateway(() => {
            dispatch(actSetMaintenance(true))
        })

        dispatch({
            type: 'SET_API_CLIENT',
            reduce: (state) => ({
                ...state,
                apiClient,
                portApiClient,
            })
        })

        if (getState().initState !== 'NO') {
            return
        }
        dispatch({
            type: 'SET_INIT_STATE',
            reduce: (state) => ({
                ...state,
                initState: 'IN_PROGRESS',
            })
        })
        // get the token first, so it gets cached and each parallel operation
        // does not do it on its own causing 3x requests
        getToken(AUDIENCE)
        .then(() => {
            dispatch(actLoadAccount(getToken)).then(() => {
                Promise.all([
                    dispatch(actLoadProxies(getToken, 0)),
                    dispatch(actLoadProxyCountryTotals(getToken)),
                    dispatch(actLoadTxRecords(getToken)),
                    // dispatch(actLoadActiveLeases(getToken, true))
                ]).then(() => {
                    // start the refresh timer
                    setInterval(() => {
                        dispatch(actLoadProxyCountryTotals(getToken, { ignoreOffline: true }))
                        dispatch(actLoadActiveLeases(getToken, true))
                        dispatch(actLoadTxRecords(getToken, true, true))
                        dispatch(actLoadLeaseHistory(getToken, true, true, true))
                        dispatch(actLoadPortsList(null, getToken))
                        // dispatch(actLoadStaticProxies(getToken))
                    }, REFRESH_INTERVAL_MS)

                    dispatch({
                        type: 'SET_INIT_STATE',
                        reduce: (state) => ({
                            ...state,
                            initState: 'YES',
                        })
                    })
                })
            })
        })
    }
}

export const actSetGlobalError = (msg) => ({
    type: 'SET_GLOBAL_ERROR',
    reduce: (state) => ({
        ...state,
        globalError: msg,
    })
})

export const actSetGlobalBusy = (busy) => ({
    type: 'SET_GLOBAL_BUSY',
    reduce: (state) => ({
        ...state,
        globalBusy: busy,
    })
})

export const actSetOffline = (offline) => ({
    type: 'SET_OFFLINE',
    reduce: (state) => ({
        ...state,
        offline,
    })
})

export const actSetMaintenance = (maintenance) => ({
    type: 'SET_MAINTENANCE',
    reduce: (state) => ({
        ...state,
        maintenance,
    })
})
