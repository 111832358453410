import React, { useEffect, useState } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TextField, Button } from '@material-ui/core'
import Paper from '@mui/material/Paper';

const UserInfoTable = (props) => {
    const dateCreated = new Intl.DateTimeFormat('en-US', {  
                                            year: "numeric", 
                                            month: 'short',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric', 
                                            hourCycle: 'h24', 
                                            timeZone: 'UTC' 
                                        }).format(new Date(props.userData.created))
    const lastLoginTime = new Intl.DateTimeFormat('en-US', {  
                                            year: "numeric", 
                                            month: 'short',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric', 
                                            hourCycle: 'h24', 
                                            timeZone: 'UTC' 
                                        }).format(new Date(props.userData.lastLogin))

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: 100}} component="th" scope="row">UserID</TableCell>
                        <TableCell style={{width: 100}} align="right">{props.userData.username}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: '10%'}} component="th" scope="row">EmailVerified</TableCell>
                        <TableCell style={{width: '10%'}} align="right">{props.userData.emailVerified?'YES':'NO'}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: '25%'}}>Email</TableCell>
                        <TableCell style={{width: '25%'}} align="right">{props.userData.email}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: '20%'}}>InviteCode</TableCell>
                        <TableCell style={{width: '20%'}} align="right">{props.userData.inviteCode}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: '15%'}}>Date Registered</TableCell>
                        <TableCell style={{width: '15%'}} align="right">{dateCreated}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: '30%'}}>Last Login Time</TableCell>
                        <TableCell style={{width: '30%'}} align="right">{lastLoginTime}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: 80}}>Last Login IP</TableCell>
                        <TableCell style={{width: 80}} align="right">{props.userData.lastIp}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: 80}}>BalanceC</TableCell>
                        <TableCell style={{width: 80}} align="right">{props.userData.balanceC/100}$</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: 80}}>DepositedC</TableCell>
                        <TableCell style={{width: 80}} align="right">{props.userData.depositedC ? props.userData.depositedC/100 : 0}$</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: 80}}>Blocked</TableCell>
                        <TableCell style={{width: 80}} align="right">{props.userData.blocked?'YES':'NO'}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{width: 140}}>
                            <TextField
                                    label="New Password"
                                    value={props.newpass} onChange={(event)=>{props.setUserPassword(event.target.value)}} sx={{width: '140px'}} style={{width: 140}}/>
                        </TableCell>
                        <TableCell style={{width: 80}} align="right">
                            <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={props.handleChangeUserPassword}>
                                        Change Password
                                </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default UserInfoTable