import React, { useState } from 'react'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { makeStyles, Snackbar, SnackbarContent, Button } from '@material-ui/core'
import { inlineIcon } from '../../styles/common'

export const CopySnackbar = (props) => {
    return (
        <Snackbar open={props.open} autoHideDuration={2000} onClose={props.onClose}>
            <SnackbarContent 
                message="Copied to clipboard" 
                action={<Button size='small' color='secondary' onClick={props.onClose}>Dismiss</Button>} 
            />
        </Snackbar>
    )
}

const CopyButton = (props) => {
    const classes = makeStyles((theme) => ({
        icon: {
            ...inlineIcon(theme),
            marginRight: theme.spacing(1),
            cursor: 'pointer',
        },
    }))()

    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const copy = () => {
        navigator.clipboard.writeText(props.value)
        setSnackbarOpen(true)
    }

    return (
        <>
            <FileCopyOutlinedIcon className={classes.icon} onClick={copy} />
            <CopySnackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} />
        </>
    )
}

export default CopyButton