import { IconButton, makeStyles, TextField } from '@material-ui/core'
import React from 'react'
import { connect } from "react-redux"
import { actSetActiveLeaseFilter } from '../../redux/backend/active-leases'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    field: {
        width: '100%',
    },
    clearIconButton: {
        margin: 0,
        padding: 0,
        height: 28,
        width: 28,
    },
    clearIcon: {
        height: 20,
        margin: 0,
        padding: 0,
    },
}))

const MyProxyFilter = (props) => {
    const classes = useStyles()

    const handleChange = (e) => props.dispatch(actSetActiveLeaseFilter(e.target.value))
    const handleClear = () => props.dispatch(actSetActiveLeaseFilter(""))

    return (
        <div className={classes.container}>
            <TextField 
                variant="outlined" 
                size="small"
                className={classes.field} 
                value={props?.filter} 
                label={props?.label} 
                onChange={handleChange}
                InputProps={{ 
                    endAdornment: props.filter && (
                        <IconButton className={classes.clearIconButton} onClick={() => handleClear()}>
                          <ClearIcon className={classes.clearIcon} />
                        </IconButton>
                    )
                }}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    filter: state.activeLeases.filter,
})

export default connect(mapStateToProps)(MyProxyFilter)