import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { TableRow, styled, TableCell, makeStyles, TextField, Paper, createMuiTheme, IconButton, Button, Link } from '@material-ui/core'
import { headCellHeight } from '../table'
import { connTypeLabels } from '../ProxyTable'
import { Autocomplete } from '@material-ui/lab'
import { actSetStaticFilter } from '../../redux/backend/proxies'
import { useAuth0 } from "../../redux/auth"
import ClearIcon from '@material-ui/icons/Clear'
import { bottomBorder } from '../../styles/common'

const useStyles = makeStyles((theme) => ({
    filterRow: {
        backgroundColor: '#e4e6eb',
        top: headCellHeight,
        position: 'sticky',
        height: headCellHeight,
        maxHeight: headCellHeight,
    },
    filterInput: {
        backgroundColor: theme.palette.common.white,
        borderColor: '#D0D4DB',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 4,
        fontSize: 12,
        padding: 2,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: 0,
        height: 32,
    },
    filterInputInternal: {
        '&::placeholder': {
            fontStyle: 'italic',
        },
    },
    clearIconButton: {
        margin: 0,
        padding: 0,
        height: 28,
        width: 28,
    },
    clearIcon: {
        height: 20,
        margin: 0,
        padding: 0,
    },
    advancedSearchButton: {
        textTransform: 'none',
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    activeAdvancedSearch: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    advancedSearchCriterion: {
        marginRight: 30,
    },
    advancedSearchValue: {
        fontWeight: 400,
    },
}))

const theme = createMuiTheme()

const FilterCell = styled(TableCell)({
    padding: 0,
    paddingLeft: theme.spacing(2),
    backgroundColor: 'inherit',
    border: 0,
    ...bottomBorder(theme),
})

let ipTimeout = null

const StaticProxyFilter = (props) => {
    const classes = useStyles()
    const {getAccessTokenSilently} = useAuth0()

    const FilterPaper = styled(Paper)({
        minWidth: 300,
        fontSize: 14,
    })

    const [ipRaw, setIpRaw] = useState(props?.filter?.ip !== null ? props?.filter?.ip : '')

    const dispatch = props.dispatch
    const filter = props.filter

    const setFilter = (name, value) => {
        if(name == 'ip') {
            setIpRaw(value==null?'':value)
        }
        props.dispatch(actSetStaticFilter(getAccessTokenSilently, name, value))
    }

    const SegmentFilterField = (props) => {
        return (
            <Autocomplete
                name={props.name}
                disabled={(!props.value) && props.disabled}
                freeSolo={Boolean(props.value)}
                openOnFocus={true}
                filterSelectedOptions={true}
                renderInput={(params) =>
                    <TextField {...params}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            classes: {input: classes.filterInputInternal},
                            autoComplete: 'new-password',
                            readOnly: Boolean(props.value),
                        }}
                        autoComplete='new-password'
                        placeholder='Any' />}
                options={props.options || []}
                PaperComponent={FilterPaper}
                classes={{inputRoot: classes.filterInput}}
                value={props.value}
                getOptionLabel={props.getOptionLabel || ((o) => o)}
                onChange={(_, v) => setFilter(props.name, v)} />
        )
    }

    return (
            <TableRow className={classes.filterRow} style={{zIndex: 1}}>
            <FilterCell>
                <TextField
                    disabled={props.disabled}
                    name="ip"
                    InputProps={{
                        disableUnderline: true, classes: {input: classes.filterInputInternal, root: classes.filterInput},
                        endAdornment: ipRaw && (
                            <IconButton className={classes.clearIconButton} onClick={(v) => setFilter('ip', null)}>
                              <ClearIcon className={classes.clearIcon} />
                            </IconButton>
                        ),
                        autoComplete: 'new-password',
                    }}
                    placeholder='Any'
                    value={props?.filter?.ip !== null ? props?.filter?.ip : ''}
                    autoComplete='new-password'
                    onChange={(e) => setFilter('ip', e.target.value)} />
            </FilterCell>
            <FilterCell>
                <SegmentFilterField name="city" disabled={!props?.staticList?.segments?.cities} options={props?.staticList?.segments?.cities}
                    value={filter?.city} />
            </FilterCell>
            <FilterCell>
                <SegmentFilterField name="isp" disabled={!props?.staticList?.segments?.isps} options={props?.staticList?.segments?.isps}
                    value={filter?.isp} />
            </FilterCell>
            <FilterCell>
                <SegmentFilterField name="conn" options={['wifi', 'cell']}
                    disabled={props.disabled}
                    getOptionLabel={(o) => connTypeLabels[o]}
                    value={filter?.conn} />
            </FilterCell>
            <FilterCell>&nbsp;</FilterCell>
        </TableRow>
    )
}

const mapStateToProps = (state) => ({
    staticList: state.proxies.static,
    countryStatic: state.proxies.filter.countryStatic,
    filter: state.proxies.static?.filter,
});

export default connect(mapStateToProps)(StaticProxyFilter);