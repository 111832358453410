import { actSetGlobalBusy } from "../actions"
import { actLoadAccount } from "./account"
import { actLoadActiveLeases } from "./active-leases"
import { SCORECHECK_OK } from "./api-client"
import { actLoadTxRecords } from "./payments"

export const actRequestIpScore = (getToken, proxyId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(actSetGlobalBusy(true))
            const result = await getState().apiClient.withToken(getToken).createScoreCheck()
            switch (result[0]) {
                case SCORECHECK_OK:
                    break
                default:
                    dispatch(actSetIpScoreCheckResult(result[0]))
                    return
            }

            const info = result[1]
            info.proxyId = proxyId
            
            dispatch(({
                type: 'SET_SCORECHECK_INFO',
                reduce: (state) => ({
                    ...state,
                    scoreCheckInfo: info,
                })
            }))
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}

export const actConfirmIpScorePurchase = (getToken) => {
    return async (dispatch, getState) => {
        const scoreCheckInfo = getState().scoreCheckInfo
        if (!scoreCheckInfo) {
            return
        }

        dispatch(actResetScoreCheck())

        try {
            dispatch(actSetGlobalBusy(true))

            const result = await getState().apiClient.withToken(getToken).purchaseScoreCheck(scoreCheckInfo.proxyId, scoreCheckInfo.priceC,
                scoreCheckInfo.oneTimeToken)
            dispatch(actSetIpScoreCheckResult(result[0]))
            switch (result[0]) {
            case SCORECHECK_OK:
                    break
                default:
                    return
            }

            const score = result[1]

            dispatch({
                type: 'UPDATE_PROXY_SCORE',
                reduce: (state) => {
                    const proxies = []
                    let newSelected
                    for (const proxy of state.proxies.proxies) {
                        const p = {...proxy}
                        if (p.id === scoreCheckInfo.proxyId) {
                            p.scoreCheck = score
                        }
                        if (p.id === state.proxies.selected?.id) {
                            newSelected = p
                        }
                        proxies.push(p)
                    }

                    return {...state, proxies: {...state.proxies, proxies, selected: newSelected}}
                }
            })

            dispatch(actLoadAccount(getToken))
            dispatch(actLoadTxRecords(getToken, true, true))
            dispatch(actLoadActiveLeases(getToken, true))
        } finally {
            dispatch(actSetGlobalBusy(false))
        }
    }
}

export const actResetScoreCheck = () => ({
    type: 'RESET_SCORECHECK_INFO',
    reduce: (state) => ({
        ...state,
        scoreCheckInfo: null,
    })
})

export const actSetIpScoreCheckResult = (result) => ({
    type: 'SET_IP_SCORE_CHECK_RESULT',
    reduce: (state) => ({
        ...state,
        scoreCheckResult: result,
    })
})